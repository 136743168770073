// Generated by purs bundle 0.13.8
var PS = {};
(function(exports) {
  /* global XMLHttpRequest */
  /* global process */
  "use strict";

  exports._ajax = function () {
    var platformSpecific = { };
    if (typeof module !== "undefined" && module.require && !(typeof process !== "undefined" && process.versions["electron"])) {
      // We are on node.js
      platformSpecific.newXHR = function () {
        var XHR = module.require("xhr2");
        return new XHR();
      };

      platformSpecific.fixupUrl = function (url, xhr) {
        if (xhr.nodejsBaseUrl === null) {
          var urllib = module.require("url");
          var u = urllib.parse(url);
          u.protocol = u.protocol || "http:";
          u.hostname = u.hostname || "localhost";
          return urllib.format(u);
        } else {
          return url || "/";
        }
      };

      platformSpecific.getResponse = function (xhr) {
        return xhr.response;
      };
    } else {
      // We are in the browser
      platformSpecific.newXHR = function () {
        return new XMLHttpRequest();
      };

      platformSpecific.fixupUrl = function (url) {
        return url || "/";
      };

      platformSpecific.getResponse = function (xhr) {
        return xhr.response;
      };
    }

    return function (mkHeader, options) {
      return function (errback, callback) {
        var xhr = platformSpecific.newXHR();
        var fixedUrl = platformSpecific.fixupUrl(options.url, xhr);
        xhr.open(options.method || "GET", fixedUrl, true, options.username, options.password);
        if (options.headers) {
          try {
            for (var i = 0, header; (header = options.headers[i]) != null; i++) {
              xhr.setRequestHeader(header.field, header.value);
            }
          } catch (e) {
            errback(e);
          }
        }
        var onerror = function (msg) {
          return function () {
            errback(new Error(msg + ": " + options.method + " " + options.url));
          };
        };
        xhr.onerror = onerror("AJAX request failed");
        xhr.ontimeout = onerror("AJAX request timed out");
        xhr.onload = function () {
          callback({
            status: xhr.status,
            statusText: xhr.statusText,
            headers: xhr.getAllResponseHeaders().split("\r\n")
              .filter(function (header) {
                return header.length > 0;
              })
              .map(function (header) {
                var i = header.indexOf(":");
                return mkHeader(header.substring(0, i))(header.substring(i + 2));
              }),
            body: platformSpecific.getResponse(xhr)
          });
        };
        xhr.responseType = options.responseType;
        xhr.withCredentials = options.withCredentials;
        xhr.send(options.content);

        return function (error, cancelErrback, cancelCallback) {
          try {
            xhr.abort();
          } catch (e) {
            return cancelErrback(e);
          }
          return cancelCallback();
        };
      };
    };
  }();
})(PS["Affjax"] = PS["Affjax"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];
  var Semigroupoid = function (compose) {
      this.compose = compose;
  };
  var semigroupoidFn = new Semigroupoid(function (f) {
      return function (g) {
          return function (x) {
              return f(g(x));
          };
      };
  });
  var compose = function (dict) {
      return dict.compose;
  };
  var composeFlipped = function (dictSemigroupoid) {
      return function (f) {
          return function (g) {
              return compose(dictSemigroupoid)(g)(f);
          };
      };
  };
  exports["compose"] = compose;
  exports["composeFlipped"] = composeFlipped;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];                
  var Category = function (Semigroupoid0, identity) {
      this.Semigroupoid0 = Semigroupoid0;
      this.identity = identity;
  };
  var identity = function (dict) {
      return dict.identity;
  };
  var categoryFn = new Category(function () {
      return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
      return x;
  });
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];                    
  var on = function (f) {
      return function (g) {
          return function (x) {
              return function (y) {
                  return f(g(x))(g(y));
              };
          };
      };
  };
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
  exports["on"] = on;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];                
  var Functor = function (map) {
      this.map = map;
  };
  var map = function (dict) {
      return dict.map;
  };
  var mapFlipped = function (dictFunctor) {
      return function (fa) {
          return function (f) {
              return map(dictFunctor)(f)(fa);
          };
      };
  };
  var $$void = function (dictFunctor) {
      return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };
  var voidLeft = function (dictFunctor) {
      return function (f) {
          return function (x) {
              return map(dictFunctor)(Data_Function["const"](x))(f);
          };
      };
  };
  var voidRight = function (dictFunctor) {
      return function (x) {
          return map(dictFunctor)(Data_Function["const"](x));
      };
  };
  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["mapFlipped"] = mapFlipped;
  exports["void"] = $$void;
  exports["voidRight"] = voidRight;
  exports["voidLeft"] = voidLeft;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];                
  var Apply = function (Functor0, apply) {
      this.Functor0 = Functor0;
      this.apply = apply;
  };                      
  var apply = function (dict) {
      return dict.apply;
  };
  var applySecond = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
          };
      };
  };
  var lift2 = function (dictApply) {
      return function (f) {
          return function (a) {
              return function (b) {
                  return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b);
              };
          };
      };
  };
  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applySecond"] = applySecond;
  exports["lift2"] = lift2;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];                
  var Applicative = function (Apply0, pure) {
      this.Apply0 = Apply0;
      this.pure = pure;
  };
  var pure = function (dict) {
      return dict.pure;
  };
  var unless = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (!v) {
                  return v1;
              };
              if (v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 62, column 1 - line 62, column 65): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var when = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 57, column 1 - line 57, column 63): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var liftA1 = function (dictApplicative) {
      return function (f) {
          return function (a) {
              return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
          };
      };
  };
  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["unless"] = unless;
  exports["when"] = when;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];                
  var Discard = function (discard) {
      this.discard = discard;
  };
  var Bind = function (Apply0, bind) {
      this.Apply0 = Apply0;
      this.bind = bind;
  };
  var discard = function (dict) {
      return dict.discard;
  };                     
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  };
  var composeKleisli = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bind(dictBind)(f(a))(g);
              };
          };
      };
  };
  var discardUnit = new Discard(function (dictBind) {
      return bind(dictBind);
  });
  var join = function (dictBind) {
      return function (m) {
          return bind(dictBind)(m)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["discard"] = discard;
  exports["join"] = join;
  exports["composeKleisli"] = composeKleisli;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["discardUnit"] = discardUnit;
})(PS);
(function(exports) {
  "use strict";

  var refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqBooleanImpl = refEq;
  exports.eqIntImpl = refEq; 
  exports.eqStringImpl = refEq;
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];
  var Eq = function (eq) {
      this.eq = eq;
  }; 
  var eqUnit = new Eq(function (v) {
      return function (v1) {
          return true;
      };
  });
  var eqString = new Eq($foreign.eqStringImpl);
  var eqInt = new Eq($foreign.eqIntImpl);  
  var eqBoolean = new Eq($foreign.eqBooleanImpl);
  var eq = function (dict) {
      return dict.eq;
  };
  var notEq = function (dictEq) {
      return function (x) {
          return function (y) {
              return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
          };
      };
  };
  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["notEq"] = notEq;
  exports["eqBoolean"] = eqBoolean;
  exports["eqInt"] = eqInt;
  exports["eqString"] = eqString;
  exports["eqUnit"] = eqUnit;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];          
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 217, column 1 - line 217, column 51): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Just) {
              return new Just(v(v1.value0));
          };
          return Nothing.value;
      };
  });
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  }; 
  var eqMaybe = function (dictEq) {
      return new Data_Eq.Eq(function (x) {
          return function (y) {
              if (x instanceof Nothing && y instanceof Nothing) {
                  return true;
              };
              if (x instanceof Just && y instanceof Just) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0);
              };
              return false;
          };
      });
  };
  var applyMaybe = new Control_Apply.Apply(function () {
      return functorMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return Data_Functor.map(functorMaybe)(v.value0)(v1);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 67, column 1 - line 69, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindMaybe = new Control_Bind.Bind(function () {
      return applyMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return v1(v.value0);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 125, column 1 - line 127, column 28): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeMaybe = new Control_Applicative.Applicative(function () {
      return applyMaybe;
  }, Just.create);
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["functorMaybe"] = functorMaybe;
  exports["applyMaybe"] = applyMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["eqMaybe"] = eqMaybe;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.MediaType.Common"] = $PS["Data.MediaType.Common"] || {};
  var exports = $PS["Data.MediaType.Common"];          
  var applicationJSON = "application/json";
  var applicationFormURLEncoded = "application/x-www-form-urlencoded";
  exports["applicationFormURLEncoded"] = applicationFormURLEncoded;
  exports["applicationJSON"] = applicationJSON;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Affjax.RequestBody"] = $PS["Affjax.RequestBody"] || {};
  var exports = $PS["Affjax.RequestBody"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_MediaType_Common = $PS["Data.MediaType.Common"];                
  var ArrayView = (function () {
      function ArrayView(value0) {
          this.value0 = value0;
      };
      ArrayView.create = function (value0) {
          return new ArrayView(value0);
      };
      return ArrayView;
  })();
  var Blob = (function () {
      function Blob(value0) {
          this.value0 = value0;
      };
      Blob.create = function (value0) {
          return new Blob(value0);
      };
      return Blob;
  })();
  var Document = (function () {
      function Document(value0) {
          this.value0 = value0;
      };
      Document.create = function (value0) {
          return new Document(value0);
      };
      return Document;
  })();
  var $$String = (function () {
      function $$String(value0) {
          this.value0 = value0;
      };
      $$String.create = function (value0) {
          return new $$String(value0);
      };
      return $$String;
  })();
  var FormData = (function () {
      function FormData(value0) {
          this.value0 = value0;
      };
      FormData.create = function (value0) {
          return new FormData(value0);
      };
      return FormData;
  })();
  var FormURLEncoded = (function () {
      function FormURLEncoded(value0) {
          this.value0 = value0;
      };
      FormURLEncoded.create = function (value0) {
          return new FormURLEncoded(value0);
      };
      return FormURLEncoded;
  })();
  var Json = (function () {
      function Json(value0) {
          this.value0 = value0;
      };
      Json.create = function (value0) {
          return new Json(value0);
      };
      return Json;
  })();
  var toMediaType = function (v) {
      if (v instanceof FormURLEncoded) {
          return new Data_Maybe.Just(Data_MediaType_Common.applicationFormURLEncoded);
      };
      if (v instanceof Json) {
          return new Data_Maybe.Just(Data_MediaType_Common.applicationJSON);
      };
      return Data_Maybe.Nothing.value;
  };
  exports["ArrayView"] = ArrayView;
  exports["Blob"] = Blob;
  exports["Document"] = Document;
  exports["String"] = $$String;
  exports["FormData"] = FormData;
  exports["FormURLEncoded"] = FormURLEncoded;
  exports["Json"] = Json;
  exports["toMediaType"] = toMediaType;
})(PS);
(function(exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];
  var HeytingAlgebra = function (conj, disj, ff, implies, not, tt) {
      this.conj = conj;
      this.disj = disj;
      this.ff = ff;
      this.implies = implies;
      this.not = not;
      this.tt = tt;
  };
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
      return function (b) {
          return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
      };
  }, $foreign.boolNot, true);
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return new HeytingAlgebra(function (f) {
          return function (g) {
              return function (a) {
                  return conj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (g) {
              return function (a) {
                  return disj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (v) {
          return ff(dictHeytingAlgebra);
      }, function (f) {
          return function (g) {
              return function (a) {
                  return implies(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (a) {
              return not(dictHeytingAlgebra)(f(a));
          };
      }, function (v) {
          return tt(dictHeytingAlgebra);
      });
  };
  exports["ff"] = ff;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS);
(function(exports) {
  "use strict";

  exports.concatString = function (s1) {
    return function (s2) {
      return s1 + s2;
    };
  };

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];
  var Semigroup = function (append) {
      this.append = append;
  }; 
  var semigroupString = new Semigroup($foreign.concatString);
  var semigroupArray = new Semigroup($foreign.concatArray);
  var append = function (dict) {
      return dict.append;
  };
  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["semigroupString"] = semigroupString;
  exports["semigroupArray"] = semigroupArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Monoid = function (Semigroup0, mempty) {
      this.Semigroup0 = Semigroup0;
      this.mempty = mempty;
  };                 
  var monoidString = new Monoid(function () {
      return Data_Semigroup.semigroupString;
  }, "");
  var mempty = function (dict) {
      return dict.mempty;
  };
  var guard = function (dictMonoid) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return mempty(dictMonoid);
              };
              throw new Error("Failed pattern match at Data.Monoid (line 73, column 1 - line 73, column 49): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
  exports["guard"] = guard;
  exports["monoidString"] = monoidString;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Monoid.Disj"] = $PS["Data.Monoid.Disj"] || {};
  var exports = $PS["Data.Monoid.Disj"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Disj = function (x) {
      return x;
  };
  var semigroupDisj = function (dictHeytingAlgebra) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
          };
      });
  };
  var monoidDisj = function (dictHeytingAlgebra) {
      return new Data_Monoid.Monoid(function () {
          return semigroupDisj(dictHeytingAlgebra);
      }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
  };
  exports["Disj"] = Disj;
  exports["monoidDisj"] = monoidDisj;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Monoid.Dual"] = $PS["Data.Monoid.Dual"] || {};
  var exports = $PS["Data.Monoid.Dual"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Dual = function (x) {
      return x;
  };
  var semigroupDual = function (dictSemigroup) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Data_Semigroup.append(dictSemigroup)(v1)(v);
          };
      });
  };
  var monoidDual = function (dictMonoid) {
      return new Data_Monoid.Monoid(function () {
          return semigroupDual(dictMonoid.Semigroup0());
      }, Data_Monoid.mempty(dictMonoid));
  };
  exports["Dual"] = Dual;
  exports["monoidDual"] = monoidDual;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Monoid.Endo"] = $PS["Data.Monoid.Endo"] || {};
  var exports = $PS["Data.Monoid.Endo"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Endo = function (x) {
      return x;
  };
  var semigroupEndo = function (dictSemigroupoid) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Control_Semigroupoid.compose(dictSemigroupoid)(v)(v1);
          };
      });
  };
  var monoidEndo = function (dictCategory) {
      return new Data_Monoid.Monoid(function () {
          return semigroupEndo(dictCategory.Semigroupoid0());
      }, Control_Category.identity(dictCategory));
  };
  exports["Endo"] = Endo;
  exports["monoidEndo"] = monoidEndo;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid_Disj = $PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = $PS["Data.Monoid.Dual"];
  var Data_Monoid_Endo = $PS["Data.Monoid.Endo"];                      
  var Newtype = function (unwrap, wrap) {
      this.unwrap = unwrap;
      this.wrap = wrap;
  };
  var wrap = function (dict) {
      return dict.wrap;
  };
  var unwrap = function (dict) {
      return dict.unwrap;
  };                             
  var newtypeEndo = new Newtype(function (v) {
      return v;
  }, Data_Monoid_Endo.Endo);
  var newtypeDual = new Newtype(function (v) {
      return v;
  }, Data_Monoid_Dual.Dual);
  var newtypeDisj = new Newtype(function (v) {
      return v;
  }, Data_Monoid_Disj.Disj);
  var alaF = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (v) {
                      return function (f) {
                          var $96 = Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1));
                          var $97 = Data_Functor.map(dictFunctor)(wrap(dictNewtype));
                          return function ($98) {
                              return $96(f($97($98)));
                          };
                      };
                  };
              };
          };
      };
  };
  exports["unwrap"] = unwrap;
  exports["Newtype"] = Newtype;
  exports["alaF"] = alaF;
  exports["newtypeDisj"] = newtypeDisj;
  exports["newtypeDual"] = newtypeDual;
  exports["newtypeEndo"] = newtypeEndo;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.MediaType"] = $PS["Data.MediaType"] || {};
  var exports = $PS["Data.MediaType"];
  var Data_Newtype = $PS["Data.Newtype"];          
  var MediaType = function (x) {
      return x;
  }; 
  var newtypeMediaType = new Data_Newtype.Newtype(function (n) {
      return n;
  }, MediaType);
  exports["newtypeMediaType"] = newtypeMediaType;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Affjax.RequestHeader"] = $PS["Affjax.RequestHeader"] || {};
  var exports = $PS["Affjax.RequestHeader"];
  var Data_MediaType = $PS["Data.MediaType"];
  var Data_Newtype = $PS["Data.Newtype"];          
  var Accept = (function () {
      function Accept(value0) {
          this.value0 = value0;
      };
      Accept.create = function (value0) {
          return new Accept(value0);
      };
      return Accept;
  })();
  var ContentType = (function () {
      function ContentType(value0) {
          this.value0 = value0;
      };
      ContentType.create = function (value0) {
          return new ContentType(value0);
      };
      return ContentType;
  })();
  var RequestHeader = (function () {
      function RequestHeader(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RequestHeader.create = function (value0) {
          return function (value1) {
              return new RequestHeader(value0, value1);
          };
      };
      return RequestHeader;
  })();
  var value = function (v) {
      if (v instanceof Accept) {
          return Data_Newtype.unwrap(Data_MediaType.newtypeMediaType)(v.value0);
      };
      if (v instanceof ContentType) {
          return Data_Newtype.unwrap(Data_MediaType.newtypeMediaType)(v.value0);
      };
      if (v instanceof RequestHeader) {
          return v.value1;
      };
      throw new Error("Failed pattern match at Affjax.RequestHeader (line 26, column 1 - line 26, column 33): " + [ v.constructor.name ]);
  }; 
  var name = function (v) {
      if (v instanceof Accept) {
          return "Accept";
      };
      if (v instanceof ContentType) {
          return "Content-Type";
      };
      if (v instanceof RequestHeader) {
          return v.value0;
      };
      throw new Error("Failed pattern match at Affjax.RequestHeader (line 21, column 1 - line 21, column 32): " + [ v.constructor.name ]);
  };
  exports["Accept"] = Accept;
  exports["ContentType"] = ContentType;
  exports["name"] = name;
  exports["value"] = value;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Affjax.ResponseFormat"] = $PS["Affjax.ResponseFormat"] || {};
  var exports = $PS["Affjax.ResponseFormat"];
  var Control_Category = $PS["Control.Category"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_MediaType_Common = $PS["Data.MediaType.Common"];                
  var $$ArrayBuffer = (function () {
      function $$ArrayBuffer(value0) {
          this.value0 = value0;
      };
      $$ArrayBuffer.create = function (value0) {
          return new $$ArrayBuffer(value0);
      };
      return $$ArrayBuffer;
  })();
  var Blob = (function () {
      function Blob(value0) {
          this.value0 = value0;
      };
      Blob.create = function (value0) {
          return new Blob(value0);
      };
      return Blob;
  })();
  var Document = (function () {
      function Document(value0) {
          this.value0 = value0;
      };
      Document.create = function (value0) {
          return new Document(value0);
      };
      return Document;
  })();
  var Json = (function () {
      function Json(value0) {
          this.value0 = value0;
      };
      Json.create = function (value0) {
          return new Json(value0);
      };
      return Json;
  })();
  var $$String = (function () {
      function $$String(value0) {
          this.value0 = value0;
      };
      $$String.create = function (value0) {
          return new $$String(value0);
      };
      return $$String;
  })();
  var Ignore = (function () {
      function Ignore(value0) {
          this.value0 = value0;
      };
      Ignore.create = function (value0) {
          return new Ignore(value0);
      };
      return Ignore;
  })();
  var toResponseType = function (v) {
      if (v instanceof $$ArrayBuffer) {
          return "arraybuffer";
      };
      if (v instanceof Blob) {
          return "blob";
      };
      if (v instanceof Document) {
          return "document";
      };
      if (v instanceof Json) {
          return "text";
      };
      if (v instanceof $$String) {
          return "text";
      };
      if (v instanceof Ignore) {
          return "";
      };
      throw new Error("Failed pattern match at Affjax.ResponseFormat (line 44, column 3 - line 50, column 19): " + [ v.constructor.name ]);
  };
  var toMediaType = function (v) {
      if (v instanceof Json) {
          return new Data_Maybe.Just(Data_MediaType_Common.applicationJSON);
      };
      return Data_Maybe.Nothing.value;
  };                                                                                
  var json = new Json(Control_Category.identity(Control_Category.categoryFn));
  var ignore = new Ignore(Control_Category.identity(Control_Category.categoryFn));
  exports["ArrayBuffer"] = $$ArrayBuffer;
  exports["Blob"] = Blob;
  exports["Document"] = Document;
  exports["Json"] = Json;
  exports["String"] = $$String;
  exports["Ignore"] = Ignore;
  exports["json"] = json;
  exports["ignore"] = ignore;
  exports["toResponseType"] = toResponseType;
  exports["toMediaType"] = toMediaType;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Affjax.ResponseHeader"] = $PS["Affjax.ResponseHeader"] || {};
  var exports = $PS["Affjax.ResponseHeader"];      
  var ResponseHeader = (function () {
      function ResponseHeader(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ResponseHeader.create = function (value0) {
          return function (value1) {
              return new ResponseHeader(value0, value1);
          };
      };
      return ResponseHeader;
  })();
  exports["ResponseHeader"] = ResponseHeader;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Bifunctor"] = $PS["Data.Bifunctor"] || {};
  var exports = $PS["Data.Bifunctor"];
  var Control_Category = $PS["Control.Category"];                
  var Bifunctor = function (bimap) {
      this.bimap = bimap;
  };
  var bimap = function (dict) {
      return dict.bimap;
  };
  var lmap = function (dictBifunctor) {
      return function (f) {
          return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var rmap = function (dictBifunctor) {
      return bimap(dictBifunctor)(Control_Category.identity(Control_Category.categoryFn));
  };
  exports["bimap"] = bimap;
  exports["Bifunctor"] = Bifunctor;
  exports["lmap"] = lmap;
  exports["rmap"] = rmap;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var note = function (a) {
      return Data_Maybe.maybe(new Left(a))(Right.create);
  };
  var functorEither = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Left) {
              return new Left(m.value0);
          };
          if (m instanceof Right) {
              return new Right(f(m.value0));
          };
          throw new Error("Failed pattern match at Data.Either (line 38, column 1 - line 38, column 52): " + [ m.constructor.name ]);
      };
  });
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either (line 238, column 1 - line 238, column 64): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var bifunctorEither = new Data_Bifunctor.Bifunctor(function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return new Left(v(v2.value0));
              };
              if (v2 instanceof Right) {
                  return new Right(v1(v2.value0));
              };
              throw new Error("Failed pattern match at Data.Either (line 46, column 1 - line 48, column 36): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  });
  var applyEither = new Control_Apply.Apply(function () {
      return functorEither;
  }, function (v) {
      return function (v1) {
          if (v instanceof Left) {
              return new Left(v.value0);
          };
          if (v instanceof Right) {
              return Data_Functor.map(functorEither)(v.value0)(v1);
          };
          throw new Error("Failed pattern match at Data.Either (line 82, column 1 - line 84, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindEither = new Control_Bind.Bind(function () {
      return applyEither;
  }, either(function (e) {
      return function (v) {
          return new Left(e);
      };
  })(function (a) {
      return function (f) {
          return f(a);
      };
  }));
  var applicativeEither = new Control_Applicative.Applicative(function () {
      return applyEither;
  }, Right.create);
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["note"] = note;
  exports["functorEither"] = functorEither;
  exports["bifunctorEither"] = bifunctorEither;
  exports["applyEither"] = applyEither;
  exports["applicativeEither"] = applicativeEither;
  exports["bindEither"] = bindEither;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                        
  var MonadThrow = function (Monad0, throwError) {
      this.Monad0 = Monad0;
      this.throwError = throwError;
  };
  var MonadError = function (MonadThrow0, catchError) {
      this.MonadThrow0 = MonadThrow0;
      this.catchError = catchError;
  };
  var throwError = function (dict) {
      return dict.throwError;
  };                                                      
  var catchError = function (dict) {
      return dict.catchError;
  };
  var $$try = function (dictMonadError) {
      return function (a) {
          return catchError(dictMonadError)(Data_Functor.map(((((dictMonadError.MonadThrow0()).Monad0()).Bind1()).Apply0()).Functor0())(Data_Either.Right.create)(a))((function () {
              var $17 = Control_Applicative.pure(((dictMonadError.MonadThrow0()).Monad0()).Applicative0());
              return function ($18) {
                  return $17(Data_Either.Left.create($18));
              };
          })());
      };
  };
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
  exports["MonadError"] = MonadError;
  exports["try"] = $$try;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];                
  var Monad = function (Applicative0, Bind1) {
      this.Applicative0 = Applicative0;
      this.Bind1 = Bind1;
  };
  var unlessM = function (dictMonad) {
      return function (mb) {
          return function (m) {
              return Control_Bind.bind(dictMonad.Bind1())(mb)(function (b) {
                  return Control_Applicative.unless(dictMonad.Applicative0())(b)(m);
              });
          };
      };
  };
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (f$prime) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (a$prime) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(f$prime(a$prime));
                  });
              });
          };
      };
  };
  exports["Monad"] = Monad;
  exports["ap"] = ap;
  exports["unlessM"] = unlessM;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Except.Trans"] = $PS["Control.Monad.Except.Trans"] || {};
  var exports = $PS["Control.Monad.Except.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                
  var ExceptT = function (x) {
      return x;
  };
  var runExceptT = function (v) {
      return v;
  }; 
  var mapExceptT = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var functorExceptT = function (dictFunctor) {
      return new Data_Functor.Functor(function (f) {
          return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
      });
  };
  var monadExceptT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeExceptT(dictMonad);
      }, function () {
          return bindExceptT(dictMonad);
      });
  };
  var bindExceptT = function (dictMonad) {
      return new Control_Bind.Bind(function () {
          return applyExceptT(dictMonad);
      }, function (v) {
          return function (k) {
              return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either((function () {
                  var $90 = Control_Applicative.pure(dictMonad.Applicative0());
                  return function ($91) {
                      return $90(Data_Either.Left.create($91));
                  };
              })())(function (a) {
                  var v1 = k(a);
                  return v1;
              }));
          };
      });
  };
  var applyExceptT = function (dictMonad) {
      return new Control_Apply.Apply(function () {
          return functorExceptT(((dictMonad.Bind1()).Apply0()).Functor0());
      }, Control_Monad.ap(monadExceptT(dictMonad)));
  };
  var applicativeExceptT = function (dictMonad) {
      return new Control_Applicative.Applicative(function () {
          return applyExceptT(dictMonad);
      }, (function () {
          var $92 = Control_Applicative.pure(dictMonad.Applicative0());
          return function ($93) {
              return ExceptT($92(Data_Either.Right.create($93)));
          };
      })());
  };
  var monadThrowExceptT = function (dictMonad) {
      return new Control_Monad_Error_Class.MonadThrow(function () {
          return monadExceptT(dictMonad);
      }, (function () {
          var $102 = Control_Applicative.pure(dictMonad.Applicative0());
          return function ($103) {
              return ExceptT($102(Data_Either.Left.create($103)));
          };
      })());
  };
  exports["ExceptT"] = ExceptT;
  exports["runExceptT"] = runExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Identity"] = $PS["Data.Identity"] || {};
  var exports = $PS["Data.Identity"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Newtype = $PS["Data.Newtype"];          
  var Identity = function (x) {
      return x;
  };
  var newtypeIdentity = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Identity);
  var functorIdentity = new Data_Functor.Functor(function (f) {
      return function (m) {
          return f(m);
      };
  });
  var applyIdentity = new Control_Apply.Apply(function () {
      return functorIdentity;
  }, function (v) {
      return function (v1) {
          return v(v1);
      };
  });
  var bindIdentity = new Control_Bind.Bind(function () {
      return applyIdentity;
  }, function (v) {
      return function (f) {
          return f(v);
      };
  });
  var applicativeIdentity = new Control_Applicative.Applicative(function () {
      return applyIdentity;
  }, Identity);
  var monadIdentity = new Control_Monad.Monad(function () {
      return applicativeIdentity;
  }, function () {
      return bindIdentity;
  });
  exports["newtypeIdentity"] = newtypeIdentity;
  exports["functorIdentity"] = functorIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Except"] = $PS["Control.Monad.Except"] || {};
  var exports = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Newtype = $PS["Data.Newtype"];                                                
  var runExcept = (function () {
      var $0 = Data_Newtype.unwrap(Data_Identity.newtypeIdentity);
      return function ($1) {
          return $0(Control_Monad_Except_Trans.runExceptT($1));
      };
  })();
  exports["runExcept"] = runExcept;
})(PS);
(function(exports) {
  "use strict";

  function id(x) {
    return x;
  }                        
  exports.fromNumber = id;
  exports.fromString = id;
  exports.fromArray = id;
  exports.fromObject = id;

  exports.jsonNull = null;

  exports.stringify = function (j) {
    return JSON.stringify(j);
  };

  function isArray(a) {
    return Object.prototype.toString.call(a) === "[object Array]";
  }

  exports._caseJson = function (isNull, isBool, isNum, isStr, isArr, isObj, j) {
    if (j == null) return isNull();
    else if (typeof j === "boolean") return isBool(j);
    else if (typeof j === "number") return isNum(j);
    else if (typeof j === "string") return isStr(j);
    else if (Object.prototype.toString.call(j) === "[object Array]")
      return isArr(j);
    else return isObj(j);
  };
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});
(function(exports) {
  "use strict";

  exports._copyST = function (m) {
    return function () {
      var r = {};
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r[k] = m[k];
        }
      }
      return r;
    };
  };

  exports.empty = {};

  exports.runST = function (f) {
    return f();
  };

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };

  function toArrayWithKey(f) {
    return function (m) {
      var r = [];
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r.push(f(k)(m[k]));
        }
      }
      return r;
    };
  }
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});
(function(exports) {
  "use strict";

  exports.runFn2 = function (fn) {
    return function (a) {
      return function (b) {
        return fn(a, b);
      };
    };
  };

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Function.Uncurried"] = $PS["Data.Function.Uncurried"] || {};
  var exports = $PS["Data.Function.Uncurried"];
  var $foreign = $PS["Data.Function.Uncurried"];
  exports["runFn2"] = $foreign.runFn2;
  exports["runFn4"] = $foreign.runFn4;
})(PS);
(function(exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };

  exports.poke = function (k) {
    return function (v) {
      return function (m) {
        return function () {
          m[k] = v;
          return m;
        };
      };
    };
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Foreign.Object.ST"] = $PS["Foreign.Object.ST"] || {};
  var exports = $PS["Foreign.Object.ST"];
  var $foreign = $PS["Foreign.Object.ST"];
  exports["new"] = $foreign["new"];
  exports["poke"] = $foreign.poke;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Foreign.Object"] = $PS["Foreign.Object"] || {};
  var exports = $PS["Foreign.Object"];
  var $foreign = $PS["Foreign.Object"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];              
  var thawST = $foreign["_copyST"];
  var mutate = function (f) {
      return function (m) {
          return $foreign.runST(function __do() {
              var s = thawST(m)();
              f(s)();
              return s;
          });
      };
  };
  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var insert = function (k) {
      return function (v) {
          return mutate(Foreign_Object_ST.poke(k)(v));
      };
  };
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["empty"] = $foreign.empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Argonaut.Core"] = $PS["Data.Argonaut.Core"] || {};
  var exports = $PS["Data.Argonaut.Core"];
  var $foreign = $PS["Data.Argonaut.Core"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign_Object = $PS["Foreign.Object"];                
  var verbJsonType = function (def) {
      return function (f) {
          return function (g) {
              return g(def)(f);
          };
      };
  };
  var toJsonType = verbJsonType(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var jsonEmptyObject = $foreign.fromObject(Foreign_Object.empty);
  var isJsonType = verbJsonType(false)(Data_Function["const"](true));
  var caseJsonString = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var caseJsonObject = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, j);
          };
      };
  };                                        
  var toObject = toJsonType(caseJsonObject);
  var caseJsonNumber = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var caseJsonNull = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };
  var isNull = isJsonType(caseJsonNull);      
  var caseJsonArray = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), j);
          };
      };
  };                                      
  var toArray = toJsonType(caseJsonArray);
  exports["caseJsonNumber"] = caseJsonNumber;
  exports["caseJsonString"] = caseJsonString;
  exports["isNull"] = isNull;
  exports["toArray"] = toArray;
  exports["toObject"] = toObject;
  exports["jsonEmptyObject"] = jsonEmptyObject;
  exports["fromNumber"] = $foreign.fromNumber;
  exports["fromString"] = $foreign.fromString;
  exports["fromArray"] = $foreign.fromArray;
  exports["fromObject"] = $foreign.fromObject;
  exports["jsonNull"] = $foreign.jsonNull;
  exports["stringify"] = $foreign.stringify;
})(PS);
(function(exports) {
  "use strict";

  exports._jsonParser = function (fail, succ, s) {
    try {
      return succ(JSON.parse(s));
    }
    catch (e) {
      return fail(e.message);
    }
  };
})(PS["Data.Argonaut.Parser"] = PS["Data.Argonaut.Parser"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Argonaut.Parser"] = $PS["Data.Argonaut.Parser"] || {};
  var exports = $PS["Data.Argonaut.Parser"];
  var $foreign = $PS["Data.Argonaut.Parser"];
  var Data_Either = $PS["Data.Either"];                
  var jsonParser = function (j) {
      return $foreign["_jsonParser"](Data_Either.Left.create, Data_Either.Right.create, j);
  };
  exports["jsonParser"] = jsonParser;
})(PS);
(function(exports) {
  "use strict";

  //------------------------------------------------------------------------------
  // Array creation --------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.range = function (start) {
    return function (end) {
      var step = start > end ? -1 : 1;
      var result = new Array(step * (end - start) + 1);
      var i = start, n = 0;
      while (i !== end) {
        result[n++] = i;
        i += step;
      }
      result[n] = i;
      return result;
    };
  };   

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  exports.snoc = function (l) {
    return function (e) {
      var l1 = l.slice();
      l1.push(e);
      return l1;
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  //------------------------------------------------------------------------------
  // Zipping ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.zipWith = function (f) {
    return function (xs) {
      return function (ys) {
        var l = xs.length < ys.length ? xs.length : ys.length;
        var result = new Array(l);
        for (var i = 0; i < l; i++) {
          result[i] = f(xs[i])(ys[i]);
        }
        return result;
      };
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Data_Maybe = $PS["Data.Maybe"];
  var $$null = function (xs) {
      return $foreign.length(xs) === 0;
  };
  var mapWithIndex = function (f) {
      return function (xs) {
          return $foreign.zipWith(f)($foreign.range(0)($foreign.length(xs) - 1 | 0))(xs);
      };
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["null"] = $$null;
  exports["index"] = index;
  exports["mapWithIndex"] = mapWithIndex;
  exports["length"] = $foreign.length;
  exports["snoc"] = $foreign.snoc;
})(PS);
(function(exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Monoid_Disj = $PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = $PS["Data.Monoid.Dual"];
  var Data_Monoid_Endo = $PS["Data.Monoid.Endo"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];                
  var Foldable = function (foldMap, foldl, foldr) {
      this.foldMap = foldMap;
      this.foldl = foldl;
      this.foldr = foldr;
  };
  var foldr = function (dict) {
      return dict.foldr;
  };
  var traverse_ = function (dictApplicative) {
      return function (dictFoldable) {
          return function (f) {
              return foldr(dictFoldable)((function () {
                  var $197 = Control_Apply.applySecond(dictApplicative.Apply0());
                  return function ($198) {
                      return $197(f($198));
                  };
              })())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
          };
      };
  };
  var for_ = function (dictApplicative) {
      return function (dictFoldable) {
          return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
      };
  };
  var foldl = function (dict) {
      return dict.foldl;
  }; 
  var foldableMaybe = new Foldable(function (dictMonoid) {
      return function (f) {
          return function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v instanceof Data_Maybe.Just) {
                  return f(v.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 135, column 27): " + [ f.constructor.name, v.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(z)(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 135, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(v1.value0)(z);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 135, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = new Foldable(function (dictMonoid) {
      return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);
  var foldMap = function (dict) {
      return dict.foldMap;
  }; 
  var foldlDefault = function (dictFoldable) {
      return function (c) {
          return function (u) {
              return function (xs) {
                  return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(Data_Newtype.unwrap(Data_Newtype.newtypeDual)(foldMap(dictFoldable)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))((function () {
                      var $201 = Data_Function.flip(c);
                      return function ($202) {
                          return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo($201($202)));
                      };
                  })())(xs)))(u);
              };
          };
      };
  };
  var foldrDefault = function (dictFoldable) {
      return function (c) {
          return function (u) {
              return function (xs) {
                  return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMap(dictFoldable)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function ($203) {
                      return Data_Monoid_Endo.Endo(c($203));
                  })(xs))(u);
              };
          };
      };
  };
  var any = function (dictFoldable) {
      return function (dictHeytingAlgebra) {
          return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeDisj)(Data_Newtype.newtypeDisj)(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
      };
  };
  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["foldrDefault"] = foldrDefault;
  exports["foldlDefault"] = foldlDefault;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["any"] = any;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
})(PS);
(function(exports) {
  "use strict";

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];                
  var $$null = function (s) {
      return s === "";
  };
  exports["null"] = $$null;
  exports["joinWith"] = $foreign.joinWith;
})(PS);
(function(exports) {
  "use strict";

  // jshint maxparams: 3

  exports.traverseArrayImpl = function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                case 0: return pure([]);
                case 1: return map(array1)(f(array[bot]));
                case 2: return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));
                case 3: return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));
                default:
                  // This slightly tricky pivot selection aims to produce two
                  // even-length partitions where possible.
                  var pivot = bot + Math.floor((top - bot) / 4) * 2;
                  return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }
              return go(0, array.length);
            };
          };
        };
      };
    };
  }();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Traversable"] = $PS["Data.Traversable"] || {};
  var exports = $PS["Data.Traversable"];
  var $foreign = $PS["Data.Traversable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];                                                          
  var Traversable = function (Foldable1, Functor0, sequence, traverse) {
      this.Foldable1 = Foldable1;
      this.Functor0 = Functor0;
      this.sequence = sequence;
      this.traverse = traverse;
  };
  var traverse = function (dict) {
      return dict.traverse;
  }; 
  var traversableMaybe = new Traversable(function () {
      return Data_Foldable.foldableMaybe;
  }, function () {
      return Data_Maybe.functorMaybe;
  }, function (dictApplicative) {
      return function (v) {
          if (v instanceof Data_Maybe.Nothing) {
              return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
          };
          if (v instanceof Data_Maybe.Just) {
              return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v.value0);
          };
          throw new Error("Failed pattern match at Data.Traversable (line 86, column 1 - line 90, column 33): " + [ v.constructor.name ]);
      };
  }, function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v(v1.value0));
              };
              throw new Error("Failed pattern match at Data.Traversable (line 86, column 1 - line 90, column 33): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var sequenceDefault = function (dictTraversable) {
      return function (dictApplicative) {
          return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var traversableArray = new Traversable(function () {
      return Data_Foldable.foldableArray;
  }, function () {
      return Data_Functor.functorArray;
  }, function (dictApplicative) {
      return sequenceDefault(traversableArray)(dictApplicative);
  }, function (dictApplicative) {
      return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
  });
  var sequence = function (dict) {
      return dict.sequence;
  };
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["traversableArray"] = traversableArray;
  exports["traversableMaybe"] = traversableMaybe;
})(PS);
(function(exports) {
  /* globals exports */
  "use strict";                                      

  var encdecURI = function (encdec) {
    return function (fail, succ, s) {
      try {
        return succ(encdec(s));
      }
      catch (e) {
        return fail(e.message);
      }
    };
  };                                                          
  exports._encodeURIComponent = encdecURI(encodeURIComponent);
})(PS["Global"] = PS["Global"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Global"] = $PS["Global"] || {};
  var exports = $PS["Global"];
  var $foreign = $PS["Global"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var $$encodeURIComponent = function (s) {
      return $foreign["_encodeURIComponent"](Data_Function["const"](Data_Maybe.Nothing.value), Data_Maybe.Just.create, s);
  };
  exports["encodeURIComponent"] = $$encodeURIComponent;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.FormURLEncoded"] = $PS["Data.FormURLEncoded"] || {};
  var exports = $PS["Data.FormURLEncoded"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Global = $PS["Global"];
  var toArray = function (v) {
      return v;
  };                                                                                                                 
  var encode = (function () {
      var encodePart = function (v) {
          if (v.value1 instanceof Data_Maybe.Nothing) {
              return Global["encodeURIComponent"](v.value0);
          };
          if (v.value1 instanceof Data_Maybe.Just) {
              return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (key) {
                  return function (val) {
                      return key + ("=" + val);
                  };
              })(Global["encodeURIComponent"](v.value0)))(Global["encodeURIComponent"](v.value1.value0));
          };
          throw new Error("Failed pattern match at Data.FormURLEncoded (line 37, column 18 - line 39, column 108): " + [ v.constructor.name ]);
      };
      var $19 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_String_Common.joinWith("&"));
      var $20 = Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)(encodePart);
      return function ($21) {
          return $19($20(toArray($21)));
      };
  })();
  exports["encode"] = encode;
})(PS);
(function(exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(
      /[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
      function (c, i) {
        switch (c) {
          case "\"":
          case "\\":
            return "\\" + c;
          case "\x07": return "\\a";
          case "\b": return "\\b";
          case "\f": return "\\f";
          case "\n": return "\\n";
          case "\r": return "\\r";
          case "\t": return "\\t";
          case "\v": return "\\v";
        }
        var k = i + 1;
        var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
        return "\\" + c.charCodeAt(0).toString(10) + empty;
      }
    ) + "\"";
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];
  var Show = function (show) {
      this.show = show;
  };
  var showString = new Show($foreign.showStringImpl);
  var showInt = new Show($foreign.showIntImpl);
  var show = function (dict) {
      return dict.show;
  };
  exports["Show"] = Show;
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showString"] = showString;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.HTTP.Method"] = $PS["Data.HTTP.Method"] || {};
  var exports = $PS["Data.HTTP.Method"];
  var Data_Either = $PS["Data.Either"];
  var Data_Show = $PS["Data.Show"];                                  
  var OPTIONS = (function () {
      function OPTIONS() {

      };
      OPTIONS.value = new OPTIONS();
      return OPTIONS;
  })();
  var GET = (function () {
      function GET() {

      };
      GET.value = new GET();
      return GET;
  })();
  var HEAD = (function () {
      function HEAD() {

      };
      HEAD.value = new HEAD();
      return HEAD;
  })();
  var POST = (function () {
      function POST() {

      };
      POST.value = new POST();
      return POST;
  })();
  var PUT = (function () {
      function PUT() {

      };
      PUT.value = new PUT();
      return PUT;
  })();
  var DELETE = (function () {
      function DELETE() {

      };
      DELETE.value = new DELETE();
      return DELETE;
  })();
  var TRACE = (function () {
      function TRACE() {

      };
      TRACE.value = new TRACE();
      return TRACE;
  })();
  var CONNECT = (function () {
      function CONNECT() {

      };
      CONNECT.value = new CONNECT();
      return CONNECT;
  })();
  var PROPFIND = (function () {
      function PROPFIND() {

      };
      PROPFIND.value = new PROPFIND();
      return PROPFIND;
  })();
  var PROPPATCH = (function () {
      function PROPPATCH() {

      };
      PROPPATCH.value = new PROPPATCH();
      return PROPPATCH;
  })();
  var MKCOL = (function () {
      function MKCOL() {

      };
      MKCOL.value = new MKCOL();
      return MKCOL;
  })();
  var COPY = (function () {
      function COPY() {

      };
      COPY.value = new COPY();
      return COPY;
  })();
  var MOVE = (function () {
      function MOVE() {

      };
      MOVE.value = new MOVE();
      return MOVE;
  })();
  var LOCK = (function () {
      function LOCK() {

      };
      LOCK.value = new LOCK();
      return LOCK;
  })();
  var UNLOCK = (function () {
      function UNLOCK() {

      };
      UNLOCK.value = new UNLOCK();
      return UNLOCK;
  })();
  var PATCH = (function () {
      function PATCH() {

      };
      PATCH.value = new PATCH();
      return PATCH;
  })();
  var unCustomMethod = function (v) {
      return v;
  };
  var showMethod = new Data_Show.Show(function (v) {
      if (v instanceof OPTIONS) {
          return "OPTIONS";
      };
      if (v instanceof GET) {
          return "GET";
      };
      if (v instanceof HEAD) {
          return "HEAD";
      };
      if (v instanceof POST) {
          return "POST";
      };
      if (v instanceof PUT) {
          return "PUT";
      };
      if (v instanceof DELETE) {
          return "DELETE";
      };
      if (v instanceof TRACE) {
          return "TRACE";
      };
      if (v instanceof CONNECT) {
          return "CONNECT";
      };
      if (v instanceof PROPFIND) {
          return "PROPFIND";
      };
      if (v instanceof PROPPATCH) {
          return "PROPPATCH";
      };
      if (v instanceof MKCOL) {
          return "MKCOL";
      };
      if (v instanceof COPY) {
          return "COPY";
      };
      if (v instanceof MOVE) {
          return "MOVE";
      };
      if (v instanceof LOCK) {
          return "LOCK";
      };
      if (v instanceof UNLOCK) {
          return "UNLOCK";
      };
      if (v instanceof PATCH) {
          return "PATCH";
      };
      throw new Error("Failed pattern match at Data.HTTP.Method (line 40, column 1 - line 56, column 23): " + [ v.constructor.name ]);
  });
  var print = Data_Either.either(Data_Show.show(showMethod))(unCustomMethod);
  exports["GET"] = GET;
  exports["print"] = print;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];                          
  var Alt = function (Functor0, alt) {
      this.Functor0 = Functor0;
      this.alt = alt;
  };
  exports["Alt"] = Alt;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];                   
  var Plus = function (Alt0, empty) {
      this.Alt0 = Alt0;
      this.empty = empty;
  };       
  var empty = function (dict) {
      return dict.empty;
  };
  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];                    
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var listMap = function (f) {
      var chunkedRevMap = function ($copy_chunksAcc) {
          return function ($copy_v) {
              var $tco_var_chunksAcc = $copy_chunksAcc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(chunksAcc, v) {
                  if (v instanceof Cons && (v.value1 instanceof Cons && v.value1.value1 instanceof Cons)) {
                      $tco_var_chunksAcc = new Cons(v, chunksAcc);
                      $copy_v = v.value1.value1.value1;
                      return;
                  };
                  var unrolledMap = function (v1) {
                      if (v1 instanceof Cons && (v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil)) {
                          return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
                      };
                      if (v1 instanceof Cons && v1.value1 instanceof Nil) {
                          return new Cons(f(v1.value0), Nil.value);
                      };
                      return Nil.value;
                  };
                  var reverseUnrolledMap = function ($copy_v1) {
                      return function ($copy_acc) {
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v1, acc) {
                              if (v1 instanceof Cons && (v1.value0 instanceof Cons && (v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons))) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                                  return;
                              };
                              $tco_done = true;
                              return acc;
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v1, $copy_acc);
                          };
                          return $tco_result;
                      };
                  };
                  $tco_done = true;
                  return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
              };
              return $tco_result;
          };
      };
      return chunkedRevMap(Nil.value);
  };
  var functorList = new Data_Functor.Functor(listMap);                 
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return Data_Foldable.foldl(foldableList)(function (acc) {
              var $202 = Data_Semigroup.append(dictMonoid.Semigroup0())(acc);
              return function ($203) {
                  return $202(f($203));
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  }, function (f) {
      var go = function ($copy_b) {
          return function ($copy_v) {
              var $tco_var_b = $copy_b;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(b, v) {
                  if (v instanceof Nil) {
                      $tco_done = true;
                      return b;
                  };
                  if (v instanceof Cons) {
                      $tco_var_b = f(b)(v.value0);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List.Types (line 109, column 12 - line 111, column 30): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_b, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  }, function (f) {
      return function (b) {
          var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value);
          var $204 = Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b);
          return function ($205) {
              return $204(rev($205));
          };
      };
  });
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
      return function (ys) {
          return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
      };
  });
  var applyList = new Control_Apply.Apply(function () {
      return functorList;
  }, function (v) {
      return function (v1) {
          if (v instanceof Nil) {
              return Nil.value;
          };
          if (v instanceof Cons) {
              return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.List.Types (line 155, column 1 - line 157, column 48): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeList = new Control_Applicative.Applicative(function () {
      return applyList;
  }, function (a) {
      return new Cons(a, Nil.value);
  });                                              
  var altList = new Control_Alt.Alt(function () {
      return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
      return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["semigroupList"] = semigroupList;
  exports["foldableList"] = foldableList;
  exports["applicativeList"] = applicativeList;
  exports["plusList"] = plusList;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];                        
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var singleton = (function () {
      var $168 = Data_NonEmpty.singleton(Data_List_Types.plusList);
      return function ($169) {
          return Data_List_Types.NonEmptyList($168($169));
      };
  })();
  var head = function (v) {
      return v.value0;
  };
  var cons = function (y) {
      return function (v) {
          return new Data_NonEmpty.NonEmpty(y, new Data_List_Types.Cons(v.value0, v.value1));
      };
  };
  exports["singleton"] = singleton;
  exports["cons"] = cons;
  exports["head"] = head;
})(PS);
(function(exports) {
  "use strict";

  exports["null"] = null;

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };

  exports.notNull = function (x) {
    return x;
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Nullable"] = $PS["Data.Nullable"] || {};
  var exports = $PS["Data.Nullable"];
  var $foreign = $PS["Data.Nullable"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var toNullable = Data_Maybe.maybe($foreign["null"])($foreign.notNull);
  var toMaybe = function (n) {
      return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };
  exports["toMaybe"] = toMaybe;
  exports["toNullable"] = toNullable;
  exports["null"] = $foreign["null"];
})(PS);
(function(exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */
  /* jshint -W083, -W098, -W003 */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};

    /*

  An awkward approximation. We elide evidence we would otherwise need in PS for
  efficiency sake.

  data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)

  */  
    var PURE    = "Pure";
    var THROW   = "Throw";
    var CATCH   = "Catch";
    var SYNC    = "Sync";
    var ASYNC   = "Async";
    var BIND    = "Bind";
    var BRACKET = "Bracket";
    var FORK    = "Fork";
    var SEQ     = "Sequential";

    /*

  data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)

  */  
    var MAP   = "Map";
    var APPLY = "Apply";
    var ALT   = "Alt";

    // Various constructors used in interpretation
    var CONS      = "Cons";      // Cons-list, for stacks
    var RESUME    = "Resume";    // Continue indiscriminately
    var RELEASE   = "Release";   // Continue with bracket finalizers
    var FINALIZER = "Finalizer"; // A non-interruptible effect
    var FINALIZED = "Finalized"; // Marker for finalization
    var FORKED    = "Forked";    // Reference to a forked fiber, with resumption stack
    var FIBER     = "Fiber";     // Actual fiber reference
    var THUNK     = "Thunk";     // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1  = _1;
      this._2  = _2;
      this._3  = _3;
    }

    function AffCtr(tag) {
      var fn = function (_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };
      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit    = 1024;
      var size     = 0;
      var ix       = 0;
      var queue    = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;
        while (size !== 0) {
          size--;
          thunk     = queue[ix];
          queue[ix] = void 0;
          ix        = (ix + 1) % limit;
          thunk();
        }
        draining = false;
      }

      return {
        isDraining: function () {
          return draining;
        },
        enqueue: function (cb) {
          var i, tmp;
          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers  = {};
      var fiberId = 0;
      var count   = 0;

      return {
        register: function (fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function (result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          })();
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function () {
          return count === 0;
        },
        killAll: function (killError, cb) {
          return function () {
            if (count === 0) {
              return cb();
            }

            var killCount = 0;
            var kills     = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;
                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }
                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers  = {};
            fiberId = 0;
            count   = 0;

            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    }

    // Fiber state machine
    var SUSPENDED   = 0; // Suspended, pending a join.
    var CONTINUE    = 1; // Interpret the next instruction.
    var STEP_BIND   = 2; // Apply the next bind.
    var STEP_RESULT = 3; // Handle potential failure from a result.
    var PENDING     = 4; // An async effect is running.
    var RETURN      = 5; // The current stack has returned.
    var COMPLETED   = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0;

      // The current branch of the state machine.
      var status = SUSPENDED;

      // The current point of interest for the state machine branch.
      var step      = aff;  // Successful step
      var fail      = null; // Failure step
      var interrupt = null; // Asynchronous interrupt

      // Stack of continuations for the current fiber.
      var bhead = null;
      var btail = null;

      // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.
      var attempts = null;

      // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.
      var bracketCount = 0;

      // Each join gets a new id so they can be revoked.
      var joinId  = 0;
      var joins   = null;
      var rethrow = true;

      // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.
      function run(localRunTick) {
        var tmp, result, attempt;
        while (true) {
          tmp       = null;
          result    = null;
          attempt   = null;

          switch (status) {
          case STEP_BIND:
            status = CONTINUE;
            try {
              step   = bhead(step);
              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }
            } catch (e) {
              status = RETURN;
              fail   = util.left(e);
              step   = null;
            }
            break;

          case STEP_RESULT:
            if (util.isLeft(step)) {
              status = RETURN;
              fail   = step;
              step   = null;
            } else if (bhead === null) {
              status = RETURN;
            } else {
              status = STEP_BIND;
              step   = util.fromRight(step);
            }
            break;

          case CONTINUE:
            switch (step.tag) {
            case BIND:
              if (bhead) {
                btail = new Aff(CONS, bhead, btail);
              }
              bhead  = step._2;
              status = CONTINUE;
              step   = step._1;
              break;

            case PURE:
              if (bhead === null) {
                status = RETURN;
                step   = util.right(step._1);
              } else {
                status = STEP_BIND;
                step   = step._1;
              }
              break;

            case SYNC:
              status = STEP_RESULT;
              step   = runSync(util.left, util.right, step._1);
              break;

            case ASYNC:
              status = PENDING;
              step   = runAsync(util.left, step._1, function (result) {
                return function () {
                  if (runTick !== localRunTick) {
                    return;
                  }
                  runTick++;
                  Scheduler.enqueue(function () {
                    // It's possible to interrupt the fiber between enqueuing and
                    // resuming, so we need to check that the runTick is still
                    // valid.
                    if (runTick !== localRunTick + 1) {
                      return;
                    }
                    status = STEP_RESULT;
                    step   = result;
                    run(runTick);
                  });
                };
              });
              return;

            case THROW:
              status = RETURN;
              fail   = util.left(step._1);
              step   = null;
              break;

            // Enqueue the Catch so that we can call the error handler later on
            // in case of an exception.
            case CATCH:
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead    = null;
              btail    = null;
              status   = CONTINUE;
              step     = step._1;
              break;

            // Enqueue the Bracket so that we can call the appropriate handlers
            // after resource acquisition.
            case BRACKET:
              bracketCount++;
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead  = null;
              btail  = null;
              status = CONTINUE;
              step   = step._1;
              break;

            case FORK:
              status = STEP_RESULT;
              tmp    = Fiber(util, supervisor, step._2);
              if (supervisor) {
                supervisor.register(tmp);
              }
              if (step._1) {
                tmp.run();
              }
              step = util.right(tmp);
              break;

            case SEQ:
              status = CONTINUE;
              step   = sequential(util, supervisor, step._1);
              break;
            }
            break;

          case RETURN:
            bhead = null;
            btail = null;
            // If the current stack has returned, and we have no other stacks to
            // resume or finalizers to run, the fiber has halted and we can
            // invoke all join callbacks. Otherwise we need to resume.
            if (attempts === null) {
              status = COMPLETED;
              step   = interrupt || fail || step;
            } else {
              // The interrupt status for the enqueued item.
              tmp      = attempts._3;
              attempt  = attempts._1;
              attempts = attempts._2;

              switch (attempt.tag) {
              // We cannot recover from an unmasked interrupt. Otherwise we should
              // continue stepping, or run the exception handler if an exception
              // was raised.
              case CATCH:
                // We should compare the interrupt status as well because we
                // only want it to apply if there has been an interrupt since
                // enqueuing the catch.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  status = RETURN;
                } else if (fail) {
                  status = CONTINUE;
                  step   = attempt._2(util.fromLeft(fail));
                  fail   = null;
                }
                break;

              // We cannot resume from an unmasked interrupt or exception.
              case RESUME:
                // As with Catch, we only want to ignore in the case of an
                // interrupt since enqueing the item.
                if (interrupt && interrupt !== tmp && bracketCount === 0 || fail) {
                  status = RETURN;
                } else {
                  bhead  = attempt._1;
                  btail  = attempt._2;
                  status = STEP_BIND;
                  step   = util.fromRight(step);
                }
                break;

              // If we have a bracket, we should enqueue the handlers,
              // and continue with the success branch only if the fiber has
              // not been interrupted. If the bracket acquisition failed, we
              // should not run either.
              case BRACKET:
                bracketCount--;
                if (fail === null) {
                  result   = util.fromRight(step);
                  // We need to enqueue the Release with the same interrupt
                  // status as the Bracket that is initiating it.
                  attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp);
                  // We should only coninue as long as the interrupt status has not changed or
                  // we are currently within a non-interruptable finalizer.
                  if (interrupt === tmp || bracketCount > 0) {
                    status = CONTINUE;
                    step   = attempt._3(result);
                  }
                }
                break;

              // Enqueue the appropriate handler. We increase the bracket count
              // because it should not be cancelled.
              case RELEASE:
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                // It has only been killed if the interrupt status has changed
                // since we enqueued the item, and the bracket count is 0. If the
                // bracket count is non-zero then we are in a masked state so it's
                // impossible to be killed.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                } else if (fail) {
                  step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                } else {
                  step = attempt._1.completed(util.fromRight(step))(attempt._2);
                }
                fail = null;
                bracketCount++;
                break;

              case FINALIZER:
                bracketCount++;
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                step     = attempt._1;
                break;

              case FINALIZED:
                bracketCount--;
                status = RETURN;
                step   = attempt._1;
                fail   = attempt._2;
                break;
              }
            }
            break;

          case COMPLETED:
            for (var k in joins) {
              if (joins.hasOwnProperty(k)) {
                rethrow = rethrow && joins[k].rethrow;
                runEff(joins[k].handler(step));
              }
            }
            joins = null;
            // If we have an interrupt and a fail, then the thread threw while
            // running finalizers. This should always rethrow in a fresh stack.
            if (interrupt && fail) {
              setTimeout(function () {
                throw util.fromLeft(fail);
              }, 0);
            // If we have an unhandled exception, and no other fiber has joined
            // then we need to throw the exception in a fresh stack.
            } else if (util.isLeft(step) && rethrow) {
              setTimeout(function () {
                // Guard on reathrow because a completely synchronous fiber can
                // still have an observer which was added after-the-fact.
                if (rethrow) {
                  throw util.fromLeft(step);
                }
              }, 0);
            }
            return;
          case SUSPENDED:
            status = CONTINUE;
            break;
          case PENDING: return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid    = joinId++;
          joins      = joins || {};
          joins[jid] = join;

          return function() {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function (/* unused */) {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
          case SUSPENDED:
            interrupt = util.left(error);
            status    = COMPLETED;
            step      = interrupt;
            run(runTick);
            break;
          case PENDING:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              if (status === PENDING) {
                attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
              }
              status   = RETURN;
              step     = null;
              fail     = null;
              run(++runTick);
            }
            break;
          default:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              status = RETURN;
              step   = null;
              fail   = null;
            }
          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();
          if (status === SUSPENDED) {
            run(runTick);
          }
          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function () {
          return status === SUSPENDED;
        },
        run: function () {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                run(runTick);
              });
            } else {
              run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId   = 0;
      var fibers    = {};

      // Table of currently running cancelers, as a product of `Alt` behavior.
      var killId    = 0;
      var kills     = {};

      // Error used for early cancelation on Alt branches.
      var early     = new Error("[ParAff] Early exit");

      // Error used to kill the entire tree.
      var interrupt = null;

      // The root pointer of the tree.
      var root      = EMPTY;

      // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.
      function kill(error, par, cb) {
        var step  = par;
        var head  = null;
        var tail  = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
          case FORKED:
            if (step._3 === EMPTY) {
              tmp = fibers[step._1];
              kills[count++] = tmp.kill(error, function (result) {
                return function () {
                  count--;
                  if (count === 0) {
                    cb(result)();
                  }
                };
              });
            }
            // Terminal case.
            if (head === null) {
              break loop;
            }
            // Go down the right side of the tree.
            step = head._2;
            if (tail === null) {
              head = null;
            } else {
              head = tail._1;
              tail = tail._2;
            }
            break;
          case MAP:
            step = step._2;
            break;
          case APPLY:
          case ALT:
            if (head) {
              tail = new Aff(CONS, head, tail);
            }
            head = step;
            step = step._1;
            break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;
          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      }

      // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.
      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null;

          // We should never continue if the entire tree has been interrupted.
          if (interrupt !== null) {
            return;
          }

          // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.
          if (head === null) {
            cb(fail || step)();
            return;
          }

          // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?
          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
          case MAP:
            if (fail === null) {
              head._3 = util.right(head._1(util.fromRight(step)));
              step    = head._3;
            } else {
              head._3 = fail;
            }
            break;
          case APPLY:
            lhs = head._1._3;
            rhs = head._2._3;
            // If we have a failure we should kill the other side because we
            // can't possible yield a result anymore.
            if (fail) {
              head._3 = fail;
              tmp     = true;
              kid     = killId++;

              kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(fail, null, null);
                  } else {
                    join(fail, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            } else if (lhs === EMPTY || rhs === EMPTY) {
              // We can only proceed if both sides have resolved.
              return;
            } else {
              step    = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
              head._3 = step;
            }
            break;
          case ALT:
            lhs = head._1._3;
            rhs = head._2._3;
            // We can only proceed if both have resolved or we have a success
            if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
              return;
            }
            // If both sides resolve with an error, we should continue with the
            // first error
            if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
              fail    = step === lhs ? rhs : lhs;
              step    = null;
              head._3 = fail;
            } else {
              head._3 = step;
              tmp     = true;
              kid     = killId++;
              // Once a side has resolved, we need to cancel the side that is still
              // pending before we can continue.
              kills[kid] = kill(early, step === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(step, null, null);
                  } else {
                    join(step, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            }
            break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      }

      // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.
      function run() {
        var status = CONTINUE;
        var step   = par;
        var head   = null;
        var tail   = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
          case CONTINUE:
            switch (step.tag) {
            case MAP:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(MAP, step._1, EMPTY, EMPTY);
              step = step._2;
              break;
            case APPLY:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(APPLY, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(ALT, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            default:
              // When we hit a leaf value, we suspend the stack in the `FORKED`.
              // When the fiber resolves, it can bubble back up the tree.
              fid    = fiberId++;
              status = RETURN;
              tmp    = step;
              step   = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
              tmp    = Fiber(util, supervisor, tmp);
              tmp.onComplete({
                rethrow: false,
                handler: resolve(step)
              })();
              fibers[fid] = tmp;
              if (supervisor) {
                supervisor.register(tmp);
              }
            }
            break;
          case RETURN:
            // Terminal case, we are back at the root.
            if (head === null) {
              break loop;
            }
            // If we are done with the right side, we need to continue down the
            // left. Otherwise we should continue up the stack.
            if (head._1 === EMPTY) {
              head._1 = step;
              status  = CONTINUE;
              step    = head._2;
              head._2 = EMPTY;
            } else {
              head._2 = step;
              step    = head;
              if (tail === null) {
                head  = null;
              } else {
                head  = tail._1;
                tail  = tail._2;
              }
            }
          }
        }

        // Keep a reference to the tree root so it can be cancelled.
        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      }

      // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.
      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;
        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];
            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);

        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }
              return nonCanceler;
            };
          });
        };
      }

      run();

      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY       = EMPTY;
    Aff.Pure        = AffCtr(PURE);
    Aff.Throw       = AffCtr(THROW);
    Aff.Catch       = AffCtr(CATCH);
    Aff.Sync        = AffCtr(SYNC);
    Aff.Async       = AffCtr(ASYNC);
    Aff.Bind        = AffCtr(BIND);
    Aff.Bracket     = AffCtr(BRACKET);
    Aff.Fork        = AffCtr(FORK);
    Aff.Seq         = AffCtr(SEQ);
    Aff.ParMap      = AffCtr(MAP);
    Aff.ParApply    = AffCtr(APPLY);
    Aff.ParAlt      = AffCtr(ALT);
    Aff.Fiber       = Fiber;
    Aff.Supervisor  = Supervisor;
    Aff.Scheduler   = Scheduler;
    Aff.nonCanceler = nonCanceler;

    return Aff;
  }();

  exports._pure = Aff.Pure;

  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._fork = function (immediate) {
    return function (aff) {
      return Aff.Fork(immediate, aff);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports.generalBracket = function (acquire) {
    return function (options) {
      return function (k) {
        return Aff.Bracket(acquire, options, k);
      };
    };
  };

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };

  exports._delay = function () {
    function setDelay(n, k) {
      if (n === 0 && typeof setImmediate !== "undefined") {
        return setImmediate(k);
      } else {
        return setTimeout(k, n);
      }
    }

    function clearDelay(n, t) {
      if (n === 0 && typeof clearImmediate !== "undefined") {
        return clearImmediate(t);
      } else {
        return clearTimeout(t);
      }
    }

    return function (right, ms) {
      return Aff.Async(function (cb) {
        return function () {
          var timer = setDelay(ms, cb(right()));
          return function () {
            return Aff.Sync(function () {
              return right(clearDelay(ms, timer));
            });
          };
        };
      });
    };
  }();

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});
(function(exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];                    
  var monadEffect = new Control_Monad.Monad(function () {
      return applicativeEffect;
  }, function () {
      return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
      return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
      return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
      return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.new = function (val) {
    return function () {
      return { value: val };
    };
  };

  exports.read = function (ref) {
    return function () {
      return ref.value;
    };
  };

  exports["modify'"] = function (f) {
    return function (ref) {
      return function () {
        var t = f(ref.value);
        ref.value = t.state;
        return t.value;
      };
    };
  };

  exports.write = function (val) {
    return function (ref) {
      return function () {
        ref.value = val;
        return {};
      };
    };
  };
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Ref"] = $PS["Effect.Ref"] || {};
  var exports = $PS["Effect.Ref"];
  var $foreign = $PS["Effect.Ref"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];                
  var modify = function (f) {
      return $foreign["modify'"](function (s) {
          var s$prime = f(s);
          return {
              state: s$prime,
              value: s$prime
          };
      });
  };
  var modify_ = function (f) {
      return function (s) {
          return Data_Functor["void"](Effect.functorEffect)(modify(f)(s));
      };
  };
  exports["modify_"] = modify_;
  exports["new"] = $foreign["new"];
  exports["read"] = $foreign.read;
  exports["modify'"] = $foreign["modify'"];
  exports["write"] = $foreign.write;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Rec.Class"] = $PS["Control.Monad.Rec.Class"] || {};
  var exports = $PS["Control.Monad.Rec.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];                
  var Loop = (function () {
      function Loop(value0) {
          this.value0 = value0;
      };
      Loop.create = function (value0) {
          return new Loop(value0);
      };
      return Loop;
  })();
  var Done = (function () {
      function Done(value0) {
          this.value0 = value0;
      };
      Done.create = function (value0) {
          return new Done(value0);
      };
      return Done;
  })();
  var MonadRec = function (Monad0, tailRecM) {
      this.Monad0 = Monad0;
      this.tailRecM = tailRecM;
  };
  var tailRecM = function (dict) {
      return dict.tailRecM;
  }; 
  var monadRecEffect = new MonadRec(function () {
      return Effect.monadEffect;
  }, function (f) {
      return function (a) {
          var fromDone = function (v) {
              if (v instanceof Done) {
                  return v.value0;
              };
              throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 113, column 30 - line 113, column 44): " + [ v.constructor.name ]);
          };
          return function __do() {
              var r = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(f(a))();
              (function () {
                  while (!(function __do() {
                      var v = Effect_Ref.read(r)();
                      if (v instanceof Loop) {
                          var e = f(v.value0)();
                          Effect_Ref.write(e)(r)();
                          return false;
                      };
                      if (v instanceof Done) {
                          return true;
                      };
                      throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 104, column 22 - line 109, column 28): " + [ v.constructor.name ]);
                  })()) {

                  };
                  return {};
              })();
              return Data_Functor.map(Effect.functorEffect)(fromDone)(Effect_Ref.read(r))();
          };
      };
  });
  exports["Loop"] = Loop;
  exports["Done"] = Done;
  exports["MonadRec"] = MonadRec;
  exports["tailRecM"] = tailRecM;
  exports["monadRecEffect"] = monadRecEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Parallel.Class"] = $PS["Control.Parallel.Class"] || {};
  var exports = $PS["Control.Parallel.Class"];
  var Parallel = function (Applicative1, Monad0, parallel, sequential) {
      this.Applicative1 = Applicative1;
      this.Monad0 = Monad0;
      this.parallel = parallel;
      this.sequential = sequential;
  };
  var sequential = function (dict) {
      return dict.sequential;
  };
  var parallel = function (dict) {
      return dict.parallel;
  };
  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
  exports["Parallel"] = Parallel;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect = $PS["Effect"];                
  var MonadEffect = function (Monad0, liftEffect) {
      this.Monad0 = Monad0;
      this.liftEffect = liftEffect;
  };
  var monadEffectEffect = new MonadEffect(function () {
      return Effect.monadEffect;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftEffect = function (dict) {
      return dict.liftEffect;
  };
  exports["liftEffect"] = liftEffect;
  exports["MonadEffect"] = MonadEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);
(function(exports) {
  "use strict";

  // module Partial.Unsafe

  exports.unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
  "use strict";

  // module Partial

  exports.crashWith = function () {
    return function (msg) {
      throw new Error(msg);
    };
  };
})(PS["Partial"] = PS["Partial"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Partial"] = $PS["Partial"] || {};
  var exports = $PS["Partial"];
  var $foreign = $PS["Partial"];
  exports["crashWith"] = $foreign.crashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Partial.Unsafe"] = $PS["Partial.Unsafe"] || {};
  var exports = $PS["Partial.Unsafe"];
  var $foreign = $PS["Partial.Unsafe"];
  var Partial = $PS["Partial"];
  var unsafeCrashWith = function (msg) {
      return $foreign.unsafePartial(function (dictPartial) {
          return Partial.crashWith()(msg);
      });
  };
  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS);
(function(exports) {
  "use strict";

  // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Aff"] = $PS["Effect.Aff"] || {};
  var exports = $PS["Effect.Aff"];
  var $foreign = $PS["Effect.Aff"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Canceler = function (x) {
      return x;
  };
  var suspendAff = $foreign["_fork"](false);
  var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
  var functorAff = new Data_Functor.Functor($foreign["_map"]);
  var forkAff = $foreign["_fork"](true);
  var ffiUtil = (function () {
      var unsafeFromRight = function (v) {
          if (v instanceof Data_Either.Right) {
              return v.value0;
          };
          if (v instanceof Data_Either.Left) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 400, column 21 - line 402, column 54): " + [ v.constructor.name ]);
      };
      var unsafeFromLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return v.value0;
          };
          if (v instanceof Data_Either.Right) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 395, column 20 - line 397, column 54): " + [ v.constructor.name ]);
      };
      var isLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return true;
          };
          if (v instanceof Data_Either.Right) {
              return false;
          };
          throw new Error("Failed pattern match at Effect.Aff (line 390, column 12 - line 392, column 20): " + [ v.constructor.name ]);
      };
      return {
          isLeft: isLeft,
          fromLeft: unsafeFromLeft,
          fromRight: unsafeFromRight,
          left: Data_Either.Left.create,
          right: Data_Either.Right.create
      };
  })();
  var makeFiber = function (aff) {
      return $foreign["_makeFiber"](ffiUtil, aff);
  };
  var launchAff = function (aff) {
      return function __do() {
          var fiber = makeFiber(aff)();
          fiber.run();
          return fiber;
      };
  };
  var launchAff_ = (function () {
      var $43 = Data_Functor["void"](Effect.functorEffect);
      return function ($44) {
          return $43(launchAff($44));
      };
  })();                              
  var delay = function (v) {
      return $foreign["_delay"](Data_Either.Right.create, v);
  };
  var bracket = function (acquire) {
      return function (completed) {
          return $foreign.generalBracket(acquire)({
              killed: Data_Function["const"](completed),
              failed: Data_Function["const"](completed),
              completed: Data_Function["const"](completed)
          });
      };
  };
  var applyParAff = new Control_Apply.Apply(function () {
      return functorParAff;
  }, $foreign["_parAffApply"]);
  var monadAff = new Control_Monad.Monad(function () {
      return applicativeAff;
  }, function () {
      return bindAff;
  });
  var bindAff = new Control_Bind.Bind(function () {
      return applyAff;
  }, $foreign["_bind"]);
  var applyAff = new Control_Apply.Apply(function () {
      return functorAff;
  }, Control_Monad.ap(monadAff));
  var applicativeAff = new Control_Applicative.Applicative(function () {
      return applyAff;
  }, $foreign["_pure"]);
  var $$finally = function (fin) {
      return function (a) {
          return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
      };
  };
  var monadEffectAff = new Effect_Class.MonadEffect(function () {
      return monadAff;
  }, $foreign["_liftEffect"]);
  var effectCanceler = (function () {
      var $45 = Effect_Class.liftEffect(monadEffectAff);
      return function ($46) {
          return Canceler(Data_Function["const"]($45($46)));
      };
  })();
  var joinFiber = function (v) {
      return $foreign.makeAff(function (k) {
          return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
      });
  };
  var functorFiber = new Data_Functor.Functor(function (f) {
      return function (t) {
          return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
      };
  });
  var killFiber = function (e) {
      return function (v) {
          return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
              if (v1) {
                  return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
              };
              return $foreign.makeAff(function (k) {
                  return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
              });
          });
      };
  };   
  var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
      return monadAff;
  }, $foreign["_throwError"]);
  var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
      return monadThrowAff;
  }, $foreign["_catchError"]);                                  
  var runAff = function (k) {
      return function (aff) {
          return launchAff(Control_Bind.bindFlipped(bindAff)((function () {
              var $49 = Effect_Class.liftEffect(monadEffectAff);
              return function ($50) {
                  return $49(k($50));
              };
          })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
      };
  };
  var runAff_ = function (k) {
      return function (aff) {
          return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
      };
  };
  var parallelAff = new Control_Parallel_Class.Parallel(function () {
      return applicativeParAff;
  }, function () {
      return monadAff;
  }, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
  var applicativeParAff = new Control_Applicative.Applicative(function () {
      return applyParAff;
  }, (function () {
      var $53 = Control_Parallel_Class.parallel(parallelAff);
      var $54 = Control_Applicative.pure(applicativeAff);
      return function ($55) {
          return $53($54($55));
      };
  })());
  var monadRecAff = new Control_Monad_Rec_Class.MonadRec(function () {
      return monadAff;
  }, function (k) {
      var go = function (a) {
          return Control_Bind.bind(bindAff)(k(a))(function (res) {
              if (res instanceof Control_Monad_Rec_Class.Done) {
                  return Control_Applicative.pure(applicativeAff)(res.value0);
              };
              if (res instanceof Control_Monad_Rec_Class.Loop) {
                  return go(res.value0);
              };
              throw new Error("Failed pattern match at Effect.Aff (line 100, column 7 - line 102, column 22): " + [ res.constructor.name ]);
          });
      };
      return go;
  });
  var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
  exports["launchAff_"] = launchAff_;
  exports["runAff_"] = runAff_;
  exports["forkAff"] = forkAff;
  exports["suspendAff"] = suspendAff;
  exports["delay"] = delay;
  exports["finally"] = $$finally;
  exports["killFiber"] = killFiber;
  exports["joinFiber"] = joinFiber;
  exports["nonCanceler"] = nonCanceler;
  exports["effectCanceler"] = effectCanceler;
  exports["functorAff"] = functorAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["monadRecAff"] = monadRecAff;
  exports["monadErrorAff"] = monadErrorAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["functorFiber"] = functorFiber;
  exports["makeAff"] = $foreign.makeAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Aff.Compat"] = $PS["Effect.Aff.Compat"] || {};
  var exports = $PS["Effect.Aff.Compat"];
  var Data_Either = $PS["Data.Either"];
  var Effect_Aff = $PS["Effect.Aff"];
  var fromEffectFnAff = function (v) {
      return Effect_Aff.makeAff(function (k) {
          return function __do() {
              var v1 = v(function ($4) {
                  return k(Data_Either.Left.create($4))();
              }, function ($5) {
                  return k(Data_Either.Right.create($5))();
              });
              return function (e) {
                  return Effect_Aff.makeAff(function (k2) {
                      return function __do() {
                          v1(e, function ($6) {
                              return k2(Data_Either.Left.create($6))();
                          }, function ($7) {
                              return k2(Data_Either.Right.create($7))();
                          });
                          return Effect_Aff.nonCanceler;
                      };
                  });
              };
          };
      });
  };
  exports["fromEffectFnAff"] = fromEffectFnAff;
})(PS);
(function(exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.message = function (e) {
    return e.message;
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];
  var $$throw = function ($2) {
      return $foreign.throwException($foreign.error($2));
  };
  exports["throw"] = $$throw;
  exports["error"] = $foreign.error;
  exports["message"] = $foreign.message;
  exports["throwException"] = $foreign.throwException;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeToForeign = function (value) {
    return value;
  };

  exports.unsafeFromForeign = function (value) {
    return value;
  };

  exports.typeOf = function (value) {
    return typeof value;
  };

  exports.tagOf = function (value) {
    return Object.prototype.toString.call(value).slice(8, -1);
  };
})(PS["Foreign"] = PS["Foreign"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Show = $PS["Data.Show"];                                        
  var ForeignError = (function () {
      function ForeignError(value0) {
          this.value0 = value0;
      };
      ForeignError.create = function (value0) {
          return new ForeignError(value0);
      };
      return ForeignError;
  })();
  var TypeMismatch = (function () {
      function TypeMismatch(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      TypeMismatch.create = function (value0) {
          return function (value1) {
              return new TypeMismatch(value0, value1);
          };
      };
      return TypeMismatch;
  })();
  var ErrorAtIndex = (function () {
      function ErrorAtIndex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtIndex.create = function (value0) {
          return function (value1) {
              return new ErrorAtIndex(value0, value1);
          };
      };
      return ErrorAtIndex;
  })();
  var ErrorAtProperty = (function () {
      function ErrorAtProperty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtProperty.create = function (value0) {
          return function (value1) {
              return new ErrorAtProperty(value0, value1);
          };
      };
      return ErrorAtProperty;
  })();
  var renderForeignError = function (v) {
      if (v instanceof ForeignError) {
          return v.value0;
      };
      if (v instanceof ErrorAtIndex) {
          return "Error at array index " + (Data_Show.show(Data_Show.showInt)(v.value0) + (": " + renderForeignError(v.value1)));
      };
      if (v instanceof ErrorAtProperty) {
          return "Error at property " + (Data_Show.show(Data_Show.showString)(v.value0) + (": " + renderForeignError(v.value1)));
      };
      if (v instanceof TypeMismatch) {
          return "Type mismatch: expected " + (v.value0 + (", found " + v.value1));
      };
      throw new Error("Failed pattern match at Foreign (line 72, column 1 - line 72, column 45): " + [ v.constructor.name ]);
  };
  var fail = (function () {
      var $107 = Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity));
      return function ($108) {
          return $107(Data_List_NonEmpty.singleton($108));
      };
  })();
  var unsafeReadTagged = function (tag) {
      return function (value) {
          if ($foreign.tagOf(value) === tag) {
              return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
          };
          if (Data_Boolean.otherwise) {
              return fail(new TypeMismatch(tag, $foreign.tagOf(value)));
          };
          throw new Error("Failed pattern match at Foreign (line 106, column 1 - line 106, column 55): " + [ tag.constructor.name, value.constructor.name ]);
      };
  };
  var readString = unsafeReadTagged("String");
  exports["ForeignError"] = ForeignError;
  exports["TypeMismatch"] = TypeMismatch;
  exports["renderForeignError"] = renderForeignError;
  exports["unsafeReadTagged"] = unsafeReadTagged;
  exports["readString"] = readString;
  exports["fail"] = fail;
  exports["unsafeToForeign"] = $foreign.unsafeToForeign;
  exports["typeOf"] = $foreign.typeOf;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Affjax"] = $PS["Affjax"] || {};
  var exports = $PS["Affjax"];
  var $foreign = $PS["Affjax"];
  var Affjax_RequestBody = $PS["Affjax.RequestBody"];
  var Affjax_RequestHeader = $PS["Affjax.RequestHeader"];
  var Affjax_ResponseFormat = $PS["Affjax.ResponseFormat"];
  var Affjax_ResponseHeader = $PS["Affjax.ResponseHeader"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Parser = $PS["Data.Argonaut.Parser"];
  var Data_Array = $PS["Data.Array"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_FormURLEncoded = $PS["Data.FormURLEncoded"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HTTP_Method = $PS["Data.HTTP.Method"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Compat = $PS["Effect.Aff.Compat"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Foreign = $PS["Foreign"];                
  var RequestContentError = (function () {
      function RequestContentError(value0) {
          this.value0 = value0;
      };
      RequestContentError.create = function (value0) {
          return new RequestContentError(value0);
      };
      return RequestContentError;
  })();
  var ResponseBodyError = (function () {
      function ResponseBodyError(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ResponseBodyError.create = function (value0) {
          return function (value1) {
              return new ResponseBodyError(value0, value1);
          };
      };
      return ResponseBodyError;
  })();
  var XHRError = (function () {
      function XHRError(value0) {
          this.value0 = value0;
      };
      XHRError.create = function (value0) {
          return new XHRError(value0);
      };
      return XHRError;
  })();
  var request = function (req) {
      var parseJSON = function (v) {
          if (v === "") {
              return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Argonaut_Core.jsonEmptyObject);
          };
          return Data_Either.either(function ($47) {
              return Foreign.fail(Foreign.ForeignError.create($47));
          })(Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)))(Data_Argonaut_Parser.jsonParser(v));
      };
      var fromResponse = (function () {
          if (req.responseFormat instanceof Affjax_ResponseFormat["ArrayBuffer"]) {
              return Foreign.unsafeReadTagged("ArrayBuffer");
          };
          if (req.responseFormat instanceof Affjax_ResponseFormat.Blob) {
              return Foreign.unsafeReadTagged("Blob");
          };
          if (req.responseFormat instanceof Affjax_ResponseFormat.Document) {
              return Foreign.unsafeReadTagged("Document");
          };
          if (req.responseFormat instanceof Affjax_ResponseFormat.Json) {
              return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(function ($48) {
                  return req.responseFormat.value0(parseJSON($48));
              })(Foreign.unsafeReadTagged("String"));
          };
          if (req.responseFormat instanceof Affjax_ResponseFormat["String"]) {
              return Foreign.unsafeReadTagged("String");
          };
          if (req.responseFormat instanceof Affjax_ResponseFormat.Ignore) {
              return Data_Function["const"](req.responseFormat.value0(Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Unit.unit)));
          };
          throw new Error("Failed pattern match at Affjax (line 237, column 18 - line 243, column 57): " + [ req.responseFormat.constructor.name ]);
      })();
      var extractContent = function (v) {
          if (v instanceof Affjax_RequestBody.ArrayView) {
              return Data_Either.Right.create(v.value0(Foreign.unsafeToForeign));
          };
          if (v instanceof Affjax_RequestBody.Blob) {
              return Data_Either.Right.create(Foreign.unsafeToForeign(v.value0));
          };
          if (v instanceof Affjax_RequestBody.Document) {
              return Data_Either.Right.create(Foreign.unsafeToForeign(v.value0));
          };
          if (v instanceof Affjax_RequestBody["String"]) {
              return Data_Either.Right.create(Foreign.unsafeToForeign(v.value0));
          };
          if (v instanceof Affjax_RequestBody.FormData) {
              return Data_Either.Right.create(Foreign.unsafeToForeign(v.value0));
          };
          if (v instanceof Affjax_RequestBody.FormURLEncoded) {
              return Data_Either.note("Body contains values that cannot be encoded as application/x-www-form-urlencoded")(Data_Functor.map(Data_Maybe.functorMaybe)(Foreign.unsafeToForeign)(Data_FormURLEncoded.encode(v.value0)));
          };
          if (v instanceof Affjax_RequestBody.Json) {
              return Data_Either.Right.create(Foreign.unsafeToForeign(Data_Argonaut_Core.stringify(v.value0)));
          };
          throw new Error("Failed pattern match at Affjax (line 203, column 20 - line 218, column 69): " + [ v.constructor.name ]);
      };
      var addHeader = function (mh) {
          return function (hs) {
              if (mh instanceof Data_Maybe.Just && !Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(Affjax_RequestHeader.name)(mh.value0))(hs)) {
                  return Data_Array.snoc(hs)(mh.value0);
              };
              return hs;
          };
      };
      var headers = function (reqContent) {
          return addHeader(Data_Functor.map(Data_Maybe.functorMaybe)(Affjax_RequestHeader.ContentType.create)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Affjax_RequestBody.toMediaType)(reqContent)))(addHeader(Data_Functor.map(Data_Maybe.functorMaybe)(Affjax_RequestHeader.Accept.create)(Affjax_ResponseFormat.toMediaType(req.responseFormat)))(req.headers));
      };
      var ajaxRequest = function (v) {
          return {
              method: Data_HTTP_Method.print(req.method),
              url: req.url,
              headers: Data_Functor.map(Data_Functor.functorArray)(function (h) {
                  return {
                      field: Affjax_RequestHeader.name(h),
                      value: Affjax_RequestHeader.value(h)
                  };
              })(headers(req.content)),
              content: v,
              responseType: Affjax_ResponseFormat.toResponseType(req.responseFormat),
              username: Data_Nullable.toNullable(req.username),
              password: Data_Nullable.toNullable(req.password),
              withCredentials: req.withCredentials
          };
      };
      var send = function (content) {
          return Data_Functor.mapFlipped(Effect_Aff.functorAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Effect_Aff_Compat.fromEffectFnAff($foreign["_ajax"](Affjax_ResponseHeader.ResponseHeader.create, ajaxRequest(content)))))(function (v) {
              if (v instanceof Data_Either.Right) {
                  var v1 = Control_Monad_Except.runExcept(fromResponse(v.value0.body));
                  if (v1 instanceof Data_Either.Left) {
                      return new Data_Either.Left(new ResponseBodyError(Data_List_NonEmpty.head(v1.value0), v.value0));
                  };
                  if (v1 instanceof Data_Either.Right) {
                      return new Data_Either.Right({
                          body: v1.value0,
                          headers: v.value0.headers,
                          status: v.value0.status,
                          statusText: v.value0.statusText
                      });
                  };
                  throw new Error("Failed pattern match at Affjax (line 184, column 9 - line 186, column 52): " + [ v1.constructor.name ]);
              };
              if (v instanceof Data_Either.Left) {
                  return new Data_Either.Left(new XHRError(v.value0));
              };
              throw new Error("Failed pattern match at Affjax (line 182, column 86 - line 188, column 28): " + [ v.constructor.name ]);
          });
      };
      if (req.content instanceof Data_Maybe.Nothing) {
          return send(Data_Nullable.toNullable(Data_Maybe.Nothing.value));
      };
      if (req.content instanceof Data_Maybe.Just) {
          var v = extractContent(req.content.value0);
          if (v instanceof Data_Either.Right) {
              return send(Data_Nullable.toNullable(new Data_Maybe.Just(v.value0)));
          };
          if (v instanceof Data_Either.Left) {
              return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(new RequestContentError(v.value0)));
          };
          throw new Error("Failed pattern match at Affjax (line 173, column 7 - line 177, column 48): " + [ v.constructor.name ]);
      };
      throw new Error("Failed pattern match at Affjax (line 169, column 3 - line 177, column 48): " + [ req.content.constructor.name ]);
  };
  var printError = function (v) {
      if (v instanceof RequestContentError) {
          return "There was a problem with the request content: " + v.value0;
      };
      if (v instanceof ResponseBodyError) {
          return "There was a problem with the response body: " + Foreign.renderForeignError(v.value0);
      };
      if (v instanceof XHRError) {
          return "There was a problem making the request: " + Effect_Exception.message(v.value0);
      };
      throw new Error("Failed pattern match at Affjax (line 91, column 14 - line 97, column 66): " + [ v.constructor.name ]);
  };
  var defaultRequest = {
      method: new Data_Either.Left(Data_HTTP_Method.GET.value),
      url: "/",
      headers: [  ],
      content: Data_Maybe.Nothing.value,
      username: Data_Maybe.Nothing.value,
      password: Data_Maybe.Nothing.value,
      withCredentials: false,
      responseFormat: Affjax_ResponseFormat.ignore
  };   
  var get = function (rf) {
      return function (u) {
          return request({
              method: defaultRequest.method,
              url: u,
              headers: defaultRequest.headers,
              content: defaultRequest.content,
              username: defaultRequest.username,
              password: defaultRequest.password,
              withCredentials: defaultRequest.withCredentials,
              responseFormat: rf
          });
      };
  };
  exports["ResponseBodyError"] = ResponseBodyError;
  exports["printError"] = printError;
  exports["get"] = get;
})(PS);
(function(exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };

  exports.fromStringAsImpl = function (just) {
    return function (nothing) {
      return function (radix) {
        var digits;
        if (radix < 11) {
          digits = "[0-" + (radix - 1).toString() + "]";
        } else if (radix === 11) {
          digits = "[0-9a]";
        } else {
          digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
        }
        var pattern = new RegExp("^[\\+\\-]?" + digits + "+$", "i");

        return function (s) {
          /* jshint bitwise: false */
          if (pattern.test(s)) {
            var i = parseInt(s, radix);
            return (i | 0) === i ? just(i) : nothing;
          } else {
            return nothing;
          }
        };
      };
    };
  };

  exports.toStringAs = function (radix) {
    return function (i) {
      return i.toString(radix);
    };
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var fromString = fromStringAs(10);
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var decimal = 10;
  exports["fromNumber"] = fromNumber;
  exports["fromString"] = fromString;
  exports["decimal"] = decimal;
  exports["toNumber"] = $foreign.toNumber;
  exports["toStringAs"] = $foreign.toStringAs;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];      
  var SProxy = (function () {
      function SProxy() {

      };
      SProxy.value = new SProxy();
      return SProxy;
  })();
  var IsSymbol = function (reflectSymbol) {
      this.reflectSymbol = reflectSymbol;
  };
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS);
(function(exports) {
  "use strict";

  exports.mapWithIndexArray = function (f) {
    return function (xs) {
      var l = xs.length;
      var result = Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(i)(xs[i]);
      }
      return result;
    };
  };
})(PS["Data.FunctorWithIndex"] = PS["Data.FunctorWithIndex"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.FunctorWithIndex"] = $PS["Data.FunctorWithIndex"] || {};
  var exports = $PS["Data.FunctorWithIndex"];
  var $foreign = $PS["Data.FunctorWithIndex"];
  var Data_Functor = $PS["Data.Functor"];          
  var FunctorWithIndex = function (Functor0, mapWithIndex) {
      this.Functor0 = Functor0;
      this.mapWithIndex = mapWithIndex;
  };
  var mapWithIndex = function (dict) {
      return dict.mapWithIndex;
  }; 
  var functorWithIndexArray = new FunctorWithIndex(function () {
      return Data_Functor.functorArray;
  }, $foreign.mapWithIndexArray);
  exports["mapWithIndex"] = mapWithIndex;
  exports["functorWithIndexArray"] = functorWithIndexArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.FoldableWithIndex"] = $PS["Data.FoldableWithIndex"] || {};
  var exports = $PS["Data.FoldableWithIndex"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_FunctorWithIndex = $PS["Data.FunctorWithIndex"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var FoldableWithIndex = function (Foldable0, foldMapWithIndex, foldlWithIndex, foldrWithIndex) {
      this.Foldable0 = Foldable0;
      this.foldMapWithIndex = foldMapWithIndex;
      this.foldlWithIndex = foldlWithIndex;
      this.foldrWithIndex = foldrWithIndex;
  };
  var foldrWithIndex = function (dict) {
      return dict.foldrWithIndex;
  };
  var foldMapWithIndexDefaultR = function (dictFoldableWithIndex) {
      return function (dictMonoid) {
          return function (f) {
              return foldrWithIndex(dictFoldableWithIndex)(function (i) {
                  return function (x) {
                      return function (acc) {
                          return Data_Semigroup.append(dictMonoid.Semigroup0())(f(i)(x))(acc);
                      };
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableWithIndexArray = new FoldableWithIndex(function () {
      return Data_Foldable.foldableArray;
  }, function (dictMonoid) {
      return foldMapWithIndexDefaultR(foldableWithIndexArray)(dictMonoid);
  }, function (f) {
      return function (z) {
          var $49 = Data_Foldable.foldl(Data_Foldable.foldableArray)(function (y) {
              return function (v) {
                  return f(v.value0)(y)(v.value1);
              };
          })(z);
          var $50 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Tuple.create);
          return function ($51) {
              return $49($50($51));
          };
      };
  }, function (f) {
      return function (z) {
          var $52 = Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
              return function (y) {
                  return f(v.value0)(v.value1)(y);
              };
          })(z);
          var $53 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Tuple.create);
          return function ($54) {
              return $52($53($54));
          };
      };
  });
  exports["foldableWithIndexArray"] = foldableWithIndexArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.TraversableWithIndex"] = $PS["Data.TraversableWithIndex"] || {};
  var exports = $PS["Data.TraversableWithIndex"];
  var Data_FoldableWithIndex = $PS["Data.FoldableWithIndex"];
  var Data_FunctorWithIndex = $PS["Data.FunctorWithIndex"];
  var Data_Traversable = $PS["Data.Traversable"];  
  var TraversableWithIndex = function (FoldableWithIndex1, FunctorWithIndex0, Traversable2, traverseWithIndex) {
      this.FoldableWithIndex1 = FoldableWithIndex1;
      this.FunctorWithIndex0 = FunctorWithIndex0;
      this.Traversable2 = Traversable2;
      this.traverseWithIndex = traverseWithIndex;
  };
  var traverseWithIndexDefault = function (dictTraversableWithIndex) {
      return function (dictApplicative) {
          return function (f) {
              var $19 = Data_Traversable.sequence(dictTraversableWithIndex.Traversable2())(dictApplicative);
              var $20 = Data_FunctorWithIndex.mapWithIndex(dictTraversableWithIndex.FunctorWithIndex0())(f);
              return function ($21) {
                  return $19($20($21));
              };
          };
      };
  };
  var traverseWithIndex = function (dict) {
      return dict.traverseWithIndex;
  }; 
  var traversableWithIndexArray = new TraversableWithIndex(function () {
      return Data_FoldableWithIndex.foldableWithIndexArray;
  }, function () {
      return Data_FunctorWithIndex.functorWithIndexArray;
  }, function () {
      return Data_Traversable.traversableArray;
  }, function (dictApplicative) {
      return traverseWithIndexDefault(traversableWithIndexArray)(dictApplicative);
  });
  exports["traverseWithIndex"] = traverseWithIndex;
  exports["traversableWithIndexArray"] = traversableWithIndexArray;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };

  exports.unsafeSet = function (label) {
    return function (value) {
      return function (rec) {
        var copy = {};
        for (var key in rec) {
          if ({}.hasOwnProperty.call(rec, key)) {
            copy[key] = rec[key];
          }
        }
        copy[label] = value;
        return copy;
      };
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Record.Unsafe"] = $PS["Record.Unsafe"] || {};
  var exports = $PS["Record.Unsafe"];
  var $foreign = $PS["Record.Unsafe"];
  exports["unsafeGet"] = $foreign.unsafeGet;
  exports["unsafeSet"] = $foreign.unsafeSet;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Record"] = $PS["Record"] || {};
  var exports = $PS["Record"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var insert = function (dictIsSymbol) {
      return function (dictLacks) {
          return function (dictCons) {
              return function (l) {
                  return function (a) {
                      return function (r) {
                          return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
                      };
                  };
              };
          };
      };
  };
  var get = function (dictIsSymbol) {
      return function (dictCons) {
          return function (l) {
              return function (r) {
                  return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
              };
          };
      };
  };
  exports["get"] = get;
  exports["insert"] = insert;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Type.Data.RowList"] = $PS["Type.Data.RowList"] || {};
  var exports = $PS["Type.Data.RowList"];
  var RLProxy = (function () {
      function RLProxy() {

      };
      RLProxy.value = new RLProxy();
      return RLProxy;
  })();
  exports["RLProxy"] = RLProxy;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Argonaut.Decode.Class"] = $PS["Data.Argonaut.Decode.Class"] || {};
  var exports = $PS["Data.Argonaut.Decode.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_TraversableWithIndex = $PS["Data.TraversableWithIndex"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Data_RowList = $PS["Type.Data.RowList"];                
  var GDecodeJson = function (gDecodeJson) {
      this.gDecodeJson = gDecodeJson;
  };
  var DecodeJson = function (decodeJson) {
      this.decodeJson = decodeJson;
  };
  var gDecodeJsonNil = new GDecodeJson(function (v) {
      return function (v1) {
          return new Data_Either.Right({});
      };
  });
  var gDecodeJson = function (dict) {
      return dict.gDecodeJson;
  };
  var elaborateFailure = function (s) {
      return function (e) {
          var msg = function (m) {
              return "Failed to decode key '" + (s + ("': " + m));
          };
          return Data_Bifunctor.lmap(Data_Either.bifunctorEither)(msg)(e);
      };
  }; 
  var decodeRecord = function (dictGDecodeJson) {
      return function (dictRowToList) {
          return new DecodeJson(function (json) {
              var v = Data_Argonaut_Core.toObject(json);
              if (v instanceof Data_Maybe.Just) {
                  return gDecodeJson(dictGDecodeJson)(v.value0)(Type_Data_RowList.RLProxy.value);
              };
              if (v instanceof Data_Maybe.Nothing) {
                  return new Data_Either.Left("Could not convert JSON to object");
              };
              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 145, column 5 - line 147, column 61): " + [ v.constructor.name ]);
          });
      };
  };
  var decodeJsonString = new DecodeJson(Data_Argonaut_Core.caseJsonString(new Data_Either.Left("Value is not a String"))(Data_Either.Right.create));
  var decodeJsonNumber = new DecodeJson(Data_Argonaut_Core.caseJsonNumber(new Data_Either.Left("Value is not a Number"))(Data_Either.Right.create));   
  var decodeJson = function (dict) {
      return dict.decodeJson;
  }; 
  var decodeJsonInt = new DecodeJson(Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
      var $61 = Data_Maybe.maybe(new Data_Either.Left("Value is not an integer"))(Data_Either.Right.create);
      return function ($62) {
          return $61(Data_Int.fromNumber($62));
      };
  })())(decodeJson(decodeJsonNumber)));
  var decodeJsonMaybe = function (dictDecodeJson) {
      return new DecodeJson(function (j) {
          if (Data_Argonaut_Core.isNull(j)) {
              return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
          };
          if (Data_Boolean.otherwise) {
              return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(decodeJson(dictDecodeJson)(j));
          };
          throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 38, column 1 - line 41, column 40): " + [ j.constructor.name ]);
      });
  };
  var gDecodeJsonCons = function (dictDecodeJson) {
      return function (dictGDecodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return function (dictLacks) {
                      return new GDecodeJson(function (object) {
                          return function (v) {
                              var fieldName = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                              var v1 = Foreign_Object.lookup(fieldName)(object);
                              if (v1 instanceof Data_Maybe.Just) {
                                  return Control_Bind.bind(Data_Either.bindEither)(elaborateFailure(fieldName)(decodeJson(dictDecodeJson)(v1.value0)))(function (val) {
                                      return Control_Bind.bind(Data_Either.bindEither)(gDecodeJson(dictGDecodeJson)(object)(Type_Data_RowList.RLProxy.value))(function (rest) {
                                          return Data_Either.Right.create(Record.insert(dictIsSymbol)()()(Data_Symbol.SProxy.value)(val)(rest));
                                      });
                                  });
                              };
                              if (v1 instanceof Data_Maybe.Nothing) {
                                  return Data_Either.Left.create("JSON was missing expected field: " + fieldName);
                              };
                              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 169, column 8 - line 178, column 64): " + [ v1.constructor.name ]);
                          };
                      });
                  };
              };
          };
      };
  };
  var decodeJArray = (function () {
      var $65 = Data_Maybe.maybe(new Data_Either.Left("Value is not an Array"))(Data_Either.Right.create);
      return function ($66) {
          return $65(Data_Argonaut_Core.toArray($66));
      };
  })();
  var decodeArray = function (dictDecodeJson) {
      return new DecodeJson((function () {
          var msg = function (i) {
              return function (m) {
                  return "Failed at index " + (Data_Show.show(Data_Show.showInt)(i) + ("): " + m));
              };
          };
          var f = function (i) {
              var $107 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(msg(i));
              var $108 = decodeJson(dictDecodeJson);
              return function ($109) {
                  return $107($108($109));
              };
          };
          var $110 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (v) {
              return "Couldn't decode Array (" + v;
          });
          var $111 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Data_Either.applicativeEither)(f))(decodeJArray);
          return function ($112) {
              return $110($111($112));
          };
      })());
  };
  exports["decodeJson"] = decodeJson;
  exports["decodeJsonMaybe"] = decodeJsonMaybe;
  exports["decodeJsonNumber"] = decodeJsonNumber;
  exports["decodeJsonInt"] = decodeJsonInt;
  exports["decodeJsonString"] = decodeJsonString;
  exports["decodeArray"] = decodeArray;
  exports["decodeRecord"] = decodeRecord;
  exports["gDecodeJsonNil"] = gDecodeJsonNil;
  exports["gDecodeJsonCons"] = gDecodeJsonCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["DOM.HTML.Indexed.InputType"] = $PS["DOM.HTML.Indexed.InputType"] || {};
  var exports = $PS["DOM.HTML.Indexed.InputType"];
  var InputButton = (function () {
      function InputButton() {

      };
      InputButton.value = new InputButton();
      return InputButton;
  })();
  var InputCheckbox = (function () {
      function InputCheckbox() {

      };
      InputCheckbox.value = new InputCheckbox();
      return InputCheckbox;
  })();
  var InputColor = (function () {
      function InputColor() {

      };
      InputColor.value = new InputColor();
      return InputColor;
  })();
  var InputDate = (function () {
      function InputDate() {

      };
      InputDate.value = new InputDate();
      return InputDate;
  })();
  var InputDatetimeLocal = (function () {
      function InputDatetimeLocal() {

      };
      InputDatetimeLocal.value = new InputDatetimeLocal();
      return InputDatetimeLocal;
  })();
  var InputEmail = (function () {
      function InputEmail() {

      };
      InputEmail.value = new InputEmail();
      return InputEmail;
  })();
  var InputFile = (function () {
      function InputFile() {

      };
      InputFile.value = new InputFile();
      return InputFile;
  })();
  var InputHidden = (function () {
      function InputHidden() {

      };
      InputHidden.value = new InputHidden();
      return InputHidden;
  })();
  var InputImage = (function () {
      function InputImage() {

      };
      InputImage.value = new InputImage();
      return InputImage;
  })();
  var InputMonth = (function () {
      function InputMonth() {

      };
      InputMonth.value = new InputMonth();
      return InputMonth;
  })();
  var InputNumber = (function () {
      function InputNumber() {

      };
      InputNumber.value = new InputNumber();
      return InputNumber;
  })();
  var InputPassword = (function () {
      function InputPassword() {

      };
      InputPassword.value = new InputPassword();
      return InputPassword;
  })();
  var InputRadio = (function () {
      function InputRadio() {

      };
      InputRadio.value = new InputRadio();
      return InputRadio;
  })();
  var InputRange = (function () {
      function InputRange() {

      };
      InputRange.value = new InputRange();
      return InputRange;
  })();
  var InputReset = (function () {
      function InputReset() {

      };
      InputReset.value = new InputReset();
      return InputReset;
  })();
  var InputSearch = (function () {
      function InputSearch() {

      };
      InputSearch.value = new InputSearch();
      return InputSearch;
  })();
  var InputSubmit = (function () {
      function InputSubmit() {

      };
      InputSubmit.value = new InputSubmit();
      return InputSubmit;
  })();
  var InputTel = (function () {
      function InputTel() {

      };
      InputTel.value = new InputTel();
      return InputTel;
  })();
  var InputText = (function () {
      function InputText() {

      };
      InputText.value = new InputText();
      return InputText;
  })();
  var InputTime = (function () {
      function InputTime() {

      };
      InputTime.value = new InputTime();
      return InputTime;
  })();
  var InputUrl = (function () {
      function InputUrl() {

      };
      InputUrl.value = new InputUrl();
      return InputUrl;
  })();
  var InputWeek = (function () {
      function InputWeek() {

      };
      InputWeek.value = new InputWeek();
      return InputWeek;
  })();
  var renderInputType = function (v) {
      if (v instanceof InputButton) {
          return "button";
      };
      if (v instanceof InputCheckbox) {
          return "checkbox";
      };
      if (v instanceof InputColor) {
          return "color";
      };
      if (v instanceof InputDate) {
          return "date";
      };
      if (v instanceof InputDatetimeLocal) {
          return "datetime-local";
      };
      if (v instanceof InputEmail) {
          return "email";
      };
      if (v instanceof InputFile) {
          return "file";
      };
      if (v instanceof InputHidden) {
          return "hidden";
      };
      if (v instanceof InputImage) {
          return "image";
      };
      if (v instanceof InputMonth) {
          return "month";
      };
      if (v instanceof InputNumber) {
          return "number";
      };
      if (v instanceof InputPassword) {
          return "password";
      };
      if (v instanceof InputRadio) {
          return "radio";
      };
      if (v instanceof InputRange) {
          return "range";
      };
      if (v instanceof InputReset) {
          return "reset";
      };
      if (v instanceof InputSearch) {
          return "search";
      };
      if (v instanceof InputSubmit) {
          return "submit";
      };
      if (v instanceof InputTel) {
          return "tel";
      };
      if (v instanceof InputText) {
          return "text";
      };
      if (v instanceof InputTime) {
          return "time";
      };
      if (v instanceof InputUrl) {
          return "url";
      };
      if (v instanceof InputWeek) {
          return "week";
      };
      throw new Error("Failed pattern match at DOM.HTML.Indexed.InputType (line 28, column 19 - line 50, column 22): " + [ v.constructor.name ]);
  };
  exports["InputSearch"] = InputSearch;
  exports["renderInputType"] = renderInputType;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["DOM.HTML.Indexed.OnOff"] = $PS["DOM.HTML.Indexed.OnOff"] || {};
  var exports = $PS["DOM.HTML.Indexed.OnOff"];
  var On = (function () {
      function On() {

      };
      On.value = new On();
      return On;
  })();
  var Off = (function () {
      function Off() {

      };
      Off.value = new Off();
      return Off;
  })();
  var renderOnOff = function (v) {
      if (v instanceof On) {
          return "on";
      };
      if (v instanceof Off) {
          return "off";
      };
      throw new Error("Failed pattern match at DOM.HTML.Indexed.OnOff (line 8, column 15 - line 10, column 15): " + [ v.constructor.name ]);
  };
  exports["On"] = On;
  exports["Off"] = Off;
  exports["renderOnOff"] = renderOnOff;
})(PS);
(function(exports) {
  "use strict";

  var unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };                                         
  exports.ordIntImpl = unsafeCompareImpl;   
  exports.ordStringImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];              
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Ord = function (Eq0, compare) {
      this.Eq0 = Eq0;
      this.compare = compare;
  }; 
  var ordUnit = new Ord(function () {
      return Data_Eq.eqUnit;
  }, function (v) {
      return function (v1) {
          return Data_Ordering.EQ.value;
      };
  });
  var ordString = new Ord(function () {
      return Data_Eq.eqString;
  }, $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordInt = new Ord(function () {
      return Data_Eq.eqInt;
  }, $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var compare = function (dict) {
      return dict.compare;
  };
  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["ordInt"] = ordInt;
  exports["ordString"] = ordString;
  exports["ordUnit"] = ordUnit;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];                
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var snd = function (v) {
      return v.value1;
  };
  var functorTuple = new Data_Functor.Functor(function (f) {
      return function (m) {
          return new Tuple(m.value0, f(m.value1));
      };
  });                                                                                                   
  var fst = function (v) {
      return v.value0;
  }; 
  var eqTuple = function (dictEq) {
      return function (dictEq1) {
          return new Data_Eq.Eq(function (x) {
              return function (y) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
              };
          });
      };
  };
  var ordTuple = function (dictOrd) {
      return function (dictOrd1) {
          return new Data_Ord.Ord(function () {
              return eqTuple(dictOrd.Eq0())(dictOrd1.Eq0());
          }, function (x) {
              return function (y) {
                  var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                  if (v instanceof Data_Ordering.LT) {
                      return Data_Ordering.LT.value;
                  };
                  if (v instanceof Data_Ordering.GT) {
                      return Data_Ordering.GT.value;
                  };
                  return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
              };
          });
      };
  };
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
  exports["ordTuple"] = ordTuple;
  exports["functorTuple"] = functorTuple;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.Machine"] = $PS["Halogen.VDom.Machine"] || {};
  var exports = $PS["Halogen.VDom.Machine"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var Step = (function () {
      function Step(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Step.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Step(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Step;
  })();
  var unStep = Unsafe_Coerce.unsafeCoerce;
  var step = function (v, a) {
      return v.value2(v.value1, a);
  };
  var mkStep = Unsafe_Coerce.unsafeCoerce;
  var halt = function (v) {
      return v.value3(v.value1);
  };
  var extract = unStep(function (v) {
      return v.value0;
  });
  exports["Step"] = Step;
  exports["mkStep"] = mkStep;
  exports["unStep"] = unStep;
  exports["extract"] = extract;
  exports["step"] = step;
  exports["halt"] = halt;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGetAny = function (key, obj) {
    return obj[key];
  };

  exports.unsafeHasAny = function (key, obj) {
    return obj.hasOwnProperty(key);
  };

  exports.unsafeSetAny = function (key, val, obj) {
      obj[key] = val;
  };

  exports.forE = function (a, f) {
    var b = [];
    for (var i = 0; i < a.length; i++) {
      b.push(f(i, a[i]));
    }
    return b;
  };

  exports.forEachE = function (a, f) {
    for (var i = 0; i < a.length; i++) {
      f(a[i]);
    }
  };

  exports.forInE = function (o, f) {
    var ks = Object.keys(o);
    for (var i = 0; i < ks.length; i++) {
      var k = ks[i];
      f(k, o[k]);
    }
  };

  exports.diffWithIxE = function (a1, a2, f1, f2, f3) {
    var a3 = [];
    var l1 = a1.length;
    var l2 = a2.length;
    var i  = 0;
    while (1) {
      if (i < l1) {
        if (i < l2) {
          a3.push(f1(i, a1[i], a2[i]));
        } else {
          f2(i, a1[i]);
        }
      } else if (i < l2) {
        a3.push(f3(i, a2[i]));
      } else {
        break;
      }
      i++;
    }
    return a3;
  };

  exports.strMapWithIxE = function (as, fk, f) {
    var o = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      o[k] = f(k, i, a);
    }
    return o;
  };

  exports.diffWithKeyAndIxE = function (o1, as, fk, f1, f2, f3) {
    var o2 = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      if (o1.hasOwnProperty(k)) {
        o2[k] = f1(k, i, o1[k], a);
      } else {
        o2[k] = f3(k, i, a);
      }
    }
    for (var k in o1) {
      if (k in o2) {
        continue;
      }
      f2(k, o1[k]);
    }
    return o2;
  };

  exports.refEq = function (a, b) {
    return a === b;
  };

  exports.createTextNode = function (s, doc) {
    return doc.createTextNode(s);
  };

  exports.setTextContent = function (s, n) {
    n.textContent = s;
  };

  exports.createElement = function (ns, name, doc) {
    if (ns != null) {
      return doc.createElementNS(ns, name);
    } else {
      return doc.createElement(name)
    }
  };

  exports.insertChildIx = function (i, a, b) {
    var n = b.childNodes.item(i) || null;
    if (n !== a) {
      b.insertBefore(a, n);
    }
  };

  exports.removeChild = function (a, b) {
    if (b && a.parentNode === b) {
      b.removeChild(a);
    }
  };

  exports.parentNode = function (a) {
    return a.parentNode;
  };

  exports.setAttribute = function (ns, attr, val, el) {
    if (ns != null) {
      el.setAttributeNS(ns, attr, val);
    } else {
      el.setAttribute(attr, val);
    }
  };

  exports.removeAttribute = function (ns, attr, el) {
    if (ns != null) {
      el.removeAttributeNS(ns, attr);
    } else {
      el.removeAttribute(attr);
    }
  };

  exports.hasAttribute = function (ns, attr, el) {
    if (ns != null) {
      return el.hasAttributeNS(ns, attr);
    } else {
      return el.hasAttribute(attr);
    }
  };

  exports.addEventListener = function (ev, listener, el) {
    el.addEventListener(ev, listener, false);
  };

  exports.removeEventListener = function (ev, listener, el) {
    el.removeEventListener(ev, listener, false);
  };

  exports.jsUndefined = void 0;
})(PS["Halogen.VDom.Util"] = PS["Halogen.VDom.Util"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.Util"] = $PS["Halogen.VDom.Util"] || {};
  var exports = $PS["Halogen.VDom.Util"];
  var $foreign = $PS["Halogen.VDom.Util"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var unsafeLookup = $foreign.unsafeGetAny;
  var unsafeFreeze = Unsafe_Coerce.unsafeCoerce;
  var pokeMutMap = $foreign.unsafeSetAny;
  var newMutMap = Foreign_Object_ST["new"];
  exports["newMutMap"] = newMutMap;
  exports["pokeMutMap"] = pokeMutMap;
  exports["unsafeFreeze"] = unsafeFreeze;
  exports["unsafeLookup"] = unsafeLookup;
  exports["unsafeGetAny"] = $foreign.unsafeGetAny;
  exports["unsafeHasAny"] = $foreign.unsafeHasAny;
  exports["unsafeSetAny"] = $foreign.unsafeSetAny;
  exports["forE"] = $foreign.forE;
  exports["forEachE"] = $foreign.forEachE;
  exports["forInE"] = $foreign.forInE;
  exports["diffWithIxE"] = $foreign.diffWithIxE;
  exports["diffWithKeyAndIxE"] = $foreign.diffWithKeyAndIxE;
  exports["strMapWithIxE"] = $foreign.strMapWithIxE;
  exports["refEq"] = $foreign.refEq;
  exports["createTextNode"] = $foreign.createTextNode;
  exports["setTextContent"] = $foreign.setTextContent;
  exports["createElement"] = $foreign.createElement;
  exports["insertChildIx"] = $foreign.insertChildIx;
  exports["removeChild"] = $foreign.removeChild;
  exports["parentNode"] = $foreign.parentNode;
  exports["setAttribute"] = $foreign.setAttribute;
  exports["removeAttribute"] = $foreign.removeAttribute;
  exports["hasAttribute"] = $foreign.hasAttribute;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
  exports["jsUndefined"] = $foreign.jsUndefined;
})(PS);
(function(exports) {
  "use strict";

  exports.eventListener = function (fn) {
    return function () {
      return function (event) {
        return fn(event)();
      };
    };
  };

  exports.addEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.addEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };

  exports.removeEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.removeEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };

  exports.dispatchEvent = function (event) {
    return function (target) {
      return function () {
        return target.dispatchEvent(event);
      };
    };
  };
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.Event.EventTarget"] = $PS["Web.Event.EventTarget"] || {};
  var exports = $PS["Web.Event.EventTarget"];
  var $foreign = $PS["Web.Event.EventTarget"];
  exports["eventListener"] = $foreign.eventListener;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
  exports["dispatchEvent"] = $foreign.dispatchEvent;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.DOM.Prop"] = $PS["Halogen.VDom.DOM.Prop"] || {};
  var exports = $PS["Halogen.VDom.DOM.Prop"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];                
  var Created = (function () {
      function Created(value0) {
          this.value0 = value0;
      };
      Created.create = function (value0) {
          return new Created(value0);
      };
      return Created;
  })();
  var Removed = (function () {
      function Removed(value0) {
          this.value0 = value0;
      };
      Removed.create = function (value0) {
          return new Removed(value0);
      };
      return Removed;
  })();
  var Attribute = (function () {
      function Attribute(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Attribute.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Attribute(value0, value1, value2);
              };
          };
      };
      return Attribute;
  })();
  var Property = (function () {
      function Property(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Property.create = function (value0) {
          return function (value1) {
              return new Property(value0, value1);
          };
      };
      return Property;
  })();
  var Handler = (function () {
      function Handler(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Handler.create = function (value0) {
          return function (value1) {
              return new Handler(value0, value1);
          };
      };
      return Handler;
  })();
  var Ref = (function () {
      function Ref(value0) {
          this.value0 = value0;
      };
      Ref.create = function (value0) {
          return new Ref(value0);
      };
      return Ref;
  })();
  var unsafeGetProperty = Halogen_VDom_Util.unsafeGetAny;
  var setProperty = Halogen_VDom_Util.unsafeSetAny;
  var removeProperty = function (key, el) {
      var v = Halogen_VDom_Util.hasAttribute(Data_Nullable["null"], key, el);
      if (v) {
          return Halogen_VDom_Util.removeAttribute(Data_Nullable["null"], key, el);
      };
      var v1 = Foreign.typeOf(Halogen_VDom_Util.unsafeGetAny(key, el));
      if (v1 === "string") {
          return Halogen_VDom_Util.unsafeSetAny(key, "", el);
      };
      if (key === "rowSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      if (key === "colSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      return Halogen_VDom_Util.unsafeSetAny(key, Halogen_VDom_Util.jsUndefined, el);
  };
  var propToStrKey = function (v) {
      if (v instanceof Attribute && v.value0 instanceof Data_Maybe.Just) {
          return "attr/" + (v.value0.value0 + (":" + v.value1));
      };
      if (v instanceof Attribute) {
          return "attr/:" + v.value1;
      };
      if (v instanceof Property) {
          return "prop/" + v.value0;
      };
      if (v instanceof Handler) {
          return "handler/" + v.value0;
      };
      if (v instanceof Ref) {
          return "ref";
      };
      throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 182, column 16 - line 187, column 16): " + [ v.constructor.name ]);
  };
  var propFromString = Unsafe_Coerce.unsafeCoerce;
  var propFromInt = Unsafe_Coerce.unsafeCoerce;
  var propFromBoolean = Unsafe_Coerce.unsafeCoerce;
  var buildProp = function (emit) {
      return function (el) {
          var removeProp = function (prevEvents) {
              return function (v, v1) {
                  if (v1 instanceof Attribute) {
                      return Halogen_VDom_Util.removeAttribute(Data_Nullable.toNullable(v1.value0), v1.value1, el);
                  };
                  if (v1 instanceof Property) {
                      return removeProperty(v1.value0, el);
                  };
                  if (v1 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v1.value0, prevEvents);
                      return Halogen_VDom_Util.removeEventListener(v1.value0, Data_Tuple.fst(handler), el);
                  };
                  if (v1 instanceof Ref) {
                      return Data_Unit.unit;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 169, column 5 - line 179, column 18): " + [ v1.constructor.name ]);
              };
          };
          var mbEmit = function (v) {
              if (v instanceof Data_Maybe.Just) {
                  return emit(v.value0)();
              };
              return Data_Unit.unit;
          };
          var haltProp = function (state) {
              var v = Foreign_Object.lookup("ref")(state.props);
              if (v instanceof Data_Maybe.Just && v.value0 instanceof Ref) {
                  return mbEmit(v.value0.value0(new Removed(el)));
              };
              return Data_Unit.unit;
          };
          var diffProp = function (prevEvents, events) {
              return function (v, v1, v11, v2) {
                  if (v11 instanceof Attribute && v2 instanceof Attribute) {
                      var $56 = v11.value2 === v2.value2;
                      if ($56) {
                          return v2;
                      };
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v11 instanceof Property && v2 instanceof Property) {
                      var v4 = Halogen_VDom_Util.refEq(v11.value1, v2.value1);
                      if (v4) {
                          return v2;
                      };
                      if (v2.value0 === "value") {
                          var elVal = unsafeGetProperty("value", el);
                          var $65 = Halogen_VDom_Util.refEq(elVal, v2.value1);
                          if ($65) {
                              return v2;
                          };
                          setProperty(v2.value0, v2.value1, el);
                          return v2;
                      };
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v11 instanceof Handler && v2 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v2.value0, prevEvents);
                      Effect_Ref.write(v2.value1)(Data_Tuple.snd(handler))();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, handler, events);
                      return v2;
                  };
                  return v2;
              };
          };
          var applyProp = function (events) {
              return function (v, v1, v2) {
                  if (v2 instanceof Attribute) {
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v2 instanceof Property) {
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v2 instanceof Handler) {
                      var v3 = Halogen_VDom_Util.unsafeGetAny(v2.value0, events);
                      if (Halogen_VDom_Util.unsafeHasAny(v2.value0, events)) {
                          Effect_Ref.write(v2.value1)(Data_Tuple.snd(v3))();
                          return v2;
                      };
                      var ref = Effect_Ref["new"](v2.value1)();
                      var listener = Web_Event_EventTarget.eventListener(function (ev) {
                          return function __do() {
                              var f$prime = Effect_Ref.read(ref)();
                              return mbEmit(f$prime(ev));
                          };
                      })();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, new Data_Tuple.Tuple(listener, ref), events);
                      Halogen_VDom_Util.addEventListener(v2.value0, listener, el);
                      return v2;
                  };
                  if (v2 instanceof Ref) {
                      mbEmit(v2.value0(new Created(el)));
                      return v2;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 113, column 5 - line 135, column 15): " + [ v2.constructor.name ]);
              };
          };
          var patchProp = function (state, ps2) {
              var events = Halogen_VDom_Util.newMutMap();
              var onThis = removeProp(state.events);
              var onThese = diffProp(state.events, events);
              var onThat = applyProp(events);
              var props = Halogen_VDom_Util.diffWithKeyAndIxE(state.props, ps2, propToStrKey, onThese, onThis, onThat);
              var nextState = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: props
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, nextState, patchProp, haltProp));
          };
          var renderProp = function (ps1) {
              var events = Halogen_VDom_Util.newMutMap();
              var ps1$prime = Halogen_VDom_Util.strMapWithIxE(ps1, propToStrKey, applyProp(events));
              var state = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: ps1$prime
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, state, patchProp, haltProp));
          };
          return renderProp;
      };
  };
  exports["Property"] = Property;
  exports["Handler"] = Handler;
  exports["Ref"] = Ref;
  exports["Created"] = Created;
  exports["Removed"] = Removed;
  exports["propFromString"] = propFromString;
  exports["propFromBoolean"] = propFromBoolean;
  exports["propFromInt"] = propFromInt;
  exports["buildProp"] = buildProp;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.Types"] = $PS["Halogen.VDom.Types"] || {};
  var exports = $PS["Halogen.VDom.Types"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Text = (function () {
      function Text(value0) {
          this.value0 = value0;
      };
      Text.create = function (value0) {
          return new Text(value0);
      };
      return Text;
  })();
  var Elem = (function () {
      function Elem(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Elem.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Elem(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Elem;
  })();
  var Keyed = (function () {
      function Keyed(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Keyed.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Keyed(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Keyed;
  })();
  var Widget = (function () {
      function Widget(value0) {
          this.value0 = value0;
      };
      Widget.create = function (value0) {
          return new Widget(value0);
      };
      return Widget;
  })();
  var Grafted = (function () {
      function Grafted(value0) {
          this.value0 = value0;
      };
      Grafted.create = function (value0) {
          return new Grafted(value0);
      };
      return Grafted;
  })();
  var Graft = (function () {
      function Graft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Graft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Graft(value0, value1, value2);
              };
          };
      };
      return Graft;
  })();
  var unGraft = function (f) {
      return function ($58) {
          return f($58);
      };
  };           
  var graft = Unsafe_Coerce.unsafeCoerce;
  var bifunctorGraft = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return unGraft(function (v) {
              return graft(new Graft(function ($60) {
                  return f(v.value0($60));
              }, function ($61) {
                  return g(v.value1($61));
              }, v.value2));
          });
      };
  });
  var runGraft = unGraft(function (v) {
      var go = function (v2) {
          if (v2 instanceof Text) {
              return new Text(v2.value0);
          };
          if (v2 instanceof Elem) {
              return new Elem(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(go)(v2.value3));
          };
          if (v2 instanceof Keyed) {
              return new Keyed(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(go))(v2.value3));
          };
          if (v2 instanceof Widget) {
              return new Widget(v.value1(v2.value0));
          };
          if (v2 instanceof Grafted) {
              return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v.value0)(v.value1)(v2.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.Types (line 86, column 7 - line 86, column 27): " + [ v2.constructor.name ]);
      };
      return go(v.value2);
  });
  exports["Text"] = Text;
  exports["Elem"] = Elem;
  exports["Keyed"] = Keyed;
  exports["Widget"] = Widget;
  exports["Grafted"] = Grafted;
  exports["runGraft"] = runGraft;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.HTML.Core"] = $PS["Halogen.HTML.Core"] || {};
  var exports = $PS["Halogen.HTML.Core"];
  var DOM_HTML_Indexed_InputType = $PS["DOM.HTML.Indexed.InputType"];
  var DOM_HTML_Indexed_OnOff = $PS["DOM.HTML.Indexed.OnOff"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var HTML = function (x) {
      return x;
  };
  var ClassName = function (x) {
      return x;
  };
  var IsProp = function (toPropValue) {
      this.toPropValue = toPropValue;
  };
  var widget = function ($30) {
      return HTML(Halogen_VDom_Types.Widget.create($30));
  };
  var toPropValue = function (dict) {
      return dict.toPropValue;
  };
  var text = function ($31) {
      return HTML(Halogen_VDom_Types.Text.create($31));
  };                                                      
  var ref = function (f) {
      return Halogen_VDom_DOM_Prop.Ref.create(function ($32) {
          return f((function (v) {
              if (v instanceof Halogen_VDom_DOM_Prop.Created) {
                  return new Data_Maybe.Just(v.value0);
              };
              if (v instanceof Halogen_VDom_DOM_Prop.Removed) {
                  return Data_Maybe.Nothing.value;
              };
              throw new Error("Failed pattern match at Halogen.HTML.Core (line 104, column 21 - line 106, column 23): " + [ v.constructor.name ]);
          })($32));
      });
  };
  var prop = function (dictIsProp) {
      return function (v) {
          var $33 = Halogen_VDom_DOM_Prop.Property.create(v);
          var $34 = toPropValue(dictIsProp);
          return function ($35) {
              return $33($34($35));
          };
      };
  };           
  var newtypeHTML = new Data_Newtype.Newtype(function (n) {
      return n;
  }, HTML);
  var newtypeClassName = new Data_Newtype.Newtype(function (n) {
      return n;
  }, ClassName);
  var isPropString = new IsProp(Halogen_VDom_DOM_Prop.propFromString);
  var isPropOnOff = new IsProp(function ($43) {
      return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_OnOff.renderOnOff($43));
  });
  var isPropInt = new IsProp(Halogen_VDom_DOM_Prop.propFromInt);
  var isPropInputType = new IsProp(function ($49) {
      return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_InputType.renderInputType($49));
  });
  var isPropBoolean = new IsProp(Halogen_VDom_DOM_Prop.propFromBoolean);
  var handler = Halogen_VDom_DOM_Prop.Handler.create;
  var element = function (ns) {
      return function (name) {
          return function (props) {
              return function (children) {
                  return new Halogen_VDom_Types.Elem(ns, name, props, children);
              };
          };
      };
  };
  exports["widget"] = widget;
  exports["text"] = text;
  exports["element"] = element;
  exports["prop"] = prop;
  exports["handler"] = handler;
  exports["ref"] = ref;
  exports["ClassName"] = ClassName;
  exports["newtypeHTML"] = newtypeHTML;
  exports["isPropString"] = isPropString;
  exports["isPropInt"] = isPropInt;
  exports["isPropBoolean"] = isPropBoolean;
  exports["isPropInputType"] = isPropInputType;
  exports["isPropOnOff"] = isPropOnOff;
  exports["newtypeClassName"] = newtypeClassName;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Query.Input"] = $PS["Halogen.Query.Input"] || {};
  var exports = $PS["Halogen.Query.Input"];
  var RefUpdate = (function () {
      function RefUpdate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RefUpdate.create = function (value0) {
          return function (value1) {
              return new RefUpdate(value0, value1);
          };
      };
      return RefUpdate;
  })();
  var Action = (function () {
      function Action(value0) {
          this.value0 = value0;
      };
      Action.create = function (value0) {
          return new Action(value0);
      };
      return Action;
  })();
  exports["RefUpdate"] = RefUpdate;
  exports["Action"] = Action;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.HTML.Properties"] = $PS["Halogen.HTML.Properties"] || {};
  var exports = $PS["Halogen.HTML.Properties"];
  var DOM_HTML_Indexed_OnOff = $PS["DOM.HTML.Indexed.OnOff"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var ref = (function () {
      var go = function (p) {
          return function (mel) {
              return new Data_Maybe.Just(new Halogen_Query_Input.RefUpdate(p, mel));
          };
      };
      return function ($11) {
          return Halogen_HTML_Core.ref(go($11));
      };
  })();
  var prop = function (dictIsProp) {
      return Halogen_HTML_Core.prop(dictIsProp);
  };                                                                     
  var spellcheck = prop(Halogen_HTML_Core.isPropBoolean)("spellcheck");
  var tabIndex = prop(Halogen_HTML_Core.isPropInt)("tabIndex");
  var type_ = function (dictIsProp) {
      return prop(dictIsProp)("type");
  };
  var value = prop(Halogen_HTML_Core.isPropString)("value");  
  var placeholder = prop(Halogen_HTML_Core.isPropString)("placeholder");
  var name = prop(Halogen_HTML_Core.isPropString)("name");
  var href = prop(Halogen_HTML_Core.isPropString)("href");   
  var classes = (function () {
      var $14 = prop(Halogen_HTML_Core.isPropString)("className");
      var $15 = Data_String_Common.joinWith(" ");
      var $16 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap(Halogen_HTML_Core.newtypeClassName));
      return function ($17) {
          return $14($15($16($17)));
      };
  })();
  var class_ = (function () {
      var $18 = prop(Halogen_HTML_Core.isPropString)("className");
      var $19 = Data_Newtype.unwrap(Halogen_HTML_Core.newtypeClassName);
      return function ($20) {
          return $18($19($20));
      };
  })();                                                              
  var autocomplete = (function () {
      var $21 = prop(Halogen_HTML_Core.isPropOnOff)("autocomplete");
      return function ($22) {
          return $21((function (b) {
              if (b) {
                  return DOM_HTML_Indexed_OnOff.On.value;
              };
              return DOM_HTML_Indexed_OnOff.Off.value;
          })($22));
      };
  })();
  exports["ref"] = ref;
  exports["class_"] = class_;
  exports["classes"] = classes;
  exports["href"] = href;
  exports["name"] = name;
  exports["type_"] = type_;
  exports["value"] = value;
  exports["spellcheck"] = spellcheck;
  exports["placeholder"] = placeholder;
  exports["autocomplete"] = autocomplete;
  exports["tabIndex"] = tabIndex;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Network.RemoteData"] = $PS["Network.RemoteData"] || {};
  var exports = $PS["Network.RemoteData"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];            
  var NotAsked = (function () {
      function NotAsked() {

      };
      NotAsked.value = new NotAsked();
      return NotAsked;
  })();
  var Loading = (function () {
      function Loading() {

      };
      Loading.value = new Loading();
      return Loading;
  })();
  var Failure = (function () {
      function Failure(value0) {
          this.value0 = value0;
      };
      Failure.create = function (value0) {
          return new Failure(value0);
      };
      return Failure;
  })();
  var Success = (function () {
      function Success(value0) {
          this.value0 = value0;
      };
      Success.create = function (value0) {
          return new Success(value0);
      };
      return Success;
  })();
  var toMaybe = function (v) {
      if (v instanceof Success) {
          return new Data_Maybe.Just(v.value0);
      };
      return Data_Maybe.Nothing.value;
  };
  var fromEither = function (v) {
      if (v instanceof Data_Either.Left) {
          return new Failure(v.value0);
      };
      if (v instanceof Data_Either.Right) {
          return new Success(v.value0);
      };
      throw new Error("Failed pattern match at Network.RemoteData (line 134, column 1 - line 134, column 55): " + [ v.constructor.name ]);
  };
  var foldableRemoteData = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (v) {
          return function (v1) {
              if (v1 instanceof Success) {
                  return v(v1.value0);
              };
              if (v1 instanceof Failure) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v1 instanceof NotAsked) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v1 instanceof Loading) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              throw new Error("Failed pattern match at Network.RemoteData (line 91, column 1 - line 97, column 27): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (f) {
      return Data_Foldable.foldlDefault(foldableRemoteData)(f);
  }, function (f) {
      return Data_Foldable.foldrDefault(foldableRemoteData)(f);
  });
  exports["NotAsked"] = NotAsked;
  exports["Loading"] = Loading;
  exports["Failure"] = Failure;
  exports["Success"] = Success;
  exports["toMaybe"] = toMaybe;
  exports["fromEither"] = fromEither;
  exports["foldableRemoteData"] = foldableRemoteData;
})(PS);
(function($PS) {
  "use strict";
  $PS["Autocomplete.Internal"] = $PS["Autocomplete.Internal"] || {};
  var exports = $PS["Autocomplete.Internal"];
  var Affjax = $PS["Affjax"];
  var Affjax_ResponseFormat = $PS["Affjax.ResponseFormat"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Network_RemoteData = $PS["Network.RemoteData"];                
  var whenElem = function (cond) {
      return function (render) {
          if (cond) {
              return render(Data_Unit.unit);
          };
          return Halogen_HTML_Core.text("");
      };
  };
  var classes_ = (function () {
      var $11 = Data_Functor.map(Data_Functor.functorArray)(Halogen_HTML_Core.ClassName);
      return function ($12) {
          return Halogen_HTML_Properties.classes($11($12));
      };
  })();

  //- CSS helpers
  // from:
  // https://github.com/citizennet/purescript-halogen-select/blob/256d09dfedf3aba3e6ed004753f0d89cea7cc993/examples/Internal/CSS.purs
  var class_ = function ($13) {
      return Halogen_HTML_Properties.class_(Halogen_HTML_Core.ClassName($13));
  };

  // | Return the "rest" of a city's context: district, region, country
  var cityLocation = function (city) {
      var region = Data_Maybe.maybe("")(function (v) {
          return v + ", ";
      })(city.region);
      var district = Data_Maybe.maybe("")(function (v) {
          return v + ", ";
      })(city.district);
      var country = Data_Maybe.maybe("")(Control_Category.identity(Control_Category.categoryFn))(city.country);
      return district + (region + country);
  };

  // | Given a `City`, return a suitable one-line representation
  var cityString = function (city) {
      var location = cityLocation(city);
      var separator = (function () {
          var $3 = Data_String_Common["null"](location);
          if ($3) {
              return "";
          };
          return ", ";
      })();
      return city.name + (separator + location);
  };

  // | Makes an autocomplete request to the service, as specified in:
  // | https://geocode.city/docs.html
  // 
  // References:
  // https://pursuit.purescript.org/packages/purescript-affjax/11.0.0
  // https://pursuit.purescript.org/packages/purescript-argonaut-codecs/7.0.0
  // https://github.com/citizennet/purescript-halogen-select/blob/v5.0.0/examples/Components/Typeahead.purs
  var autocompleteCandidates = function (partialCity) {
      return function (apiKey) {
          return function (limit$prime) {
              var prefix = Data_Maybe.maybe("?")(function (k) {
                  return "?api-key=" + (k + "&");
              })(apiKey);
              var limit = Data_Maybe.fromMaybe(5)(limit$prime);
              var failed = (function () {
                  var $14 = Control_Applicative.pure(Effect_Aff.applicativeAff);
                  return function ($15) {
                      return $14(Network_RemoteData.Failure.create($15));
                  };
              })();
              return Control_Bind.bind(Effect_Aff.bindAff)(Affjax.get(Affjax_ResponseFormat.json)("https://api.geocode.city/autocomplete" + (prefix + ("q=" + (partialCity + ("&limit=" + Data_Int.toStringAs(Data_Int.decimal)(limit)))))))(function (res) {
                  if (res instanceof Data_Either.Left) {
                      if (res.value0 instanceof Affjax.ResponseBodyError) {
                          if (res.value0.value1.status === 401) {
                              return failed("Missing API Key.");
                          };
                          if (res.value0.value1.status === 403) {
                              return failed("Invalid API Key.");
                          };
                          if (res.value0.value1.status === 429) {
                              return failed("Request limit reached.");
                          };
                          return failed(Affjax.printError(res.value0));
                      };
                      return failed(Affjax.printError(res.value0));
                  };
                  if (res instanceof Data_Either.Right) {
                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(Network_RemoteData.fromEither(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeArray(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonNumber)(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonNumber)(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonString)(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonInt)(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeJsonString)(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                          return "timezone";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "region";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "population";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "name";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "longitude";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "latitude";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "district";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "countryCode";
                      }))()())(new Data_Symbol.IsSymbol(function () {
                          return "country";
                      }))()())()))(res.value0.body)));
                  };
                  throw new Error("Failed pattern match at Autocomplete.Internal (line 42, column 3 - line 54, column 70): " + [ res.constructor.name ]);
              });
          };
      };
  };
  exports["autocompleteCandidates"] = autocompleteCandidates;
  exports["cityString"] = cityString;
  exports["cityLocation"] = cityLocation;
  exports["class_"] = class_;
  exports["classes_"] = classes_;
  exports["whenElem"] = whenElem;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.State.Class"] = $PS["Control.Monad.State.Class"] || {};
  var exports = $PS["Control.Monad.State.Class"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                
  var MonadState = function (Monad0, state) {
      this.Monad0 = Monad0;
      this.state = state;
  };
  var state = function (dict) {
      return dict.state;
  };
  var modify_ = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
          });
      };
  };
  var get = function (dictMonadState) {
      return state(dictMonadState)(function (s) {
          return new Data_Tuple.Tuple(s, s);
      });
  };
  exports["MonadState"] = MonadState;
  exports["get"] = get;
  exports["modify_"] = modify_;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Aff.Class"] = $PS["Effect.Aff.Class"] || {};
  var exports = $PS["Effect.Aff.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var MonadAff = function (MonadEffect0, liftAff) {
      this.MonadEffect0 = MonadEffect0;
      this.liftAff = liftAff;
  };
  var monadAffAff = new MonadAff(function () {
      return Effect_Aff.monadEffectAff;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftAff = function (dict) {
      return dict.liftAff;
  };
  exports["liftAff"] = liftAff;
  exports["MonadAff"] = MonadAff;
  exports["monadAffAff"] = monadAffAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.HTML.Elements"] = $PS["Halogen.HTML.Elements"] || {};
  var exports = $PS["Halogen.HTML.Elements"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var element = Halogen_HTML_Core.element(Data_Maybe.Nothing.value);
  var input = function (props) {
      return element("input")(props)([  ]);
  };                         
  var small = element("small");
  var small_ = small([  ]);
  var span = element("span");
  var span_ = span([  ]);
  var div = element("div");
  var div_ = div([  ]);  
  var a = element("a");
  exports["a"] = a;
  exports["div"] = div;
  exports["div_"] = div_;
  exports["input"] = input;
  exports["small_"] = small_;
  exports["span"] = span;
  exports["span_"] = span_;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.List"] = $PS["Data.List"] || {};
  var exports = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var reverse = (function () {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return acc;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List (line 368, column 3 - line 368, column 19): " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  })();
  var $$null = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };
  exports["null"] = $$null;
  exports["reverse"] = reverse;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.CatQueue"] = $PS["Data.CatQueue"] || {};
  var exports = $PS["Data.CatQueue"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatQueue = (function () {
      function CatQueue(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatQueue.create = function (value0) {
          return function (value1) {
              return new CatQueue(value0, value1);
          };
      };
      return CatQueue;
  })();
  var uncons = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          if (v.value0 instanceof Data_List_Types.Nil) {
              $copy_v = new CatQueue(Data_List.reverse(v.value1), Data_List_Types.Nil.value);
              return;
          };
          if (v.value0 instanceof Data_List_Types.Cons) {
              $tco_done = true;
              return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0, new CatQueue(v.value0.value1, v.value1)));
          };
          throw new Error("Failed pattern match at Data.CatQueue (line 83, column 1 - line 83, column 63): " + [ v.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var snoc = function (v) {
      return function (a) {
          return new CatQueue(v.value0, new Data_List_Types.Cons(a, v.value1));
      };
  };
  var $$null = function (v) {
      if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };                                                                                                
  var empty = new CatQueue(Data_List_Types.Nil.value, Data_List_Types.Nil.value);
  exports["empty"] = empty;
  exports["null"] = $$null;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.CatList"] = $PS["Data.CatList"] || {};
  var exports = $PS["Data.CatList"];
  var Data_CatQueue = $PS["Data.CatQueue"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatNil = (function () {
      function CatNil() {

      };
      CatNil.value = new CatNil();
      return CatNil;
  })();
  var CatCons = (function () {
      function CatCons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatCons.create = function (value0) {
          return function (value1) {
              return new CatCons(value0, value1);
          };
      };
      return CatCons;
  })();
  var link = function (v) {
      return function (v1) {
          if (v instanceof CatNil) {
              return v1;
          };
          if (v1 instanceof CatNil) {
              return v;
          };
          if (v instanceof CatCons) {
              return new CatCons(v.value0, Data_CatQueue.snoc(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.CatList (line 109, column 1 - line 109, column 54): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var foldr = function (k) {
      return function (b) {
          return function (q) {
              var foldl = function ($copy_v) {
                  return function ($copy_c) {
                      return function ($copy_v1) {
                          var $tco_var_v = $copy_v;
                          var $tco_var_c = $copy_c;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v, c, v1) {
                              if (v1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return c;
                              };
                              if (v1 instanceof Data_List_Types.Cons) {
                                  $tco_var_v = v;
                                  $tco_var_c = v(c)(v1.value0);
                                  $copy_v1 = v1.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.CatList (line 125, column 3 - line 125, column 59): " + [ v.constructor.name, c.constructor.name, v1.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v, $tco_var_c, $copy_v1);
                          };
                          return $tco_result;
                      };
                  };
              };
              var go = function ($copy_xs) {
                  return function ($copy_ys) {
                      var $tco_var_xs = $copy_xs;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(xs, ys) {
                          var v = Data_CatQueue.uncons(xs);
                          if (v instanceof Data_Maybe.Nothing) {
                              $tco_done = true;
                              return foldl(function (x) {
                                  return function (i) {
                                      return i(x);
                                  };
                              })(b)(ys);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              $tco_var_xs = v.value0.value1;
                              $copy_ys = new Data_List_Types.Cons(k(v.value0.value0), ys);
                              return;
                          };
                          throw new Error("Failed pattern match at Data.CatList (line 121, column 14 - line 123, column 67): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_xs, $copy_ys);
                      };
                      return $tco_result;
                  };
              };
              return go(q)(Data_List_Types.Nil.value);
          };
      };
  };
  var uncons = function (v) {
      if (v instanceof CatNil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof CatCons) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, (function () {
              var $44 = Data_CatQueue["null"](v.value1);
              if ($44) {
                  return CatNil.value;
              };
              return foldr(link)(CatNil.value)(v.value1);
          })()));
      };
      throw new Error("Failed pattern match at Data.CatList (line 100, column 1 - line 100, column 61): " + [ v.constructor.name ]);
  };
  var empty = CatNil.value;
  var append = link;
  var semigroupCatList = new Data_Semigroup.Semigroup(append);
  var snoc = function (cat) {
      return function (a) {
          return append(cat)(new CatCons(a, Data_CatQueue.empty));
      };
  };
  exports["empty"] = empty;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
  exports["semigroupCatList"] = semigroupCatList;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Free"] = $PS["Control.Monad.Free"] || {};
  var exports = $PS["Control.Monad.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Data_CatList = $PS["Data.CatList"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Free = (function () {
      function Free(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Free.create = function (value0) {
          return function (value1) {
              return new Free(value0, value1);
          };
      };
      return Free;
  })();
  var Return = (function () {
      function Return(value0) {
          this.value0 = value0;
      };
      Return.create = function (value0) {
          return new Return(value0);
      };
      return Return;
  })();
  var Bind = (function () {
      function Bind(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bind.create = function (value0) {
          return function (value1) {
              return new Bind(value0, value1);
          };
      };
      return Bind;
  })();
  var toView = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          var runExpF = function (v2) {
              return v2;
          };
          var concatF = function (v2) {
              return function (r) {
                  return new Free(v2.value0, Data_Semigroup.append(Data_CatList.semigroupCatList)(v2.value1)(r));
              };
          };
          if (v.value0 instanceof Return) {
              var v2 = Data_CatList.uncons(v.value1);
              if (v2 instanceof Data_Maybe.Nothing) {
                  $tco_done = true;
                  return new Return(v.value0.value0);
              };
              if (v2 instanceof Data_Maybe.Just) {
                  $copy_v = concatF(runExpF(v2.value0.value0)(v.value0.value0))(v2.value0.value1);
                  return;
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 227, column 7 - line 231, column 64): " + [ v2.constructor.name ]);
          };
          if (v.value0 instanceof Bind) {
              $tco_done = true;
              return new Bind(v.value0.value0, function (a) {
                  return concatF(v.value0.value1(a))(v.value1);
              });
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 225, column 3 - line 233, column 56): " + [ v.value0.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var fromView = function (f) {
      return new Free(f, Data_CatList.empty);
  };
  var freeMonad = new Control_Monad.Monad(function () {
      return freeApplicative;
  }, function () {
      return freeBind;
  });
  var freeFunctor = new Data_Functor.Functor(function (k) {
      return function (f) {
          return Control_Bind.bindFlipped(freeBind)((function () {
              var $120 = Control_Applicative.pure(freeApplicative);
              return function ($121) {
                  return $120(k($121));
              };
          })())(f);
      };
  });
  var freeBind = new Control_Bind.Bind(function () {
      return freeApply;
  }, function (v) {
      return function (k) {
          return new Free(v.value0, Data_CatList.snoc(v.value1)(k));
      };
  });
  var freeApply = new Control_Apply.Apply(function () {
      return freeFunctor;
  }, Control_Monad.ap(freeMonad));
  var freeApplicative = new Control_Applicative.Applicative(function () {
      return freeApply;
  }, function ($122) {
      return fromView(Return.create($122));
  });
  var liftF = function (f) {
      return fromView(new Bind(f, (function () {
          var $123 = Control_Applicative.pure(freeApplicative);
          return function ($124) {
              return $123($124);
          };
      })()));
  };
  var foldFree = function (dictMonadRec) {
      return function (k) {
          var go = function (f) {
              var v = toView(f);
              if (v instanceof Return) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(v.value0));
              };
              if (v instanceof Bind) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(function ($136) {
                      return Control_Monad_Rec_Class.Loop.create(v.value1($136));
                  })(k(v.value0));
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 158, column 10 - line 160, column 37): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  exports["liftF"] = liftF;
  exports["foldFree"] = foldFree;
  exports["freeFunctor"] = freeFunctor;
  exports["freeBind"] = freeBind;
  exports["freeApplicative"] = freeApplicative;
  exports["freeApply"] = freeApply;
  exports["freeMonad"] = freeMonad;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Query.HalogenM"] = $PS["Halogen.Query.HalogenM"] || {};
  var exports = $PS["Halogen.Query.HalogenM"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];                                          
  var SubscriptionId = function (x) {
      return x;
  };
  var ForkId = function (x) {
      return x;
  };
  var State = (function () {
      function State(value0) {
          this.value0 = value0;
      };
      State.create = function (value0) {
          return new State(value0);
      };
      return State;
  })();
  var Subscribe = (function () {
      function Subscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Subscribe.create = function (value0) {
          return function (value1) {
              return new Subscribe(value0, value1);
          };
      };
      return Subscribe;
  })();
  var Unsubscribe = (function () {
      function Unsubscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Unsubscribe.create = function (value0) {
          return function (value1) {
              return new Unsubscribe(value0, value1);
          };
      };
      return Unsubscribe;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var ChildQuery = (function () {
      function ChildQuery(value0) {
          this.value0 = value0;
      };
      ChildQuery.create = function (value0) {
          return new ChildQuery(value0);
      };
      return ChildQuery;
  })();
  var Raise = (function () {
      function Raise(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Raise.create = function (value0) {
          return function (value1) {
              return new Raise(value0, value1);
          };
      };
      return Raise;
  })();
  var Par = (function () {
      function Par(value0) {
          this.value0 = value0;
      };
      Par.create = function (value0) {
          return new Par(value0);
      };
      return Par;
  })();
  var Fork = (function () {
      function Fork(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Fork.create = function (value0) {
          return function (value1) {
              return new Fork(value0, value1);
          };
      };
      return Fork;
  })();
  var Kill = (function () {
      function Kill(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Kill.create = function (value0) {
          return function (value1) {
              return new Kill(value0, value1);
          };
      };
      return Kill;
  })();
  var GetRef = (function () {
      function GetRef(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      GetRef.create = function (value0) {
          return function (value1) {
              return new GetRef(value0, value1);
          };
      };
      return GetRef;
  })();
  var HalogenM = function (x) {
      return x;
  };
  var raise = function (o) {
      return HalogenM(Control_Monad_Free.liftF(new Raise(o, Data_Unit.unit)));
  };
  var ordSubscriptionId = Data_Ord.ordInt;
  var ordForkId = Data_Ord.ordInt;
  var monadHalogenM = Control_Monad_Free.freeMonad;
  var monadStateHalogenM = new Control_Monad_State_Class.MonadState(function () {
      return monadHalogenM;
  }, function ($139) {
      return HalogenM(Control_Monad_Free.liftF(State.create($139)));
  });
  var monadEffectHalogenM = function (dictMonadEffect) {
      return new Effect_Class.MonadEffect(function () {
          return monadHalogenM;
      }, (function () {
          var $144 = Effect_Class.liftEffect(dictMonadEffect);
          return function ($145) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($144($145))));
          };
      })());
  };
  var monadAffHalogenM = function (dictMonadAff) {
      return new Effect_Aff_Class.MonadAff(function () {
          return monadEffectHalogenM(dictMonadAff.MonadEffect0());
      }, (function () {
          var $146 = Effect_Aff_Class.liftAff(dictMonadAff);
          return function ($147) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($146($147))));
          };
      })());
  };
  var getRef = function (p) {
      return HalogenM(Control_Monad_Free.liftF(new GetRef(p, Control_Category.identity(Control_Category.categoryFn))));
  };
  var functorHalogenM = Control_Monad_Free.freeFunctor;
  var fork = function (hmu) {
      return HalogenM(Control_Monad_Free.liftF(new Fork(hmu, Control_Category.identity(Control_Category.categoryFn))));
  };                           
  var bindHalogenM = Control_Monad_Free.freeBind;
  var applyHalogenM = Control_Monad_Free.freeApply;         
  var applicativeHalogenM = Control_Monad_Free.freeApplicative;
  exports["State"] = State;
  exports["Subscribe"] = Subscribe;
  exports["Unsubscribe"] = Unsubscribe;
  exports["Lift"] = Lift;
  exports["ChildQuery"] = ChildQuery;
  exports["Raise"] = Raise;
  exports["Par"] = Par;
  exports["Fork"] = Fork;
  exports["Kill"] = Kill;
  exports["GetRef"] = GetRef;
  exports["HalogenM"] = HalogenM;
  exports["raise"] = raise;
  exports["SubscriptionId"] = SubscriptionId;
  exports["ForkId"] = ForkId;
  exports["fork"] = fork;
  exports["getRef"] = getRef;
  exports["functorHalogenM"] = functorHalogenM;
  exports["applyHalogenM"] = applyHalogenM;
  exports["applicativeHalogenM"] = applicativeHalogenM;
  exports["bindHalogenM"] = bindHalogenM;
  exports["monadEffectHalogenM"] = monadEffectHalogenM;
  exports["monadAffHalogenM"] = monadAffHalogenM;
  exports["monadStateHalogenM"] = monadStateHalogenM;
  exports["ordSubscriptionId"] = ordSubscriptionId;
  exports["ordForkId"] = ordForkId;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Time.Duration"] = $PS["Data.Time.Duration"] || {};
  var exports = $PS["Data.Time.Duration"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var semigroupMilliseconds = new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
          return v + v1;
      };
  });     
  var monoidMilliseconds = new Data_Monoid.Monoid(function () {
      return semigroupMilliseconds;
  }, 0.0);
  exports["monoidMilliseconds"] = monoidMilliseconds;
})(PS);
(function(exports) {
  /* globals exports, setTimeout */
  "use strict";

  var AVar = function () {

    function MutableQueue () {
      this.head = null;
      this.last = null;
      this.size = 0;
    }

    function MutableCell (queue, value) {
      this.queue = queue;
      this.value = value;
      this.next  = null;
      this.prev  = null;
    }

    function AVar (value) {
      this.draining = false;
      this.error    = null;
      this.value    = value;
      this.takes    = new MutableQueue();
      this.reads    = new MutableQueue();
      this.puts     = new MutableQueue();
    }

    var EMPTY = {};

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function putLast (queue, value) {
      var cell = new MutableCell(queue, value);
      switch (queue.size) {
      case 0:
        queue.head = cell;
        break;
      case 1:
        cell.prev = queue.head;
        queue.head.next = cell;
        queue.last = cell;
        break;
      default:
        cell.prev = queue.last;
        queue.last.next = cell;
        queue.last = cell;
      }
      queue.size++;
      return cell;
    }

    function takeLast (queue) {
      var cell;
      switch (queue.size) {
      case 0:
        return null;
      case 1:
        cell = queue.head;
        queue.head = null;
        break;
      case 2:
        cell = queue.last;
        queue.head.next = null;
        queue.last = null;
        break;
      default:
        cell = queue.last;
        queue.last = cell.prev;
        queue.last.next = null;
      }
      cell.prev = null;
      cell.queue = null;
      queue.size--;
      return cell.value;
    }

    function takeHead (queue) {
      var cell;
      switch (queue.size) {
      case 0:
        return null;
      case 1:
        cell = queue.head;
        queue.head = null;
        break;
      case 2:
        cell = queue.head;
        queue.last.prev = null;
        queue.head = queue.last;
        queue.last = null;
        break;
      default:
        cell = queue.head;
        queue.head = cell.next;
        queue.head.prev = null;
      }
      cell.next = null;
      cell.queue = null;
      queue.size--;
      return cell.value;
    }

    function deleteCell (cell) {
      if (cell.queue === null) {
        return;
      }
      if (cell.queue.last === cell) {
        takeLast(cell.queue);
        return;
      }
      if (cell.queue.head === cell) {
        takeHead(cell.queue);
        return;
      }
      if (cell.prev) {
        cell.prev.next = cell.next;
      }
      if (cell.next) {
        cell.next.prev = cell.prev;
      }
      cell.queue.size--;
      cell.queue = null;
      cell.value = null;
      cell.next  = null;
      cell.prev  = null;
    }

    function drainVar (util, avar) {
      if (avar.draining) {
        return;
      }

      var ps = avar.puts;
      var ts = avar.takes;
      var rs = avar.reads;
      var p, r, t, value, rsize;

      avar.draining = true;

      while (1) { // eslint-disable-line no-constant-condition
        p = null;
        r = null;
        t = null;
        value = avar.value;
        rsize = rs.size;

        if (avar.error !== null) {
          value = util.left(avar.error);
          while (p = takeHead(ps)) { // eslint-disable-line no-cond-assign
            runEff(p.cb(value));
          }
          while (r = takeHead(rs)) { // eslint-disable-line no-cond-assign
            runEff(r(value));
          }
          while (t = takeHead(ts)) { // eslint-disable-line no-cond-assign
            runEff(t(value));
          }
          break;
        }

        // Process the next put. We do not immediately invoke the callback
        // because we want to preserve ordering. If there are takes/reads
        // we want to run those first.
        if (value === EMPTY && (p = takeHead(ps))) {
          avar.value = value = p.value;
        }

        if (value !== EMPTY) {
          // We go ahead and queue up the next take for the same reasons as
          // above. Invoking the read callbacks can affect the mutable queue.
          t = takeHead(ts);
          // We only want to process the reads queued up before running these
          // callbacks so we guard on rsize.
          while (rsize-- && (r = takeHead(rs))) {
            runEff(r(util.right(value)));
          }
          if (t !== null) {
            avar.value = EMPTY;
            runEff(t(util.right(value)));
          }
        }

        if (p !== null) {
          runEff(p.cb(util.right(void 0)));
        }

        // Callbacks could have queued up more items so we need to guard on the
        // actual mutable properties.
        if (avar.value === EMPTY && ps.size === 0 || avar.value !== EMPTY && ts.size === 0) {
          break;
        }
      }
      avar.draining = false;
    }

    AVar.EMPTY      = EMPTY;
    AVar.putLast    = putLast;
    AVar.takeLast   = takeLast;
    AVar.takeHead   = takeHead;
    AVar.deleteCell = deleteCell;
    AVar.drainVar   = drainVar;

    return AVar;
  }();

  exports.empty = function () {
    return new AVar(AVar.EMPTY);
  };

  exports._killVar = function (util, error, avar) {
    return function () {
      if (avar.error === null) {
        avar.error = error;
        avar.value = AVar.EMPTY;
        AVar.drainVar(util, avar);
      }
    };
  };

  exports._putVar = function (util, value, avar, cb) {
    return function () {
      var cell = AVar.putLast(avar.puts, { cb: cb, value: value });
      AVar.drainVar(util, avar);
      return function () {
        AVar.deleteCell(cell);
      };
    };
  };

  exports._takeVar = function (util, avar, cb) {
    return function () {
      var cell = AVar.putLast(avar.takes, cb);
      AVar.drainVar(util, avar);
      return function () {
        AVar.deleteCell(cell);
      };
    };
  };
})(PS["Effect.AVar"] = PS["Effect.AVar"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.AVar"] = $PS["Effect.AVar"] || {};
  var exports = $PS["Effect.AVar"];
  var $foreign = $PS["Effect.AVar"];
  var Data_Either = $PS["Data.Either"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var Killed = (function () {
      function Killed(value0) {
          this.value0 = value0;
      };
      Killed.create = function (value0) {
          return new Killed(value0);
      };
      return Killed;
  })();
  var Filled = (function () {
      function Filled(value0) {
          this.value0 = value0;
      };
      Filled.create = function (value0) {
          return new Filled(value0);
      };
      return Filled;
  })();
  var Empty = (function () {
      function Empty() {

      };
      Empty.value = new Empty();
      return Empty;
  })();
  var ffiUtil = {
      left: Data_Either.Left.create,
      right: Data_Either.Right.create,
      nothing: Data_Maybe.Nothing.value,
      just: Data_Maybe.Just.create,
      killed: Killed.create,
      filled: Filled.create,
      empty: Empty.value
  };
  var kill = function (err) {
      return function (avar) {
          return $foreign["_killVar"](ffiUtil, err, avar);
      };
  };
  var put = function (value) {
      return function (avar) {
          return function (cb) {
              return $foreign["_putVar"](ffiUtil, value, avar, cb);
          };
      };
  };
  var take = function (avar) {
      return function (cb) {
          return $foreign["_takeVar"](ffiUtil, avar, cb);
      };
  };
  exports["take"] = take;
  exports["put"] = put;
  exports["kill"] = kill;
  exports["empty"] = $foreign.empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Aff.AVar"] = $PS["Effect.Aff.AVar"] || {};
  var exports = $PS["Effect.Aff.AVar"];
  var Effect_AVar = $PS["Effect.AVar"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var take = function (avar) {
      return Effect_Aff.makeAff(function (k) {
          return function __do() {
              var c = Effect_AVar.take(avar)(k)();
              return Effect_Aff.effectCanceler(c);
          };
      });
  };
  var put = function (value) {
      return function (avar) {
          return Effect_Aff.makeAff(function (k) {
              return function __do() {
                  var c = Effect_AVar.put(value)(avar)(k)();
                  return Effect_Aff.effectCanceler(c);
              };
          });
      };
  };   
  var kill = function (error) {
      var $11 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
      var $12 = Effect_AVar.kill(error);
      return function ($13) {
          return $11($12($13));
      };
  };
  var empty = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_AVar.empty);
  exports["empty"] = empty;
  exports["take"] = take;
  exports["put"] = put;
  exports["kill"] = kill;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Exists"] = $PS["Data.Exists"] || {};
  var exports = $PS["Data.Exists"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var runExists = Unsafe_Coerce.unsafeCoerce;
  var mkExists = Unsafe_Coerce.unsafeCoerce;
  exports["mkExists"] = mkExists;
  exports["runExists"] = runExists;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Coyoneda"] = $PS["Data.Coyoneda"] || {};
  var exports = $PS["Data.Coyoneda"];
  var Control_Category = $PS["Control.Category"];
  var Data_Exists = $PS["Data.Exists"];
  var Data_Functor = $PS["Data.Functor"];                        
  var CoyonedaF = (function () {
      function CoyonedaF(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CoyonedaF.create = function (value0) {
          return function (value1) {
              return new CoyonedaF(value0, value1);
          };
      };
      return CoyonedaF;
  })();
  var Coyoneda = function (x) {
      return x;
  };
  var unCoyoneda = function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return f(v1.value0)(v1.value1);
          })(v);
      };
  };
  var coyoneda = function (k) {
      return function (fi) {
          return Coyoneda(Data_Exists.mkExists(new CoyonedaF(k, fi)));
      };
  };
  var functorCoyoneda = new Data_Functor.Functor(function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return coyoneda(function ($85) {
                  return f(v1.value0($85));
              })(v1.value1);
          })(v);
      };
  });
  var liftCoyoneda = coyoneda(Control_Category.identity(Control_Category.categoryFn));
  exports["unCoyoneda"] = unCoyoneda;
  exports["liftCoyoneda"] = liftCoyoneda;
  exports["functorCoyoneda"] = functorCoyoneda;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Map.Internal"] = $PS["Data.Map.Internal"] || {};
  var exports = $PS["Data.Map.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];                          
  var Leaf = (function () {
      function Leaf() {

      };
      Leaf.value = new Leaf();
      return Leaf;
  })();
  var Two = (function () {
      function Two(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Two.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Two(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Two;
  })();
  var Three = (function () {
      function Three(value0, value1, value2, value3, value4, value5, value6) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
          this.value6 = value6;
      };
      Three.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return function (value6) {
                                  return new Three(value0, value1, value2, value3, value4, value5, value6);
                              };
                          };
                      };
                  };
              };
          };
      };
      return Three;
  })();
  var TwoLeft = (function () {
      function TwoLeft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoLeft(value0, value1, value2);
              };
          };
      };
      return TwoLeft;
  })();
  var TwoRight = (function () {
      function TwoRight(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoRight(value0, value1, value2);
              };
          };
      };
      return TwoRight;
  })();
  var ThreeLeft = (function () {
      function ThreeLeft(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeLeft(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeLeft;
  })();
  var ThreeMiddle = (function () {
      function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeMiddle.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeMiddle;
  })();
  var ThreeRight = (function () {
      function ThreeRight(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeRight(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeRight;
  })();
  var KickUp = (function () {
      function KickUp(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      KickUp.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new KickUp(value0, value1, value2, value3);
                  };
              };
          };
      };
      return KickUp;
  })();
  var values = function (v) {
      if (v instanceof Leaf) {
          return Data_List_Types.Nil.value;
      };
      if (v instanceof Two) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(values(v.value3)));
      };
      if (v instanceof Three) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value5))(values(v.value6)))));
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 612, column 1 - line 612, column 40): " + [ v.constructor.name ]);
  };
  var lookup = function (dictOrd) {
      return function (k) {
          var comp = Data_Ord.compare(dictOrd);
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Leaf) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Two) {
                      var v2 = comp(k)(v.value1);
                      if (v2 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      if (v2 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  if (v instanceof Three) {
                      var v3 = comp(k)(v.value1);
                      if (v3 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      var v4 = comp(k)(v.value4);
                      if (v4 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value5);
                      };
                      if (v3 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      if (v4 instanceof Data_Ordering.GT) {
                          $copy_v = v.value6;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 200, column 5 - line 200, column 22): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return go;
      };
  };
  var member = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.isJust(lookup(dictOrd)(k)(m));
          };
      };
  };
  var functorMap = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Leaf) {
              return Leaf.value;
          };
          if (v1 instanceof Two) {
              return new Two(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3));
          };
          if (v1 instanceof Three) {
              return new Three(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3), v1.value4, v(v1.value5), Data_Functor.map(functorMap)(v)(v1.value6));
          };
          throw new Error("Failed pattern match at Data.Map.Internal (line 96, column 1 - line 99, column 110): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var fromZipper = function ($copy_dictOrd) {
      return function ($copy_v) {
          return function ($copy_tree) {
              var $tco_var_dictOrd = $copy_dictOrd;
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(dictOrd, v, tree) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return tree;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof TwoLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
                          return;
                      };
                      if (v.value0 instanceof TwoRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
                          return;
                      };
                      if (v.value0 instanceof ThreeLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeMiddle) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 418, column 3 - line 423, column 88): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 415, column 1 - line 415, column 80): " + [ v.constructor.name, tree.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
              };
              return $tco_result;
          };
      };
  };
  var insert = function (dictOrd) {
      return function (k) {
          return function (v) {
              var up = function ($copy_v1) {
                  return function ($copy_v2) {
                      var $tco_var_v1 = $copy_v1;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(v1, v2) {
                          if (v1 instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
                          };
                          if (v1 instanceof Data_List_Types.Cons) {
                              if (v1.value0 instanceof TwoLeft) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v2.value0, v2.value1, v2.value2, v2.value3, v1.value0.value0, v1.value0.value1, v1.value0.value2));
                              };
                              if (v1.value0 instanceof TwoRight) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0, v2.value1, v2.value2, v2.value3));
                              };
                              if (v1.value0 instanceof ThreeLeft) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v2.value0, v2.value1, v2.value2, v2.value3), v1.value0.value0, v1.value0.value1, new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeMiddle) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0), v2.value1, v2.value2, new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeRight) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3), v1.value0.value4, v1.value0.value5, new Two(v2.value0, v2.value1, v2.value2, v2.value3));
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.Map.Internal (line 454, column 5 - line 459, column 108): " + [ v1.value0.constructor.name, v2.constructor.name ]);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 451, column 3 - line 451, column 56): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                      };
                      return $tco_result;
                  };
              };
              var comp = Data_Ord.compare(dictOrd);
              var down = function ($copy_ctx) {
                  return function ($copy_v1) {
                      var $tco_var_ctx = $copy_ctx;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(ctx, v1) {
                          if (v1 instanceof Leaf) {
                              $tco_done = true;
                              return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
                          };
                          if (v1 instanceof Two) {
                              var v2 = comp(k)(v1.value1);
                              if (v2 instanceof Data_Ordering.EQ) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                              };
                              if (v2 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                              $copy_v1 = v1.value3;
                              return;
                          };
                          if (v1 instanceof Three) {
                              var v3 = comp(k)(v1.value1);
                              if (v3 instanceof Data_Ordering.EQ) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                              };
                              var v4 = comp(k)(v1.value4);
                              if (v4 instanceof Data_Ordering.EQ) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                              };
                              if (v3 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              if (v3 instanceof Data_Ordering.GT && v4 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value3;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                              $copy_v1 = v1.value6;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 434, column 3 - line 434, column 55): " + [ ctx.constructor.name, v1.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
                      };
                      return $tco_result;
                  };
              };
              return down(Data_List_Types.Nil.value);
          };
      };
  };
  var pop = function (dictOrd) {
      return function (k) {
          var up = function ($copy_ctxs) {
              return function ($copy_tree) {
                  var $tco_var_ctxs = $copy_ctxs;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctxs, tree) {
                      if (ctxs instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return tree;
                      };
                      if (ctxs instanceof Data_List_Types.Cons) {
                          if (ctxs.value0 instanceof TwoLeft && (ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoRight && (ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && (ctxs.value0.value2 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeRight && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 511, column 9 - line 528, column 136): " + [ ctxs.value0.constructor.name, tree.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 508, column 5 - line 528, column 136): " + [ ctxs.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
                  };
                  return $tco_result;
              };
          };
          var removeMaxNode = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Two && (m.value0 instanceof Leaf && m.value3 instanceof Leaf)) {
                          $tco_done = true;
                          return up(ctx)(Leaf.value);
                      };
                      if (m instanceof Two) {
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three && (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf))) {
                          $tco_done = true;
                          return up(new Data_List_Types.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
                      };
                      if (m instanceof Three) {
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 540, column 5 - line 544, column 107): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          var maxNode = function ($copy_m) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(m) {
                  if (m instanceof Two && m.value3 instanceof Leaf) {
                      $tco_done = true;
                      return {
                          key: m.value1,
                          value: m.value2
                      };
                  };
                  if (m instanceof Two) {
                      $copy_m = m.value3;
                      return;
                  };
                  if (m instanceof Three && m.value6 instanceof Leaf) {
                      $tco_done = true;
                      return {
                          key: m.value4,
                          value: m.value5
                      };
                  };
                  if (m instanceof Three) {
                      $copy_m = m.value6;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 531, column 33 - line 535, column 45): " + [ m.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_m);
              };
              return $tco_result;
          };
          var comp = Data_Ord.compare(dictOrd);
          var down = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Leaf) {
                          $tco_done = true;
                          return Data_Maybe.Nothing.value;
                      };
                      if (m instanceof Two) {
                          var v = comp(k)(m.value1);
                          if (m.value3 instanceof Leaf && v instanceof Data_Ordering.EQ) {
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three) {
                          var leaves = (function () {
                              if (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf)) {
                                  return true;
                              };
                              return false;
                          })();
                          var v = comp(k)(m.value4);
                          var v3 = comp(k)(m.value1);
                          if (leaves && v3 instanceof Data_Ordering.EQ) {
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
                          };
                          if (leaves && v instanceof Data_Ordering.EQ) {
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
                          };
                          if (v3 instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value3);
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
                          };
                          if (v3 instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          if (v3 instanceof Data_Ordering.GT && v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value3;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 481, column 34 - line 504, column 80): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          return down(Data_List_Types.Nil.value);
      };
  };
  var foldableMap = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return function (m) {
              return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  });
  var empty = Leaf.value;
  var $$delete = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
          };
      };
  }; 
  var alter = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (m) {
                  var v = f(lookup(dictOrd)(k)(m));
                  if (v instanceof Data_Maybe.Nothing) {
                      return $$delete(dictOrd)(k)(m);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return insert(dictOrd)(k)(v.value0)(m);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 549, column 15 - line 551, column 25): " + [ v.constructor.name ]);
              };
          };
      };
  };
  exports["empty"] = empty;
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["delete"] = $$delete;
  exports["pop"] = pop;
  exports["member"] = member;
  exports["alter"] = alter;
  exports["functorMap"] = functorMap;
  exports["foldableMap"] = foldableMap;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Data.OrdBox"] = $PS["Halogen.Data.OrdBox"] || {};
  var exports = $PS["Halogen.Data.OrdBox"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ord = $PS["Data.Ord"];                
  var OrdBox = (function () {
      function OrdBox(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      OrdBox.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new OrdBox(value0, value1, value2);
              };
          };
      };
      return OrdBox;
  })();
  var mkOrdBox = function (dictOrd) {
      return OrdBox.create(Data_Eq.eq(dictOrd.Eq0()))(Data_Ord.compare(dictOrd));
  };
  var eqOrdBox = new Data_Eq.Eq(function (v) {
      return function (v1) {
          return v.value0(v.value2)(v1.value2);
      };
  });
  var ordOrdBox = new Data_Ord.Ord(function () {
      return eqOrdBox;
  }, function (v) {
      return function (v1) {
          return v.value1(v.value2)(v1.value2);
      };
  });
  exports["mkOrdBox"] = mkOrdBox;
  exports["ordOrdBox"] = ordOrdBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Data.Slot"] = $PS["Halogen.Data.Slot"] || {};
  var exports = $PS["Halogen.Data.Slot"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_Data_OrdBox = $PS["Halogen.Data.OrdBox"];
  var pop = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.pop(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var lookup = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (val) {
                          return function (v) {
                              return Data_Map_Internal.insert(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(val)(v);
                          };
                      };
                  };
              };
          };
      };
  };
  var foreachSlot = function (dictApplicative) {
      return function (v) {
          return function (k) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Map_Internal.foldableMap)(function ($37) {
                  return k($37);
              })(v);
          };
      };
  };
  var empty = Data_Map_Internal.empty;
  exports["empty"] = empty;
  exports["lookup"] = lookup;
  exports["insert"] = insert;
  exports["pop"] = pop;
  exports["foreachSlot"] = foreachSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Query.HalogenQ"] = $PS["Halogen.Query.HalogenQ"] || {};
  var exports = $PS["Halogen.Query.HalogenQ"];           
  var Initialize = (function () {
      function Initialize(value0) {
          this.value0 = value0;
      };
      Initialize.create = function (value0) {
          return new Initialize(value0);
      };
      return Initialize;
  })();
  var Finalize = (function () {
      function Finalize(value0) {
          this.value0 = value0;
      };
      Finalize.create = function (value0) {
          return new Finalize(value0);
      };
      return Finalize;
  })();
  var Receive = (function () {
      function Receive(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Receive.create = function (value0) {
          return function (value1) {
              return new Receive(value0, value1);
          };
      };
      return Receive;
  })();
  var Action = (function () {
      function Action(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Action.create = function (value0) {
          return function (value1) {
              return new Action(value0, value1);
          };
      };
      return Action;
  })();
  var Query = (function () {
      function Query(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Query.create = function (value0) {
          return function (value1) {
              return new Query(value0, value1);
          };
      };
      return Query;
  })();
  exports["Initialize"] = Initialize;
  exports["Finalize"] = Finalize;
  exports["Receive"] = Receive;
  exports["Action"] = Action;
  exports["Query"] = Query;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Component"] = $PS["Halogen.Component"] || {};
  var exports = $PS["Halogen.Component"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ComponentSlot = (function () {
      function ComponentSlot(value0) {
          this.value0 = value0;
      };
      ComponentSlot.create = function (value0) {
          return new ComponentSlot(value0);
      };
      return ComponentSlot;
  })();
  var ThunkSlot = (function () {
      function ThunkSlot(value0) {
          this.value0 = value0;
      };
      ThunkSlot.create = function (value0) {
          return new ThunkSlot(value0);
      };
      return ThunkSlot;
  })();
  var unComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var unComponent = Unsafe_Coerce.unsafeCoerce;
  var mkEval = function (args) {
      return function (v) {
          if (v instanceof Halogen_Query_HalogenQ.Initialize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.initialize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Finalize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.finalize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Receive) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.receive(v.value0)))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Action) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(args.handleAction(v.value0))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Query) {
              return Data_Coyoneda.unCoyoneda(function (g) {
                  var $28 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(Data_Maybe.maybe(v.value1(Data_Unit.unit))(g));
                  return function ($29) {
                      return $28(args.handleQuery($29));
                  };
              })(v.value0);
          };
          throw new Error("Failed pattern match at Halogen.Component (line 187, column 15 - line 197, column 70): " + [ v.constructor.name ]);
      };
  };
  var mkComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var mkComponent = Unsafe_Coerce.unsafeCoerce;
  var componentSlot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (comp) {
                          return function (input) {
                              return function (output) {
                                  return mkComponentSlot({
                                      get: Halogen_Data_Slot.lookup()(dictIsSymbol)(dictOrd)(label)(p),
                                      pop: Halogen_Data_Slot.pop()(dictIsSymbol)(dictOrd)(label)(p),
                                      set: Halogen_Data_Slot.insert()(dictIsSymbol)(dictOrd)(label)(p),
                                      component: comp,
                                      input: input,
                                      output: output
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["mkComponent"] = mkComponent;
  exports["unComponent"] = unComponent;
  exports["mkEval"] = mkEval;
  exports["ComponentSlot"] = ComponentSlot;
  exports["ThunkSlot"] = ThunkSlot;
  exports["componentSlot"] = componentSlot;
  exports["unComponentSlot"] = unComponentSlot;
})(PS);
(function(exports) {
  "use strict";

  exports._read = function (nothing, just, value) {
    var tag = Object.prototype.toString.call(value);
    if (tag.indexOf("[object HTML") === 0 && tag.indexOf("Element]") === tag.length - 8) {
      return just(value);
    } else {
      return nothing;
    }
  };

  exports.focus = function (elt) {
    return function () {
      return elt.focus();
    };
  };

  exports.blur = function (elt) {
    return function () {
      return elt.blur();
    };
  };
})(PS["Web.HTML.HTMLElement"] = PS["Web.HTML.HTMLElement"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.HTML.HTMLElement"] = $PS["Web.HTML.HTMLElement"] || {};
  var exports = $PS["Web.HTML.HTMLElement"];
  var $foreign = $PS["Web.HTML.HTMLElement"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;       
  var toElement = Unsafe_Coerce.unsafeCoerce;
  var fromElement = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  exports["fromElement"] = fromElement;
  exports["toElement"] = toElement;
  exports["toNode"] = toNode;
  exports["focus"] = $foreign.focus;
  exports["blur"] = $foreign.blur;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Query"] = $PS["Halogen.Query"] || {};
  var exports = $PS["Halogen.Query"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var getHTMLElementRef = (function () {
      var $1 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(function (v) {
          return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(v);
      });
      return function ($2) {
          return $1(Halogen_Query_HalogenM.getRef($2));
      };
  })();
  exports["getHTMLElementRef"] = getHTMLElementRef;
})(PS);
(function(exports) {
  "use strict";

  exports.copyRecord = function(rec) {
    var copy = {};
    for (var key in rec) {
      if ({}.hasOwnProperty.call(rec, key)) {
        copy[key] = rec[key];
      }
    }
    return copy;
  };

  exports.unsafeInsert = function(l) {
    return function(a) {
      return function(rec) {
        rec[l] = a;
        return rec;
      };
    };
  };

  exports.unsafeModify = function(l) {
    return function (f) {
      return function(rec) {
        rec[l] = f(rec[l]);
        return rec;
      };
    };
  };
})(PS["Record.Builder"] = PS["Record.Builder"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Record.Builder"] = $PS["Record.Builder"] || {};
  var exports = $PS["Record.Builder"];
  var $foreign = $PS["Record.Builder"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Symbol = $PS["Data.Symbol"];
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
  var modify = function (dictCons) {
      return function (dictCons1) {
          return function (dictIsSymbol) {
              return function (l) {
                  return function (f) {
                      return function (r1) {
                          return $foreign.unsafeModify(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(f)(r1);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictLacks) {
          return function (dictIsSymbol) {
              return function (l) {
                  return function (a) {
                      return function (r1) {
                          return $foreign.unsafeInsert(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r1);
                      };
                  };
              };
          };
      };
  };                                                
  var build = function (v) {
      return function (r1) {
          return v($foreign.copyRecord(r1));
      };
  };
  exports["build"] = build;
  exports["insert"] = insert;
  exports["modify"] = modify;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
})(PS);
(function(exports) {
  "use strict";

  exports._currentTarget = function (e) {
    return e.currentTarget;
  };

  exports.preventDefault = function (e) {
    return function () {
      return e.preventDefault();
    };
  };
})(PS["Web.Event.Event"] = PS["Web.Event.Event"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.Event.Event"] = $PS["Web.Event.Event"] || {};
  var exports = $PS["Web.Event.Event"];
  var $foreign = $PS["Web.Event.Event"];
  var Data_Nullable = $PS["Data.Nullable"];
  var currentTarget = function ($8) {
      return Data_Nullable.toMaybe($foreign["_currentTarget"]($8));
  };
  exports["currentTarget"] = currentTarget;
  exports["preventDefault"] = $foreign.preventDefault;
})(PS);
(function(exports) {
  "use strict";

  exports.key = function (e) {
    return e.key;
  };
})(PS["Web.UIEvent.KeyboardEvent"] = PS["Web.UIEvent.KeyboardEvent"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.UIEvent.KeyboardEvent"] = $PS["Web.UIEvent.KeyboardEvent"] || {};
  var exports = $PS["Web.UIEvent.KeyboardEvent"];
  var $foreign = $PS["Web.UIEvent.KeyboardEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];  
  var toEvent = Unsafe_Coerce.unsafeCoerce;
  exports["toEvent"] = toEvent;
  exports["key"] = $foreign.key;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.UIEvent.MouseEvent"] = $PS["Web.UIEvent.MouseEvent"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];  
  var toEvent = Unsafe_Coerce.unsafeCoerce;
  exports["toEvent"] = toEvent;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Select"] = $PS["Select"] || {};
  var exports = $PS["Select"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Time_Duration = $PS["Data.Time.Duration"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_AVar = $PS["Effect.Aff.AVar"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Record_Builder = $PS["Record.Builder"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_UIEvent_KeyboardEvent = $PS["Web.UIEvent.KeyboardEvent"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var Off = (function () {
      function Off() {

      };
      Off.value = new Off();
      return Off;
  })();
  var On = (function () {
      function On() {

      };
      On.value = new On();
      return On;
  })();
  var Prev = (function () {
      function Prev() {

      };
      Prev.value = new Prev();
      return Prev;
  })();
  var Next = (function () {
      function Next() {

      };
      Next.value = new Next();
      return Next;
  })();
  var Index = (function () {
      function Index(value0) {
          this.value0 = value0;
      };
      Index.create = function (value0) {
          return new Index(value0);
      };
      return Index;
  })();
  var Send = (function () {
      function Send(value0) {
          this.value0 = value0;
      };
      Send.create = function (value0) {
          return new Send(value0);
      };
      return Send;
  })();
  var Query = (function () {
      function Query(value0) {
          this.value0 = value0;
      };
      Query.create = function (value0) {
          return new Query(value0);
      };
      return Query;
  })();
  var Text = (function () {
      function Text() {

      };
      Text.value = new Text();
      return Text;
  })();
  var Toggle = (function () {
      function Toggle() {

      };
      Toggle.value = new Toggle();
      return Toggle;
  })();
  var Searched = (function () {
      function Searched(value0) {
          this.value0 = value0;
      };
      Searched.create = function (value0) {
          return new Searched(value0);
      };
      return Searched;
  })();
  var Selected = (function () {
      function Selected(value0) {
          this.value0 = value0;
      };
      Selected.create = function (value0) {
          return new Selected(value0);
      };
      return Selected;
  })();
  var VisibilityChanged = (function () {
      function VisibilityChanged(value0) {
          this.value0 = value0;
      };
      VisibilityChanged.create = function (value0) {
          return new VisibilityChanged(value0);
      };
      return VisibilityChanged;
  })();
  var Search = (function () {
      function Search(value0) {
          this.value0 = value0;
      };
      Search.create = function (value0) {
          return new Search(value0);
      };
      return Search;
  })();
  var Highlight = (function () {
      function Highlight(value0) {
          this.value0 = value0;
      };
      Highlight.create = function (value0) {
          return new Highlight(value0);
      };
      return Highlight;
  })();
  var Select = (function () {
      function Select(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Select.create = function (value0) {
          return function (value1) {
              return new Select(value0, value1);
          };
      };
      return Select;
  })();
  var ToggleClick = (function () {
      function ToggleClick(value0) {
          this.value0 = value0;
      };
      ToggleClick.create = function (value0) {
          return new ToggleClick(value0);
      };
      return ToggleClick;
  })();
  var Focus = (function () {
      function Focus(value0) {
          this.value0 = value0;
      };
      Focus.create = function (value0) {
          return new Focus(value0);
      };
      return Focus;
  })();
  var Key = (function () {
      function Key(value0) {
          this.value0 = value0;
      };
      Key.create = function (value0) {
          return new Key(value0);
      };
      return Key;
  })();
  var PreventClick = (function () {
      function PreventClick(value0) {
          this.value0 = value0;
      };
      PreventClick.create = function (value0) {
          return new PreventClick(value0);
      };
      return PreventClick;
  })();
  var SetVisibility = (function () {
      function SetVisibility(value0) {
          this.value0 = value0;
      };
      SetVisibility.create = function (value0) {
          return new SetVisibility(value0);
      };
      return SetVisibility;
  })();
  var Initialize = (function () {
      function Initialize(value0) {
          this.value0 = value0;
      };
      Initialize.create = function (value0) {
          return new Initialize(value0);
      };
      return Initialize;
  })();
  var Action = (function () {
      function Action(value0) {
          this.value0 = value0;
      };
      Action.create = function (value0) {
          return new Action(value0);
      };
      return Action;
  })();
  var handleQuery = function (dictMonadAff) {
      return function (handleQuery$prime) {
          return function (v) {
              if (v instanceof Send) {
                  return Halogen_Query_HalogenM.HalogenM(Control_Monad_Free.liftF(new Halogen_Query_HalogenM.ChildQuery(v.value0)));
              };
              if (v instanceof Query) {
                  return handleQuery$prime(v.value0);
              };
              throw new Error("Failed pattern match at Select (line 216, column 28 - line 221, column 23): " + [ v.constructor.name ]);
          };
      };
  };
  var eqVisibility = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Off && y instanceof Off) {
              return true;
          };
          if (x instanceof On && y instanceof On) {
              return true;
          };
          return false;
      };
  });
  var handleAction = function (dictMonadAff) {
      return function (dictLacks) {
          return function (dictLacks1) {
              return function (dictLacks2) {
                  return function (handleAction$prime) {
                      return function (handleEvent) {
                          var handle = function (act) {
                              return handleAction(dictMonadAff)()()()(handleAction$prime)(handleEvent)(act);
                          };
                          var getTargetIndex = function (st) {
                              var lastIndex = function ($79) {
                                  return (function (v) {
                                      return v - 1 | 0;
                                  })(st.getItemCount($79));
                              };
                              return function (v) {
                                  if (v instanceof Index) {
                                      return v.value0;
                                  };
                                  if (v instanceof Prev) {
                                      if (st.highlightedIndex instanceof Data_Maybe.Just && st.highlightedIndex.value0 !== 0) {
                                          return st.highlightedIndex.value0 - 1 | 0;
                                      };
                                      return lastIndex(st);
                                  };
                                  if (v instanceof Next) {
                                      if (st.highlightedIndex instanceof Data_Maybe.Just && st.highlightedIndex.value0 !== lastIndex(st)) {
                                          return st.highlightedIndex.value0 + 1 | 0;
                                      };
                                      return 0;
                                  };
                                  throw new Error("Failed pattern match at Select (line 339, column 23 - line 346, column 13): " + [ v.constructor.name ]);
                              };
                          };
                          return function (v) {
                              if (v instanceof Initialize) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Effect_Ref["new"](Data_Maybe.Nothing.value)))(function (ref) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                          var $39 = {};
                                          for (var $40 in v1) {
                                              if ({}.hasOwnProperty.call(v1, $40)) {
                                                  $39[$40] = v1[$40];
                                              };
                                          };
                                          $39.debounceRef = new Data_Maybe.Just(ref);
                                          return $39;
                                      }))(function () {
                                          return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(v.value0)(handleAction$prime);
                                      });
                                  });
                              };
                              if (v instanceof Search) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Functor.map(Effect.functorEffect)(Control_Bind.join(Data_Maybe.bindMaybe))(Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(Effect_Ref.read)(st.debounceRef))))(function (ref) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                              var $43 = {};
                                              for (var $44 in v1) {
                                                  if ({}.hasOwnProperty.call(v1, $44)) {
                                                      $43[$44] = v1[$44];
                                                  };
                                              };
                                              $43.search = v.value0;
                                              return $43;
                                          }))(function () {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handle(new SetVisibility(On.value)))))(function () {
                                                  if (st.inputType instanceof Text && ref instanceof Data_Maybe.Nothing) {
                                                      return Data_Functor.voidRight(Halogen_Query_HalogenM.functorHalogenM)(Data_Unit.unit)(Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff_AVar.empty))(function ($$var) {
                                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff.forkAff(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(st.debounceTime))(function () {
                                                              return Effect_Aff_AVar.put(Data_Unit.unit)($$var);
                                                          }))))(function (fiber) {
                                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff_AVar.take($$var))))(function () {
                                                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Ref.write(Data_Maybe.Nothing.value))(st.debounceRef))))(function () {
                                                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                                                          var $48 = {};
                                                                          for (var $49 in v1) {
                                                                              if ({}.hasOwnProperty.call(v1, $49)) {
                                                                                  $48[$49] = v1[$49];
                                                                              };
                                                                          };
                                                                          $48.highlightedIndex = new Data_Maybe.Just(0);
                                                                          return $48;
                                                                      }))(function () {
                                                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (newState) {
                                                                              return handleEvent(new Searched(newState.search));
                                                                          });
                                                                      });
                                                                  });
                                                              }))))(function () {
                                                                  return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Ref.write(new Data_Maybe.Just({
                                                                      "var": $$var,
                                                                      fiber: fiber
                                                                  })))(st.debounceRef)));
                                                              });
                                                          });
                                                      }));
                                                  };
                                                  if (st.inputType instanceof Text && ref instanceof Data_Maybe.Just) {
                                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff.killFiber(Effect_Exception.error("Time's up!"))(ref.value0.fiber))))(function () {
                                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff.forkAff(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(st.debounceTime))(function () {
                                                              return Effect_Aff_AVar.put(Data_Unit.unit)(ref["value0"]["var"]);
                                                          }))))(function (fiber) {
                                                              return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Ref.write(new Data_Maybe.Just({
                                                                  "var": ref["value0"]["var"],
                                                                  fiber: fiber
                                                              })))(st.debounceRef)));
                                                          });
                                                      });
                                                  };
                                                  return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                                              });
                                          });
                                      });
                                  });
                              };
                              if (v instanceof Highlight) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                      return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.eq(eqVisibility)(st.visibility)(On.value))(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                          var $53 = {};
                                          for (var $54 in v1) {
                                              if ({}.hasOwnProperty.call(v1, $54)) {
                                                  $53[$54] = v1[$54];
                                              };
                                          };
                                          $53.highlightedIndex = Data_Maybe.Just.create(getTargetIndex(st)(v.value0));
                                          return $53;
                                      }));
                                  });
                              };
                              if (v instanceof Select) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(v.value1)((function () {
                                      var $80 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()));
                                      return function ($81) {
                                          return $80(Web_Event_Event.preventDefault(Web_UIEvent_MouseEvent.toEvent($81)));
                                      };
                                  })()))(function () {
                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                          return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.eq(eqVisibility)(st.visibility)(On.value))((function () {
                                              if (v.value0 instanceof Index) {
                                                  return handleEvent(new Selected(v.value0.value0));
                                              };
                                              if (v.value0 instanceof Next) {
                                                  return handleEvent(Selected.create(getTargetIndex(st)(v.value0)));
                                              };
                                              if (v.value0 instanceof Prev) {
                                                  return handleEvent(Selected.create(getTargetIndex(st)(v.value0)));
                                              };
                                              throw new Error("Failed pattern match at Select (line 283, column 32 - line 286, column 64): " + [ v.value0.constructor.name ]);
                                          })());
                                      });
                                  });
                              };
                              if (v instanceof ToggleClick) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_MouseEvent.toEvent(v.value0))))(function () {
                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                          if (st.visibility instanceof On) {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(handle(new Focus(false)))(function () {
                                                  return handle(new SetVisibility(Off.value));
                                              });
                                          };
                                          if (st.visibility instanceof Off) {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(handle(new Focus(true)))(function () {
                                                  return handle(new SetVisibility(On.value));
                                              });
                                          };
                                          throw new Error("Failed pattern match at Select (line 291, column 5 - line 297, column 34): " + [ st.visibility.constructor.name ]);
                                      });
                                  });
                              };
                              if (v instanceof Focus) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query.getHTMLElementRef("select-input"))(function (inputElement) {
                                      return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(inputElement)(function (el) {
                                          return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))((function () {
                                              if (v.value0) {
                                                  return Web_HTML_HTMLElement.focus(el);
                                              };
                                              return Web_HTML_HTMLElement.blur(el);
                                          })());
                                      });
                                  });
                              };
                              if (v instanceof Key) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.fork(handle(new SetVisibility(On.value)))))(function () {
                                      var preventIt = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent(v.value0)));
                                      var v1 = Web_UIEvent_KeyboardEvent.key(v.value0);
                                      if (v1 === "ArrowUp" || v1 === "Up") {
                                          return Control_Apply.applySecond(Halogen_Query_HalogenM.applyHalogenM)(preventIt)(handle(new Highlight(Prev.value)));
                                      };
                                      if (v1 === "ArrowDown" || v1 === "Down") {
                                          return Control_Apply.applySecond(Halogen_Query_HalogenM.applyHalogenM)(preventIt)(handle(new Highlight(Next.value)));
                                      };
                                      if (v1 === "Escape" || v1 === "Esc") {
                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query.getHTMLElementRef("select-input"))(function (inputElement) {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(preventIt)(function () {
                                                  return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(inputElement)((function () {
                                                      var $82 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()));
                                                      return function ($83) {
                                                          return $82(Web_HTML_HTMLElement.blur($83));
                                                      };
                                                  })());
                                              });
                                          });
                                      };
                                      if (v1 === "Enter") {
                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(preventIt)(function () {
                                                  return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(st.highlightedIndex)(function (ix) {
                                                      return handle(new Select(new Index(ix), Data_Maybe.Nothing.value));
                                                  });
                                              });
                                          });
                                      };
                                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                                  });
                              };
                              if (v instanceof PreventClick) {
                                  return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Web_Event_Event.preventDefault(Web_UIEvent_MouseEvent.toEvent(v.value0)));
                              };
                              if (v instanceof SetVisibility) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                      return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.notEq(eqVisibility)(st.visibility)(v.value0))(Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v2) {
                                          var $68 = {};
                                          for (var $69 in v2) {
                                              if ({}.hasOwnProperty.call(v2, $69)) {
                                                  $68[$69] = v2[$69];
                                              };
                                          };
                                          $68.visibility = v.value0;
                                          $68.highlightedIndex = new Data_Maybe.Just(0);
                                          return $68;
                                      }))(function () {
                                          return handleEvent(new VisibilityChanged(v.value0));
                                      }));
                                  });
                              };
                              if (v instanceof Action) {
                                  return handleAction$prime(v.value0);
                              };
                              throw new Error("Failed pattern match at Select (line 233, column 42 - line 333, column 34): " + [ v.constructor.name ]);
                          };
                      };
                  };
              };
          };
      };
  }; 
  var defaultSpec = {
      render: Data_Function["const"](Halogen_HTML_Core.text(Data_Monoid.mempty(Data_Monoid.monoidString))),
      handleAction: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      handleQuery: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value)),
      handleEvent: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      receive: Data_Function["const"](Data_Maybe.Nothing.value),
      initialize: Data_Maybe.Nothing.value,
      finalize: Data_Maybe.Nothing.value
  };
  var component = function (dictMonadAff) {
      return function (dictLacks) {
          return function (dictLacks1) {
              return function (dictLacks2) {
                  return function (mkInput) {
                      return function (spec) {
                          var initialState = (function () {
                              var pipeline = Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.modify()()(new Data_Symbol.IsSymbol(function () {
                                  return "search";
                              }))(Data_Symbol.SProxy.value)(Data_Maybe.fromMaybe("")))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.modify()()(new Data_Symbol.IsSymbol(function () {
                                  return "debounceTime";
                              }))(Data_Symbol.SProxy.value)(Data_Maybe.fromMaybe(Data_Monoid.mempty(Data_Time_Duration.monoidMilliseconds))))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                  return "debounceRef";
                              }))(Data_Symbol.SProxy.value)(Data_Maybe.Nothing.value))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                  return "visibility";
                              }))(Data_Symbol.SProxy.value)(Off.value))(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                  return "highlightedIndex";
                              }))(Data_Symbol.SProxy.value)(Data_Maybe.Nothing.value)))));
                              return Record_Builder.build(pipeline);
                          })();
                          return Halogen_Component.mkComponent({
                              initialState: function ($84) {
                                  return initialState(mkInput($84));
                              },
                              render: spec.render,
                              "eval": Halogen_Component.mkEval({
                                  handleQuery: handleQuery(dictMonadAff)(spec.handleQuery),
                                  handleAction: handleAction(dictMonadAff)()()()(spec.handleAction)(spec.handleEvent),
                                  initialize: new Data_Maybe.Just(new Initialize(spec.initialize)),
                                  receive: (function () {
                                      var $85 = Data_Functor.map(Data_Maybe.functorMaybe)(Action.create);
                                      return function ($86) {
                                          return $85(spec.receive($86));
                                      };
                                  })(),
                                  finalize: Data_Functor.map(Data_Maybe.functorMaybe)(Action.create)(spec.finalize)
                              })
                          });
                      };
                  };
              };
          };
      };
  };
  exports["Search"] = Search;
  exports["Highlight"] = Highlight;
  exports["Select"] = Select;
  exports["Key"] = Key;
  exports["PreventClick"] = PreventClick;
  exports["SetVisibility"] = SetVisibility;
  exports["Action"] = Action;
  exports["Searched"] = Searched;
  exports["Selected"] = Selected;
  exports["VisibilityChanged"] = VisibilityChanged;
  exports["Index"] = Index;
  exports["Off"] = Off;
  exports["On"] = On;
  exports["Text"] = Text;
  exports["Toggle"] = Toggle;
  exports["defaultSpec"] = defaultSpec;
  exports["component"] = component;
  exports["eqVisibility"] = eqVisibility;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeReadPropImpl = function (f, s, key, value) {
    return value == null ? f : s(value[key]);
  };
})(PS["Foreign.Index"] = PS["Foreign.Index"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Foreign.Index"] = $PS["Foreign.Index"] || {};
  var exports = $PS["Foreign.Index"];
  var $foreign = $PS["Foreign.Index"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Identity = $PS["Data.Identity"];
  var Foreign = $PS["Foreign"];
  var unsafeReadProp = function (k) {
      return function (value) {
          return $foreign.unsafeReadPropImpl(Foreign.fail(new Foreign.TypeMismatch("object", Foreign.typeOf(value))), Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)), k, value);
      };
  };
  var readProp = unsafeReadProp;
  exports["readProp"] = readProp;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.HTML.Event.EventTypes"] = $PS["Web.HTML.Event.EventTypes"] || {};
  var exports = $PS["Web.HTML.Event.EventTypes"];
  var input = "input";
  var domcontentloaded = "DOMContentLoaded";
  var blur = "blur";
  exports["blur"] = blur;
  exports["domcontentloaded"] = domcontentloaded;
  exports["input"] = input;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.UIEvent.FocusEvent.EventTypes"] = $PS["Web.UIEvent.FocusEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.FocusEvent.EventTypes"];
  var focus = "focus";
  exports["focus"] = focus;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.UIEvent.KeyboardEvent.EventTypes"] = $PS["Web.UIEvent.KeyboardEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];
  var keydown = "keydown";
  exports["keydown"] = keydown;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.UIEvent.MouseEvent.EventTypes"] = $PS["Web.UIEvent.MouseEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseover = "mouseover";  
  var mousedown = "mousedown";
  exports["mousedown"] = mousedown;
  exports["mouseover"] = mouseover;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.HTML.Events"] = $PS["Halogen.HTML.Events"] || {};
  var exports = $PS["Halogen.HTML.Events"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign = $PS["Foreign"];
  var Foreign_Index = $PS["Foreign.Index"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_UIEvent_FocusEvent_EventTypes = $PS["Web.UIEvent.FocusEvent.EventTypes"];
  var Web_UIEvent_KeyboardEvent_EventTypes = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];
  var Web_UIEvent_MouseEvent_EventTypes = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseHandler = Unsafe_Coerce.unsafeCoerce;
  var keyHandler = Unsafe_Coerce.unsafeCoerce;
  var handler = function (et) {
      var $1 = Halogen_HTML_Core.handler(et);
      var $2 = Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Halogen_Query_Input.Action.create));
      return function ($3) {
          return $1($2($3));
      };
  };                                                         
  var onKeyDown = (function () {
      var $12 = handler(Web_UIEvent_KeyboardEvent_EventTypes.keydown);
      return function ($13) {
          return $12(keyHandler($13));
      };
  })();                                                
  var onMouseDown = (function () {
      var $16 = handler(Web_UIEvent_MouseEvent_EventTypes.mousedown);
      return function ($17) {
          return $16(mouseHandler($17));
      };
  })();
  var onMouseOver = (function () {
      var $26 = handler(Web_UIEvent_MouseEvent_EventTypes.mouseover);
      return function ($27) {
          return $26(mouseHandler($27));
      };
  })();
  var focusHandler = Unsafe_Coerce.unsafeCoerce;
  var onBlur = (function () {
      var $44 = handler(Web_HTML_Event_EventTypes.blur);
      return function ($45) {
          return $44(focusHandler($45));
      };
  })();
  var onFocus = (function () {
      var $46 = handler(Web_UIEvent_FocusEvent_EventTypes.focus);
      return function ($47) {
          return $46(focusHandler($47));
      };
  })();
  var addForeignPropHandler = function (key) {
      return function (prop) {
          return function (reader) {
              return function (f) {
                  var go = function (a) {
                      return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(reader)(Foreign_Index.readProp(prop))(Foreign.unsafeToForeign(a));
                  };
                  return handler(key)(Control_Bind.composeKleisli(Data_Maybe.bindMaybe)(Web_Event_Event.currentTarget)(function (e) {
                      return Data_Either.either(Data_Function["const"](Data_Maybe.Nothing.value))(f)(Control_Monad_Except.runExcept(go(e)));
                  }));
              };
          };
      };
  };                                                                                                       
  var onValueInput = addForeignPropHandler(Web_HTML_Event_EventTypes.input)("value")(Foreign.readString);
  exports["onMouseDown"] = onMouseDown;
  exports["onMouseOver"] = onMouseOver;
  exports["onKeyDown"] = onKeyDown;
  exports["onBlur"] = onBlur;
  exports["onFocus"] = onFocus;
  exports["onValueInput"] = onValueInput;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Select.Setters"] = $PS["Select.Setters"] || {};
  var exports = $PS["Select.Setters"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Select = $PS["Select"];                                                             
  var setItemProps = function (index) {
      return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Halogen_HTML_Events.onMouseDown(function (ev) {
          return new Data_Maybe.Just(new Select.Select(new Select.Index(index), new Data_Maybe.Just(ev)));
      }), Halogen_HTML_Events.onMouseOver(function (v) {
          return Data_Maybe.Just.create(new Select.Highlight(new Select.Index(index)));
      }) ]);
  };
  var setInputProps = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Halogen_HTML_Events.onFocus(function (v) {
      return Data_Maybe.Just.create(new Select.SetVisibility(Select.On.value));
  }), Halogen_HTML_Events.onKeyDown(function ($8) {
      return Data_Maybe.Just.create(Select.Key.create($8));
  }), Halogen_HTML_Events.onValueInput(function ($9) {
      return Data_Maybe.Just.create(Select.Search.create($9));
  }), Halogen_HTML_Events.onMouseDown(function (v) {
      return Data_Maybe.Just.create(new Select.SetVisibility(Select.On.value));
  }), Halogen_HTML_Events.onBlur(function (v) {
      return Data_Maybe.Just.create(new Select.SetVisibility(Select.Off.value));
  }), Halogen_HTML_Properties.tabIndex(0), Halogen_HTML_Properties.ref("select-input") ]);
  var setContainerProps = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Halogen_HTML_Events.onMouseDown(function ($10) {
      return Data_Maybe.Just.create(Select.PreventClick.create($10));
  }) ]);
  exports["setInputProps"] = setInputProps;
  exports["setItemProps"] = setItemProps;
  exports["setContainerProps"] = setContainerProps;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Autocomplete.Dropdown"] = $PS["Autocomplete.Dropdown"] || {};
  var exports = $PS["Autocomplete.Dropdown"];
  var Autocomplete_Internal = $PS["Autocomplete.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Select = $PS["Select"];
  var Select_Setters = $PS["Select.Setters"];                
  var SelectionChanged = (function () {
      function SelectionChanged(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      SelectionChanged.create = function (value0) {
          return function (value1) {
              return new SelectionChanged(value0, value1);
          };
      };
      return SelectionChanged;
  })();
  var component = (function () {
      var render = function (st) {
          var renderContainer = (function () {
              var renderItem = function (index) {
                  return function (item) {
                      return Halogen_HTML_Elements.div(Select_Setters.setItemProps(index)([ Autocomplete_Internal.classes_([ "geocode-city__dropdown-item", Data_Monoid.guard(Data_Monoid.monoidString)(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(st.highlightedIndex)(new Data_Maybe.Just(index)))("geocode-city__dropdown-item-highlighted") ]) ]))([ Halogen_HTML_Core.text(Autocomplete_Internal.cityString(item)) ]);
                  };
              };
              return Autocomplete_Internal.whenElem(Data_Eq.eq(Select.eqVisibility)(st.visibility)(Select.On.value))(function (v) {
                  return Halogen_HTML_Elements.div(Select_Setters.setContainerProps([ Autocomplete_Internal.class_("geocode-city__dropdown-container") ]))(Data_Array.mapWithIndex(renderItem)(st.items));
              });
          })();
          return Halogen_HTML_Elements.div([ Autocomplete_Internal.class_("geocode-city__dropdown") ])([ renderContainer ]);
      };
      var input = function (v) {
          return {
              inputType: Select.Toggle.value,
              search: Data_Maybe.Nothing.value,
              debounceTime: Data_Maybe.Nothing.value,
              getItemCount: function ($13) {
                  return Data_Array.length((function (v1) {
                      return v1.items;
                  })($13));
              },
              items: v.items,
              selection: Data_Maybe.Nothing.value
          };
      };
      var handleEvent = function (v) {
          if (v instanceof Select.Selected) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                  var selection = Data_Array.index(st.items)(v.value0);
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $9 = {};
                      for (var $10 in v1) {
                          if ({}.hasOwnProperty.call(v1, $10)) {
                              $9[$10] = v1[$10];
                          };
                      };
                      $9.selection = selection;
                      $9.visibility = Select.Off.value;
                      return $9;
                  }))(function () {
                      return Halogen_Query_HalogenM.raise(new SelectionChanged(st.selection, selection));
                  });
              });
          };
          return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
      };
      return Select.component(Effect_Aff_Class.monadAffAff)()()()(input)({
          render: render,
          handleAction: Select.defaultSpec.handleAction,
          handleQuery: Select.defaultSpec.handleQuery,
          handleEvent: handleEvent,
          receive: Select.defaultSpec.receive,
          initialize: Select.defaultSpec.initialize,
          finalize: Select.defaultSpec.finalize
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.HTML"] = $PS["Halogen.HTML"] || {};
  var exports = $PS["Halogen.HTML"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];        
  var slot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (component) {
                          return function (input) {
                              return function (outputQuery) {
                                  return Halogen_HTML_Core.widget(new Halogen_Component.ComponentSlot(Halogen_Component.componentSlot()(dictIsSymbol)(dictOrd)(label)(p)(component)(input)(outputQuery)));
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["slot"] = slot;
})(PS);
(function($PS) {
  "use strict";
  $PS["Autocomplete.Component"] = $PS["Autocomplete.Component"] || {};
  var exports = $PS["Autocomplete.Component"];
  var Autocomplete_Dropdown = $PS["Autocomplete.Dropdown"];
  var Autocomplete_Internal = $PS["Autocomplete.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var DOM_HTML_Indexed_InputType = $PS["DOM.HTML.Indexed.InputType"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Select = $PS["Select"];
  var Select_Setters = $PS["Select.Setters"];
  var ResultsFound = (function () {
      function ResultsFound(value0) {
          this.value0 = value0;
      };
      ResultsFound.create = function (value0) {
          return new ResultsFound(value0);
      };
      return ResultsFound;
  })();
  var NoResults = (function () {
      function NoResults() {

      };
      NoResults.value = new NoResults();
      return NoResults;
  })();
  var Selected = (function () {
      function Selected(value0) {
          this.value0 = value0;
      };
      Selected.create = function (value0) {
          return new Selected(value0);
      };
      return Selected;
  })();
  var Failed = (function () {
      function Failed(value0) {
          this.value0 = value0;
      };
      Failed.create = function (value0) {
          return new Failed(value0);
      };
      return Failed;
  })();
  var HandleDropdown = (function () {
      function HandleDropdown(value0) {
          this.value0 = value0;
      };
      HandleDropdown.create = function (value0) {
          return new HandleDropdown(value0);
      };
      return HandleDropdown;
  })();
  var component = (function () {
      var render = function (st) {
          var renderPoweredBy = Halogen_HTML_Elements.div([ Autocomplete_Internal.class_("geocode-city__powered-by") ])([ Halogen_HTML_Elements.small_([ Halogen_HTML_Core.text("Powered by "), Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://geocode.city") ])([ Halogen_HTML_Core.text("geocode.city") ]) ]) ]);
          var renderDropdown = (function () {
              var handler = function (msg) {
                  return Data_Maybe.Just.create(Select.Action.create(new HandleDropdown(msg)));
              };
              var dropdownInput = {
                  items: [  ]
              };
              return Autocomplete_Internal.whenElem(Data_Eq.eq(Select.eqVisibility)(st.visibility)(Select.On.value))(function (v) {
                  return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                      return "dropdown";
                  }))(Data_Ord.ordUnit)(Data_Symbol.SProxy.value)(Data_Unit.unit)(Autocomplete_Dropdown.component)(dropdownInput)(handler);
              });
          })();
          var renderContainer = (function () {
              var renderItem = function (index) {
                  return function (city) {
                      var highlight = Data_Monoid.guard(Data_Monoid.monoidString)(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(st.highlightedIndex)(new Data_Maybe.Just(index)))("geocode-city__autocomplete-item-highlighted");
                      return Halogen_HTML_Elements.div(Select_Setters.setItemProps(index)([ Autocomplete_Internal.classes_([ "geocode-city__autocomplete-item", highlight, "geocode-city__city" ]) ]))([ Halogen_HTML_Elements.span([ Autocomplete_Internal.class_("geocode-city__city-name") ])([ Halogen_HTML_Core.text(city.name + " ") ]), Halogen_HTML_Elements.span([ Autocomplete_Internal.class_("geocode-city__city-location") ])([ Halogen_HTML_Core.text(Autocomplete_Internal.cityLocation(city)) ]) ]);
                  };
              };
              var hasItems = Data_Maybe.maybe(false)((function () {
                  var $49 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                  return function ($50) {
                      return $49(Data_Array["null"]($50));
                  };
              })())(Network_RemoteData.toMaybe(st.available));
              var messageClass = (function () {
                  if (st.available instanceof Network_RemoteData.NotAsked) {
                      return "not-asked";
                  };
                  if (st.available instanceof Network_RemoteData.Loading) {
                      return "loading";
                  };
                  if (st.available instanceof Network_RemoteData.Failure) {
                      return "error";
                  };
                  if (st.available instanceof Network_RemoteData.Success) {
                      if (hasItems) {
                          return "results-found";
                      };
                      if (Data_Boolean.otherwise) {
                          return "no-results";
                      };
                  };
                  throw new Error("Failed pattern match at Autocomplete.Component (line 162, column 9 - line 168, column 40): " + [ st.available.constructor.name ]);
              })();
              var renderItems = (function () {
                  var renderMsg = function (msg) {
                      return [ Halogen_HTML_Elements.span_([ Halogen_HTML_Core.text(msg) ]) ];
                  };
                  if (st.available instanceof Network_RemoteData.NotAsked) {
                      return renderMsg("No search performed...");
                  };
                  if (st.available instanceof Network_RemoteData.Loading) {
                      return renderMsg("Loading...");
                  };
                  if (st.available instanceof Network_RemoteData.Failure) {
                      return renderMsg(st.available.value0);
                  };
                  if (st.available instanceof Network_RemoteData.Success) {
                      if (hasItems) {
                          return Data_Array.mapWithIndex(renderItem)(st.available.value0);
                      };
                      if (Data_Boolean.otherwise) {
                          return renderMsg("No results found");
                      };
                  };
                  throw new Error("Failed pattern match at Autocomplete.Component (line 172, column 9 - line 178, column 56): " + [ st.available.constructor.name ]);
              })();
              return Autocomplete_Internal.whenElem(Data_Eq.eq(Select.eqVisibility)(st.visibility)(Select.On.value))(function (v) {
                  return Halogen_HTML_Elements.div(Select_Setters.setContainerProps([ Autocomplete_Internal.classes_([ "geocode-city__autocomplete-container", "geocode-city__autocomplete-container--" + messageClass ]) ]))([ Halogen_HTML_Elements.div_(renderItems), renderPoweredBy ]);
              });
          })();
          var hasSelection = Data_Maybe.isJust(st.selection);
          var renderInput = Halogen_HTML_Elements.input(Select_Setters.setInputProps([ Autocomplete_Internal.classes_([ "geocode-city__autocomplete-input", Data_Monoid.guard(Data_Monoid.monoidString)(hasSelection)("geocode-city__autocomplete-input-selections"), Data_Monoid.guard(Data_Monoid.monoidString)(Data_Eq.eq(Select.eqVisibility)(st.visibility)(Select.On.value))("geocode-city__autocomplete-input-active"), Data_Monoid.guard(Data_Monoid.monoidString)(Data_Maybe.isJust(st.config.inputClass))(Data_Maybe.fromMaybe("")(st.config.inputClass)) ]), Halogen_HTML_Properties.name(Data_Maybe.fromMaybe("")(st.config.inputName)), Halogen_HTML_Properties.placeholder("Type to search..."), Halogen_HTML_Properties.value(Data_Maybe.maybe("")(Autocomplete_Internal.cityString)(st.selection)), Halogen_HTML_Properties.autocomplete(false), Halogen_HTML_Properties.spellcheck(false), Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputSearch.value) ]));
          return Halogen_HTML_Elements.div([ Autocomplete_Internal.class_("geocode-city__autocomplete") ])([ renderInput, renderDropdown, renderContainer ]);
      };
      var input = function (config) {
          return {
              inputType: Select.Text.value,
              debounceTime: new Data_Maybe.Just(300.0),
              search: Data_Maybe.Nothing.value,
              getItemCount: (function () {
                  var $51 = Data_Maybe.maybe(0)(Data_Array.length);
                  return function ($52) {
                      return $51(Network_RemoteData.toMaybe((function (v) {
                          return v.available;
                      })($52)));
                  };
              })(),
              selection: Data_Maybe.Nothing.value,
              available: Network_RemoteData.NotAsked.value,
              config: config
          };
      };
      var handleQuery = function (v) {
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Just.create(v.value0(st.selection)));
          });
      };
      var handleEvent = function (v) {
          if (v instanceof Select.Selected) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                  return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Network_RemoteData.foldableRemoteData)(st.available)(function (arr) {
                      return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(Data_Array.index(arr)(v.value0))(function (item) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                              var $21 = {};
                              for (var $22 in v1) {
                                  if ({}.hasOwnProperty.call(v1, $22)) {
                                      $21[$22] = v1[$22];
                                  };
                              };
                              $21.selection = new Data_Maybe.Just(item);
                              $21.search = "";
                              $21.visibility = Select.Off.value;
                              return $21;
                          }))(function () {
                              return Halogen_Query_HalogenM.raise(new Selected(item));
                          });
                      });
                  });
              });
          };
          if (v instanceof Select.Searched) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                  var $25 = Data_String_Common["null"](v.value0);
                  if ($25) {
                      return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                          var $26 = {};
                          for (var $27 in v1) {
                              if ({}.hasOwnProperty.call(v1, $27)) {
                                  $26[$27] = v1[$27];
                              };
                          };
                          $26.available = Network_RemoteData.NotAsked.value;
                          return $26;
                      });
                  };
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $29 = {};
                      for (var $30 in v1) {
                          if ({}.hasOwnProperty.call(v1, $30)) {
                              $29[$30] = v1[$30];
                          };
                      };
                      $29.available = Network_RemoteData.Loading.value;
                      return $29;
                  }))(function () {
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(Effect_Aff_Class.monadAffAff))(Autocomplete_Internal.autocompleteCandidates(v.value0)(st.config.apiKey)(st.config.suggestionCount)))(function (cities) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                              var $32 = {};
                              for (var $33 in v1) {
                                  if ({}.hasOwnProperty.call(v1, $33)) {
                                      $32[$33] = v1[$33];
                                  };
                              };
                              $32.available = cities;
                              return $32;
                          }))(function () {
                              if (cities instanceof Network_RemoteData.Failure) {
                                  return Halogen_Query_HalogenM.raise(new Failed(cities.value0));
                              };
                              if (cities instanceof Network_RemoteData.Success) {
                                  var $37 = Data_Array["null"](cities.value0);
                                  if ($37) {
                                      return Halogen_Query_HalogenM.raise(NoResults.value);
                                  };
                                  return Halogen_Query_HalogenM.raise(new ResultsFound(cities.value0));
                              };
                              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                          });
                      });
                  });
              });
          };
          if (v instanceof Select.VisibilityChanged) {
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
          };
          throw new Error("Failed pattern match at Autocomplete.Component (line 69, column 17 - line 97, column 39): " + [ v.constructor.name ]);
      };
      var handleAction = function (v) {
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
              return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                  var $43 = {};
                  for (var $44 in v1) {
                      if ({}.hasOwnProperty.call(v1, $44)) {
                          $43[$44] = v1[$44];
                      };
                  };
                  $43.selection = v.value0.value1;
                  return $43;
              });
          });
      };
      return Select.component(Effect_Aff_Class.monadAffAff)()()()(input)({
          render: render,
          handleAction: handleAction,
          handleQuery: handleQuery,
          handleEvent: handleEvent,
          receive: Select.defaultSpec.receive,
          initialize: Select.defaultSpec.initialize,
          finalize: Select.defaultSpec.finalize
      });
  })();
  exports["ResultsFound"] = ResultsFound;
  exports["NoResults"] = NoResults;
  exports["Selected"] = Selected;
  exports["Failed"] = Failed;
  exports["component"] = component;
})(PS);
(function(exports) {
  exports.customEvent = function (name, data) {
    return new CustomEvent(name, { detail: data });
  }
})(PS["Autocomplete.Events"] = PS["Autocomplete.Events"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Argonaut.Encode.Class"] = $PS["Data.Argonaut.Encode.Class"] || {};
  var exports = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Data_RowList = $PS["Type.Data.RowList"];                
  var GEncodeJson = function (gEncodeJson) {
      this.gEncodeJson = gEncodeJson;
  };
  var EncodeJson = function (encodeJson) {
      this.encodeJson = encodeJson;
  };
  var gEncodeJsonNil = new GEncodeJson(function (v) {
      return function (v1) {
          return Foreign_Object.empty;
      };
  });
  var gEncodeJson = function (dict) {
      return dict.gEncodeJson;
  };                                                
  var encodeRecord = function (dictGEncodeJson) {
      return function (dictRowToList) {
          return new EncodeJson(function (rec) {
              return Data_Argonaut_Core.fromObject(gEncodeJson(dictGEncodeJson)(rec)(Type_Data_RowList.RLProxy.value));
          });
      };
  };                                                                                          
  var encodeJsonJString = new EncodeJson(Data_Argonaut_Core.fromString);
  var encodeJsonJNumber = new EncodeJson(Data_Argonaut_Core.fromNumber);  
  var encodeJsonInt = new EncodeJson(function ($45) {
      return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($45));
  });
  var encodeJson = function (dict) {
      return dict.encodeJson;
  };
  var encodeJsonArray = function (dictEncodeJson) {
      return new EncodeJson((function () {
          var $46 = Data_Functor.map(Data_Functor.functorArray)(encodeJson(dictEncodeJson));
          return function ($47) {
              return Data_Argonaut_Core.fromArray($46($47));
          };
      })());
  };
  var encodeJsonMaybe = function (dictEncodeJson) {
      return new EncodeJson(function (v) {
          if (v instanceof Data_Maybe.Nothing) {
              return Data_Argonaut_Core.jsonNull;
          };
          if (v instanceof Data_Maybe.Just) {
              return encodeJson(dictEncodeJson)(v.value0);
          };
          throw new Error("Failed pattern match at Data.Argonaut.Encode.Class (line 38, column 16 - line 40, column 27): " + [ v.constructor.name ]);
      });
  };
  var gEncodeJsonCons = function (dictEncodeJson) {
      return function (dictGEncodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return new GEncodeJson(function (row) {
                      return function (v) {
                          return Foreign_Object.insert(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value))(encodeJson(dictEncodeJson)(Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(row)))(gEncodeJson(dictGEncodeJson)(row)(Type_Data_RowList.RLProxy.value));
                      };
                  });
              };
          };
      };
  };
  exports["encodeJson"] = encodeJson;
  exports["encodeJsonMaybe"] = encodeJsonMaybe;
  exports["encodeJsonJNumber"] = encodeJsonJNumber;
  exports["encodeJsonInt"] = encodeJsonInt;
  exports["encodeJsonJString"] = encodeJsonJString;
  exports["encodeJsonArray"] = encodeJsonArray;
  exports["encodeRecord"] = encodeRecord;
  exports["gEncodeJsonNil"] = gEncodeJsonNil;
  exports["gEncodeJsonCons"] = gEncodeJsonCons;
})(PS);
(function($PS) {
  "use strict";
  $PS["Autocomplete.Events"] = $PS["Autocomplete.Events"] || {};
  var exports = $PS["Autocomplete.Events"];
  var $foreign = $PS["Autocomplete.Events"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Symbol = $PS["Data.Symbol"];                
  var customEventC = Data_Function_Uncurried.runFn2($foreign.customEvent);
  var lookupError = function (errMsg) {
      return customEventC("lookupError")(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
          return "message";
      }))())())({
          message: errMsg
      }));
  };
  var noCitiesFound = customEventC("noCitiesFound")(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
      return "message";
  }))())())({
      message: "No results."
  }));
  var citySelected = function (city) {
      return customEventC("citySelected")(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
          return "timezone";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "region";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "population";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "name";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "longitude";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "latitude";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "district";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "countryCode";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "country";
      }))())())(city));
  };
  var citiesFound = function (cities) {
      return customEventC("citiesFound")(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJNumber)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString))(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
          return "timezone";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "region";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "population";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "name";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "longitude";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "latitude";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "district";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "countryCode";
      }))())(new Data_Symbol.IsSymbol(function () {
          return "country";
      }))())()))(cities));
  };
  exports["citySelected"] = citySelected;
  exports["lookupError"] = lookupError;
  exports["citiesFound"] = citiesFound;
  exports["noCitiesFound"] = noCitiesFound;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Applicative.Free"] = $PS["Control.Applicative.Free"] || {};
  var exports = $PS["Control.Applicative.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Pure = (function () {
      function Pure(value0) {
          this.value0 = value0;
      };
      Pure.create = function (value0) {
          return new Pure(value0);
      };
      return Pure;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var Ap = (function () {
      function Ap(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Ap.create = function (value0) {
          return function (value1) {
              return new Ap(value0, value1);
          };
      };
      return Ap;
  })();
  var mkAp = function (fba) {
      return function (fb) {
          return new Ap(fba, fb);
      };
  };
  var liftFreeAp = Lift.create;
  var goLeft = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_valStack) {
              return function ($copy_nat) {
                  return function ($copy_func) {
                      return function ($copy_count) {
                          var $tco_var_dictApplicative = $copy_dictApplicative;
                          var $tco_var_fStack = $copy_fStack;
                          var $tco_var_valStack = $copy_valStack;
                          var $tco_var_nat = $copy_nat;
                          var $tco_var_func = $copy_func;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(dictApplicative, fStack, valStack, nat, func, count) {
                              if (func instanceof Pure) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: Control_Applicative.pure(dictApplicative)(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Lift) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: nat(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Ap) {
                                  $tco_var_dictApplicative = dictApplicative;
                                  $tco_var_fStack = fStack;
                                  $tco_var_valStack = Data_List_NonEmpty.cons(func.value1)(valStack);
                                  $tco_var_nat = nat;
                                  $tco_var_func = func.value0;
                                  $copy_count = count + 1 | 0;
                                  return;
                              };
                              throw new Error("Failed pattern match at Control.Applicative.Free (line 102, column 41 - line 105, column 81): " + [ func.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_valStack, $tco_var_nat, $tco_var_func, $copy_count);
                          };
                          return $tco_result;
                      };
                  };
              };
          };
      };
  };
  var goApply = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_vals) {
              return function ($copy_gVal) {
                  var $tco_var_dictApplicative = $copy_dictApplicative;
                  var $tco_var_fStack = $copy_fStack;
                  var $tco_var_vals = $copy_vals;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(dictApplicative, fStack, vals, gVal) {
                      if (fStack instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return new Data_Either.Left(gVal);
                      };
                      if (fStack instanceof Data_List_Types.Cons) {
                          var gRes = Control_Apply.apply(dictApplicative.Apply0())(fStack.value0.func)(gVal);
                          var $14 = fStack.value0.count === 1;
                          if ($14) {
                              if (fStack.value1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return new Data_Either.Left(gRes);
                              };
                              $tco_var_dictApplicative = dictApplicative;
                              $tco_var_fStack = fStack.value1;
                              $tco_var_vals = vals;
                              $copy_gVal = gRes;
                              return;
                          };
                          if (vals instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Data_Either.Left(gRes);
                          };
                          if (vals instanceof Data_List_Types.Cons) {
                              $tco_done = true;
                              return Data_Either.Right.create(new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                  func: gRes,
                                  count: fStack.value0.count - 1 | 0
                              }, fStack.value1), new Data_NonEmpty.NonEmpty(vals.value0, vals.value1)));
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 83, column 11 - line 88, column 50): " + [ vals.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 72, column 3 - line 88, column 50): " + [ fStack.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_vals, $copy_gVal);
                  };
                  return $tco_result;
              };
          };
      };
  };
  var functorFreeAp = new Data_Functor.Functor(function (f) {
      return function (x) {
          return mkAp(new Pure(f))(x);
      };
  });
  var foldFreeAp = function (dictApplicative) {
      return function (nat) {
          return function (z) {
              var go = function ($copy_v) {
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(v) {
                      if (v.value1.value0 instanceof Pure) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(Control_Applicative.pure(dictApplicative)(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 54, column 17 - line 56, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Lift) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(nat(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 57, column 17 - line 59, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Ap) {
                          var nextVals = new Data_NonEmpty.NonEmpty(v.value1.value0.value1, v.value1.value1);
                          $copy_v = goLeft(dictApplicative)(v.value0)(nextVals)(nat)(v.value1.value0.value0)(1);
                          return;
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 53, column 5 - line 62, column 47): " + [ v.value1.value0.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($copy_v);
                  };
                  return $tco_result;
              };
              return go(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_NonEmpty.singleton(z)));
          };
      };
  };
  var retractFreeAp = function (dictApplicative) {
      return foldFreeAp(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  };
  var applyFreeAp = new Control_Apply.Apply(function () {
      return functorFreeAp;
  }, function (fba) {
      return function (fb) {
          return mkAp(fba)(fb);
      };
  });
  var applicativeFreeAp = new Control_Applicative.Applicative(function () {
      return applyFreeAp;
  }, Pure.create);
  var hoistFreeAp = function (f) {
      return foldFreeAp(applicativeFreeAp)(function ($37) {
          return liftFreeAp(f($37));
      });
  };
  exports["retractFreeAp"] = retractFreeAp;
  exports["hoistFreeAp"] = hoistFreeAp;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Trans.Class"] = $PS["Control.Monad.Trans.Class"] || {};
  var exports = $PS["Control.Monad.Trans.Class"];
  var MonadTrans = function (lift) {
      this.lift = lift;
  };
  var lift = function (dict) {
      return dict.lift;
  };
  exports["lift"] = lift;
  exports["MonadTrans"] = MonadTrans;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Free.Trans"] = $PS["Control.Monad.Free.Trans"] || {};
  var exports = $PS["Control.Monad.Free.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Exists = $PS["Data.Exists"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];                      
  var Bound = (function () {
      function Bound(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bound.create = function (value0) {
          return function (value1) {
              return new Bound(value0, value1);
          };
      };
      return Bound;
  })();
  var FreeT = (function () {
      function FreeT(value0) {
          this.value0 = value0;
      };
      FreeT.create = function (value0) {
          return new FreeT(value0);
      };
      return FreeT;
  })();
  var Bind = (function () {
      function Bind(value0) {
          this.value0 = value0;
      };
      Bind.create = function (value0) {
          return new Bind(value0);
      };
      return Bind;
  })();
  var monadTransFreeT = function (dictFunctor) {
      return new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
          return function (ma) {
              return new FreeT(function (v) {
                  return Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Data_Either.Left.create)(ma);
              });
          };
      });
  };
  var freeT = FreeT.create;
  var bound = function (m) {
      return function (f) {
          return new Bind(Data_Exists.mkExists(new Bound(m, f)));
      };
  };
  var functorFreeT = function (dictFunctor) {
      return function (dictFunctor1) {
          return new Data_Functor.Functor(function (f) {
              return function (v) {
                  if (v instanceof FreeT) {
                      return new FreeT(function (v1) {
                          return Data_Functor.map(dictFunctor1)(Data_Bifunctor.bimap(Data_Either.bifunctorEither)(f)(Data_Functor.map(dictFunctor)(Data_Functor.map(functorFreeT(dictFunctor)(dictFunctor1))(f))))(v.value0(Data_Unit.unit));
                      });
                  };
                  if (v instanceof Bind) {
                      return Data_Exists.runExists(function (v1) {
                          return bound(v1.value0)((function () {
                              var $123 = Data_Functor.map(functorFreeT(dictFunctor)(dictFunctor1))(f);
                              return function ($124) {
                                  return $123(v1.value1($124));
                              };
                          })());
                      })(v.value0);
                  };
                  throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 59, column 1 - line 61, column 71): " + [ f.constructor.name, v.constructor.name ]);
              };
          });
      };
  };
  var monadFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Monad.Monad(function () {
              return applicativeFreeT(dictFunctor)(dictMonad);
          }, function () {
              return bindFreeT(dictFunctor)(dictMonad);
          });
      };
  };
  var bindFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Bind.Bind(function () {
              return applyFreeT(dictFunctor)(dictMonad);
          }, function (v) {
              return function (f) {
                  if (v instanceof Bind) {
                      return Data_Exists.runExists(function (v1) {
                          return bound(v1.value0)(function (x) {
                              return bound(function (v2) {
                                  return v1.value1(x);
                              })(f);
                          });
                      })(v.value0);
                  };
                  return bound(function (v1) {
                      return v;
                  })(f);
              };
          });
      };
  };
  var applyFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Apply.Apply(function () {
              return functorFreeT(dictFunctor)(((dictMonad.Bind1()).Apply0()).Functor0());
          }, Control_Monad.ap(monadFreeT(dictFunctor)(dictMonad)));
      };
  };
  var applicativeFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Applicative.Applicative(function () {
              return applyFreeT(dictFunctor)(dictMonad);
          }, function (a) {
              return new FreeT(function (v) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(a));
              });
          });
      };
  };
  var liftFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return function (fa) {
              return new FreeT(function (v) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(Data_Functor.map(dictFunctor)(Control_Applicative.pure(applicativeFreeT(dictFunctor)(dictMonad)))(fa)));
              });
          };
      };
  };
  var resume = function (dictFunctor) {
      return function (dictMonadRec) {
          var go = function (v) {
              if (v instanceof FreeT) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(v.value0(Data_Unit.unit));
              };
              if (v instanceof Bind) {
                  return Data_Exists.runExists(function (v1) {
                      var v2 = v1.value0(Data_Unit.unit);
                      if (v2 instanceof FreeT) {
                          return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(v2.value0(Data_Unit.unit))(function (v3) {
                              if (v3 instanceof Data_Either.Left) {
                                  return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(v1.value1(v3.value0)));
                              };
                              if (v3 instanceof Data_Either.Right) {
                                  return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(new Data_Either.Right(Data_Functor.map(dictFunctor)(function (h) {
                                      return Control_Bind.bind(bindFreeT(dictFunctor)(dictMonadRec.Monad0()))(h)(v1.value1);
                                  })(v3.value0))));
                              };
                              throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 54, column 20 - line 56, column 67): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v2 instanceof Bind) {
                          return Data_Exists.runExists(function (v3) {
                              return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Control_Bind.bind(bindFreeT(dictFunctor)(dictMonadRec.Monad0()))(v3.value0(Data_Unit.unit))(function (z) {
                                  return Control_Bind.bind(bindFreeT(dictFunctor)(dictMonadRec.Monad0()))(v3.value1(z))(v1.value1);
                              })));
                          })(v2.value0);
                      };
                      throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 52, column 5 - line 57, column 98): " + [ v2.constructor.name ]);
                  })(v.value0);
              };
              throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 49, column 3 - line 49, column 75): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  var runFreeT = function (dictFunctor) {
      return function (dictMonadRec) {
          return function (interp) {
              var go = function (v) {
                  if (v instanceof Data_Either.Left) {
                      return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(v.value0));
                  };
                  if (v instanceof Data_Either.Right) {
                      return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Loop.create)(interp(v.value0));
                  };
                  throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 141, column 3 - line 141, column 63): " + [ v.constructor.name ]);
              };
              return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(Control_Bind.composeKleisliFlipped((dictMonadRec.Monad0()).Bind1())(go)(resume(dictFunctor)(dictMonadRec)));
          };
      };
  };
  var monadRecFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Monad_Rec_Class.MonadRec(function () {
              return monadFreeT(dictFunctor)(dictMonad);
          }, function (f) {
              var go = function (s) {
                  return Control_Bind.bind(bindFreeT(dictFunctor)(dictMonad))(f(s))(function (v) {
                      if (v instanceof Control_Monad_Rec_Class.Loop) {
                          return go(v.value0);
                      };
                      if (v instanceof Control_Monad_Rec_Class.Done) {
                          return Control_Applicative.pure(applicativeFreeT(dictFunctor)(dictMonad))(v.value0);
                      };
                      throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 82, column 15 - line 84, column 25): " + [ v.constructor.name ]);
                  });
              };
              return go;
          });
      };
  };
  exports["freeT"] = freeT;
  exports["liftFreeT"] = liftFreeT;
  exports["resume"] = resume;
  exports["runFreeT"] = runFreeT;
  exports["functorFreeT"] = functorFreeT;
  exports["applicativeFreeT"] = applicativeFreeT;
  exports["bindFreeT"] = bindFreeT;
  exports["monadTransFreeT"] = monadTransFreeT;
  exports["monadRecFreeT"] = monadRecFreeT;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Data.Profunctor"] = $PS["Data.Profunctor"] || {};
  var exports = $PS["Data.Profunctor"];
  var Control_Category = $PS["Control.Category"];        
  var Profunctor = function (dimap) {
      this.dimap = dimap;
  };
  var profunctorFn = new Profunctor(function (a2b) {
      return function (c2d) {
          return function (b2c) {
              return function ($9) {
                  return c2d(b2c(a2b($9)));
              };
          };
      };
  });
  var dimap = function (dict) {
      return dict.dimap;
  };
  var rmap = function (dictProfunctor) {
      return function (b2c) {
          return dimap(dictProfunctor)(Control_Category.identity(Control_Category.categoryFn))(b2c);
      };
  };
  exports["dimap"] = dimap;
  exports["Profunctor"] = Profunctor;
  exports["rmap"] = rmap;
  exports["profunctorFn"] = profunctorFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Coroutine"] = $PS["Control.Coroutine"] || {};
  var exports = $PS["Control.Coroutine"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Control_Monad_Free_Trans = $PS["Control.Monad.Free.Trans"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Emit = (function () {
      function Emit(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Emit.create = function (value0) {
          return function (value1) {
              return new Emit(value0, value1);
          };
      };
      return Emit;
  })();
  var runProcess = function (dictMonadRec) {
      return Control_Monad_Free_Trans.runFreeT(Data_Identity.functorIdentity)(dictMonadRec)((function () {
          var $176 = Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0());
          var $177 = Data_Newtype.unwrap(Data_Identity.newtypeIdentity);
          return function ($178) {
              return $176($177($178));
          };
      })());
  };
  var profunctorAwait = new Data_Profunctor.Profunctor(function (f) {
      return function (g) {
          return function (v) {
              return Data_Profunctor.dimap(Data_Profunctor.profunctorFn)(f)(g)(v);
          };
      };
  });
  var loop = function (dictFunctor) {
      return function (dictMonad) {
          return function (me) {
              return Control_Monad_Rec_Class.tailRecM(Control_Monad_Free_Trans.monadRecFreeT(dictFunctor)(dictMonad))(function (v) {
                  return Data_Functor.map(Control_Monad_Free_Trans.functorFreeT(dictFunctor)(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Maybe.maybe(new Control_Monad_Rec_Class.Loop(Data_Unit.unit))(Control_Monad_Rec_Class.Done.create))(me);
              })(Data_Unit.unit);
          };
      };
  };
  var fuseWithL = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictFunctor2) {
              return function (dictMonadRec) {
                  return function (zap) {
                      return function (fs) {
                          return function (gs) {
                              var go = function (v) {
                                  return Control_Monad_Except_Trans.runExceptT(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(dictMonadRec.Monad0()))(Control_Monad_Except_Trans.ExceptT(Control_Monad_Free_Trans.resume(dictFunctor)(dictMonadRec)(v.value0)))(function (l) {
                                      return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(dictMonadRec.Monad0()))(Control_Monad_Except_Trans.ExceptT(Control_Monad_Free_Trans.resume(dictFunctor1)(dictMonadRec)(v.value1)))(function (r) {
                                          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonadRec.Monad0()))(Data_Functor.map(dictFunctor2)(function (t) {
                                              return Control_Monad_Free_Trans.freeT(function (v1) {
                                                  return go(t);
                                              });
                                          })(zap(Data_Tuple.Tuple.create)(l)(r)));
                                      });
                                  }));
                              };
                              return Control_Monad_Free_Trans.freeT(function (v) {
                                  return go(new Data_Tuple.Tuple(fs, gs));
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var fuseWith = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictFunctor2) {
              return function (dictMonadRec) {
                  return function (dictParallel) {
                      return function (zap) {
                          return function (fs) {
                              return function (gs) {
                                  var go = function (v) {
                                      return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Control_Parallel_Class.sequential(dictParallel)(Control_Apply.apply((dictParallel.Applicative1()).Apply0())(Data_Functor.map(((dictParallel.Applicative1()).Apply0()).Functor0())(Control_Apply.lift2(Data_Either.applyEither)(zap(Data_Tuple.Tuple.create)))(Control_Parallel_Class.parallel(dictParallel)(Control_Monad_Free_Trans.resume(dictFunctor)(dictMonadRec)(v.value0))))(Control_Parallel_Class.parallel(dictParallel)(Control_Monad_Free_Trans.resume(dictFunctor1)(dictMonadRec)(v.value1)))))(function (next) {
                                          if (next instanceof Data_Either.Left) {
                                              return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Data_Either.Left(next.value0));
                                          };
                                          if (next instanceof Data_Either.Right) {
                                              return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Data_Either.Right(Data_Functor.map(dictFunctor2)(function (t) {
                                                  return Control_Monad_Free_Trans.freeT(function (v1) {
                                                      return go(t);
                                                  });
                                              })(next.value0)));
                                          };
                                          throw new Error("Failed pattern match at Control.Coroutine (line 79, column 5 - line 81, column 63): " + [ next.constructor.name ]);
                                      });
                                  };
                                  return Control_Monad_Free_Trans.freeT(function (v) {
                                      return go(new Data_Tuple.Tuple(fs, gs));
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  var functorAwait = new Data_Functor.Functor(Data_Profunctor.rmap(profunctorAwait));
  var bifunctorEmit = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              return new Emit(f(v.value0), g(v.value1));
          };
      };
  });
  var functorEmit = new Data_Functor.Functor(Data_Bifunctor.rmap(bifunctorEmit));
  var connect = function (dictMonadRec) {
      return function (dictParallel) {
          return fuseWith(functorEmit)(functorAwait)(Data_Identity.functorIdentity)(dictMonadRec)(dictParallel)(function (f) {
              return function (v) {
                  return function (v1) {
                      return f(v.value1)(v1(v.value0));
                  };
              };
          });
      };
  };
  var emit = function (dictMonad) {
      return function (o) {
          return Control_Monad_Free_Trans.liftFreeT(functorEmit)(dictMonad)(new Emit(o, Data_Unit.unit));
      };
  };
  var producer = function (dictMonad) {
      return function (recv) {
          return loop(functorEmit)(dictMonad)(Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(functorEmit)(dictMonad))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(functorEmit))(dictMonad)(recv))(function (e) {
              if (e instanceof Data_Either.Left) {
                  return Data_Functor.voidLeft(Control_Monad_Free_Trans.functorFreeT(functorEmit)(((dictMonad.Bind1()).Apply0()).Functor0()))(emit(dictMonad)(e.value0))(Data_Maybe.Nothing.value);
              };
              if (e instanceof Data_Either.Right) {
                  return Control_Applicative.pure(Control_Monad_Free_Trans.applicativeFreeT(functorEmit)(dictMonad))(new Data_Maybe.Just(e.value0));
              };
              throw new Error("Failed pattern match at Control.Coroutine (line 125, column 3 - line 127, column 29): " + [ e.constructor.name ]);
          }));
      };
  };
  var pullFrom = function (dictMonadRec) {
      return fuseWithL(functorAwait)(functorEmit)(Data_Identity.functorIdentity)(dictMonadRec)(function (f) {
          return function (v) {
              return function (v1) {
                  return Control_Applicative.pure(Data_Identity.applicativeIdentity)(f(v(v1.value0))(v1.value1));
              };
          };
      });
  };
  var $$await = function (dictMonad) {
      return Control_Monad_Free_Trans.liftFreeT(functorAwait)(dictMonad)(Control_Category.identity(Control_Category.categoryFn));
  };
  var consumer = function (dictMonad) {
      return function (send) {
          return loop(functorAwait)(dictMonad)(Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(functorAwait)(dictMonad))($$await(dictMonad))(function (a) {
              return Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(functorAwait))(dictMonad)(send(a));
          }));
      };
  };
  exports["runProcess"] = runProcess;
  exports["producer"] = producer;
  exports["await"] = $$await;
  exports["consumer"] = consumer;
  exports["connect"] = connect;
  exports["pullFrom"] = pullFrom;
  exports["functorAwait"] = functorAwait;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Monad.Fork.Class"] = $PS["Control.Monad.Fork.Class"] || {};
  var exports = $PS["Control.Monad.Fork.Class"];
  var Effect_Aff = $PS["Effect.Aff"];
  var MonadFork = function (Functor1, Monad0, fork, join, suspend) {
      this.Functor1 = Functor1;
      this.Monad0 = Monad0;
      this.fork = fork;
      this.join = join;
      this.suspend = suspend;
  };
  var monadForkAff = new MonadFork(function () {
      return Effect_Aff.functorFiber;
  }, function () {
      return Effect_Aff.monadAff;
  }, Effect_Aff.forkAff, Effect_Aff.joinFiber, Effect_Aff.suspendAff);
  var fork = function (dict) {
      return dict.fork;
  };
  exports["fork"] = fork;
  exports["monadForkAff"] = monadForkAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Control.Parallel"] = $PS["Control.Parallel"] || {};
  var exports = $PS["Control.Parallel"];
  var Control_Category = $PS["Control.Category"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Foldable = $PS["Data.Foldable"];                      
  var parTraverse_ = function (dictParallel) {
      return function (dictFoldable) {
          return function (f) {
              var $17 = Control_Parallel_Class.sequential(dictParallel);
              var $18 = Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)((function () {
                  var $20 = Control_Parallel_Class.parallel(dictParallel);
                  return function ($21) {
                      return $20(f($21));
                  };
              })());
              return function ($19) {
                  return $17($18($19));
              };
          };
      };
  };
  var parSequence_ = function (dictParallel) {
      return function (dictFoldable) {
          return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["parSequence_"] = parSequence_;
})(PS);
(function(exports) {
  "use strict";

  exports.log = function (s) {
    return function () {
      console.log(s);
      return {};
    };
  };

  exports.warn = function (s) {
    return function () {
      console.warn(s);
      return {};
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  exports["log"] = $foreign.log;
  exports["warn"] = $foreign.warn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Effect.Class.Console"] = $PS["Effect.Class.Console"] || {};
  var exports = $PS["Effect.Class.Console"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var log = function (dictMonadEffect) {
      var $30 = Effect_Class.liftEffect(dictMonadEffect);
      return function ($31) {
          return $30(Effect_Console.log($31));
      };
  };
  exports["log"] = log;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Aff.Driver.State"] = $PS["Halogen.Aff.Driver.State"] || {};
  var exports = $PS["Halogen.Aff.Driver.State"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var unDriverStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX_ = function (dictApplicative) {
      return function (f) {
          return unDriverStateX(function (st) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Foldable.foldableMaybe)(f)(st.rendering);
          });
      };
  };
  var mkRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX = function (dictFunctor) {
      return function (f) {
          return unDriverStateX(function (st) {
              return mkRenderStateX(f(st.rendering));
          });
      };
  };
  var mkDriverStateXRef = Unsafe_Coerce.unsafeCoerce;
  var mapDriverState = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var initDriverState = function (component) {
      return function (input) {
          return function (handler) {
              return function (lchs) {
                  return function __do() {
                      var selfRef = Effect_Ref["new"]({})();
                      var childrenIn = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var childrenOut = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var handlerRef = Effect_Ref["new"](handler)();
                      var pendingQueries = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingOuts = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingHandlers = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                      var fresh = Effect_Ref["new"](1)();
                      var subscriptions = Effect_Ref["new"](new Data_Maybe.Just(Data_Map_Internal.empty))();
                      var forks = Effect_Ref["new"](Data_Map_Internal.empty)();
                      var ds = {
                          component: component,
                          state: component.initialState(input),
                          refs: Data_Map_Internal.empty,
                          children: Halogen_Data_Slot.empty,
                          childrenIn: childrenIn,
                          childrenOut: childrenOut,
                          selfRef: selfRef,
                          handlerRef: handlerRef,
                          pendingQueries: pendingQueries,
                          pendingOuts: pendingOuts,
                          pendingHandlers: pendingHandlers,
                          rendering: Data_Maybe.Nothing.value,
                          fresh: fresh,
                          subscriptions: subscriptions,
                          forks: forks,
                          lifecycleHandlers: lchs
                      };
                      Effect_Ref.write(ds)(selfRef)();
                      return mkDriverStateXRef(selfRef);
                  };
              };
          };
      };
  };
  exports["mapDriverState"] = mapDriverState;
  exports["unDriverStateX"] = unDriverStateX;
  exports["renderStateX"] = renderStateX;
  exports["renderStateX_"] = renderStateX_;
  exports["unRenderStateX"] = unRenderStateX;
  exports["initDriverState"] = initDriverState;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Query.ChildQuery"] = $PS["Halogen.Query.ChildQuery"] || {};
  var exports = $PS["Halogen.Query.ChildQuery"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  exports["unChildQueryBox"] = unChildQueryBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Query.EventSource"] = $PS["Halogen.Query.EventSource"] || {};
  var exports = $PS["Halogen.Query.EventSource"];
  var finalize = function (v) {
      return v;
  };
  exports["finalize"] = finalize;
})(PS);
(function(exports) {
  "use strict";

  exports.reallyUnsafeRefEq = function (a) {
    return function (b) {
      return a === b;
    };
  };
})(PS["Unsafe.Reference"] = PS["Unsafe.Reference"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Unsafe.Reference"] = $PS["Unsafe.Reference"] || {};
  var exports = $PS["Unsafe.Reference"];
  var $foreign = $PS["Unsafe.Reference"];
  var unsafeRefEq = $foreign.reallyUnsafeRefEq;
  exports["unsafeRefEq"] = unsafeRefEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Aff.Driver.Eval"] = $PS["Halogen.Aff.Driver.Eval"] || {};
  var exports = $PS["Halogen.Aff.Driver.Eval"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Coroutine = $PS["Control.Coroutine"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_Free_Trans = $PS["Control.Monad.Free.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];
  var Halogen_Query_EventSource = $PS["Halogen.Query.EventSource"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];                
  var unsubscribe = function (sid) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v.subscriptions)))(function (subs) {
                  return Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Halogen_Query_EventSource.finalize)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(subs));
              });
          });
      };
  };
  var queueOrRun = function (ref) {
      return function (au) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return au;
              };
              if (v instanceof Data_Maybe.Just) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write(new Data_Maybe.Just(new Data_List_Types.Cons(au, v.value0)))(ref));
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 195, column 33 - line 197, column 57): " + [ v.constructor.name ]);
          });
      };
  };
  var handleLifecycle = function (lchs) {
      return function (f) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
              initializers: Data_List_Types.Nil.value,
              finalizers: Data_List_Types.Nil.value
          })(lchs)))(function () {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(function (result) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(lchs)))(function (v) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(v.finalizers))(function () {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(v.initializers))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
                          });
                      });
                  });
              });
          });
      };
  };
  var fresh = function (f) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["modify'"](function (i) {
                  return {
                      state: i + 1 | 0,
                      value: f(i)
                  };
              })(v.fresh));
          });
      };
  };
  var evalQ = function (render) {
      return function (ref) {
          return function (q) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
                  return evalM(render)(ref)(v["component"]["eval"](new Halogen_Query_HalogenQ.Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(Data_Maybe.Just.create)(Data_Coyoneda.liftCoyoneda(q)), Data_Function["const"](Data_Maybe.Nothing.value))));
              });
          };
      };
  };
  var evalM = function (render) {
      return function (initRef) {
          return function (v) {
              var evalChildQuery = function (ref) {
                  return function (cqb) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                          return Halogen_Query_ChildQuery.unChildQueryBox(function (v2) {
                              var evalChild = function (v3) {
                                  return Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3)))(function (dsx) {
                                      return Halogen_Aff_Driver_State.unDriverStateX(function (ds) {
                                          return evalQ(render)(ds.selfRef)(v2.value1);
                                      })(dsx);
                                  }));
                              };
                              return Data_Functor.map(Effect_Aff.functorAff)(v2.value2)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(v2.value0(Effect_Aff.applicativeParAff)(evalChild)(v1.children)));
                          })(cqb);
                      });
                  };
              };
              var go = function (ref) {
                  return function (v1) {
                      if (v1 instanceof Halogen_Query_HalogenM.State) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              var v3 = v1.value0(v2.state);
                              if (Unsafe_Reference.unsafeRefEq(v2.state)(v3.value1)) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                              };
                              if (Data_Boolean.otherwise) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
                                      component: v2.component,
                                      state: v3.value1,
                                      refs: v2.refs,
                                      children: v2.children,
                                      childrenIn: v2.childrenIn,
                                      childrenOut: v2.childrenOut,
                                      selfRef: v2.selfRef,
                                      handlerRef: v2.handlerRef,
                                      pendingQueries: v2.pendingQueries,
                                      pendingOuts: v2.pendingOuts,
                                      pendingHandlers: v2.pendingHandlers,
                                      rendering: v2.rendering,
                                      fresh: v2.fresh,
                                      subscriptions: v2.subscriptions,
                                      forks: v2.forks,
                                      lifecycleHandlers: v2.lifecycleHandlers
                                  })(ref)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleLifecycle(v2.lifecycleHandlers)(render(v2.lifecycleHandlers)(ref)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                                      });
                                  });
                              };
                              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 85, column 7 - line 91, column 21): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Subscribe) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.SubscriptionId)(ref))(function (sid) {
                              var v2 = v1.value0(sid);
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v3) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Control_Bind.bind(Effect_Aff.bindAff)(v2)(function (v4) {
                                      var done = Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3.subscriptions)))(function (subs) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordSubscriptionId)(sid)))(v3.subscriptions)))(function () {
                                              return Control_Applicative.when(Effect_Aff.applicativeAff)(Data_Maybe.maybe(false)(Data_Map_Internal.member(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(subs))(Halogen_Query_EventSource.finalize(v4.finalizer));
                                          });
                                      });
                                      var consumer = Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Control_Coroutine["await"](Effect_Aff.monadAff))(function (act) {
                                          return Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(Control_Coroutine.functorAwait))(Effect_Aff.monadAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3.subscriptions))))(function (subs) {
                                              return Control_Applicative.when(Control_Monad_Free_Trans.applicativeFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.member(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(subs))(new Data_Maybe.Just(true)))(Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(Control_Coroutine.functorAwait))(Effect_Aff.monadAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(evalF(render)(ref)(new Halogen_Query_Input.Action(act)))))(function () {
                                                  return consumer;
                                              }));
                                          });
                                      });
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordSubscriptionId)(sid)(done)))(v3.subscriptions)))(function () {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Coroutine.runProcess(Effect_Aff.monadRecAff)(Control_Coroutine.pullFrom(Effect_Aff.monadRecAff)(consumer)(v4.producer)))(function () {
                                              return Halogen_Query_EventSource.finalize(done);
                                          });
                                      });
                                  })))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(sid));
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Unsubscribe) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(unsubscribe(v1.value0)(ref))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Lift) {
                          return v1.value0;
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.ChildQuery) {
                          return evalChildQuery(ref)(v1.value0);
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Raise) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.handlerRef)))(function (handler) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(queueOrRun(v2.pendingOuts)(handler(v1.value0)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Par) {
                          return Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Control_Applicative_Free.retractFreeAp(Effect_Aff.applicativeParAff)(Control_Applicative_Free.hoistFreeAp((function () {
                              var $85 = Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
                              var $86 = evalM(render)(ref);
                              return function ($87) {
                                  return $85($86($87));
                              };
                          })())(v1.value0)));
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Fork) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.ForkId)(ref))(function (fid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (doneRef) {
                                      return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Effect_Aff["finally"](Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                          Effect_Ref.modify_(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordForkId)(fid))(v2.forks)();
                                          return Effect_Ref.write(true)(doneRef)();
                                      }))(evalM(render)(ref)(v1.value0))))(function (fiber) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Monad.unlessM(Effect.monadEffect)(Effect_Ref.read(doneRef))(Effect_Ref.modify_(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordForkId)(fid)(fiber))(v2.forks))))(function () {
                                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(fid));
                                          });
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Kill) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.forks)))(function (forkMap) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Effect_Aff.killFiber(Effect_Exception.error("Cancelled")))(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordForkId)(v1.value0)(forkMap)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.GetRef) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(Data_Map_Internal.lookup(Data_Ord.ordString)(v1.value0)(v2.refs)));
                          });
                      };
                      throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 82, column 12 - line 146, column 33): " + [ v1.constructor.name ]);
                  };
              };
              return Control_Monad_Free.foldFree(Effect_Aff.monadRecAff)(go(initRef))(v);
          };
      };
  };
  var evalF = function (render) {
      return function (ref) {
          return function (v) {
              if (v instanceof Halogen_Query_Input.RefUpdate) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Function.flip(Effect_Ref.modify_)(ref)(Halogen_Aff_Driver_State.mapDriverState(function (st) {
                      return {
                          component: st.component,
                          state: st.state,
                          refs: Data_Map_Internal.alter(Data_Ord.ordString)(Data_Function["const"](v.value1))(v.value0)(st.refs),
                          children: st.children,
                          childrenIn: st.childrenIn,
                          childrenOut: st.childrenOut,
                          selfRef: st.selfRef,
                          handlerRef: st.handlerRef,
                          pendingQueries: st.pendingQueries,
                          pendingOuts: st.pendingOuts,
                          pendingHandlers: st.pendingHandlers,
                          rendering: st.rendering,
                          fresh: st.fresh,
                          subscriptions: st.subscriptions,
                          forks: st.forks,
                          lifecycleHandlers: st.lifecycleHandlers
                      };
                  })));
              };
              if (v instanceof Halogen_Query_Input.Action) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                      return evalM(render)(ref)(v1["component"]["eval"](new Halogen_Query_HalogenQ.Action(v.value0, Data_Unit.unit)));
                  });
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 51, column 20 - line 57, column 62): " + [ v.constructor.name ]);
          };
      };
  };
  exports["evalF"] = evalF;
  exports["evalQ"] = evalQ;
  exports["evalM"] = evalM;
  exports["handleLifecycle"] = handleLifecycle;
  exports["queueOrRun"] = queueOrRun;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Aff.Driver"] = $PS["Halogen.Aff.Driver"] || {};
  var exports = $PS["Halogen.Aff.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Coroutine = $PS["Control.Coroutine"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_AVar = $PS["Effect.Aff.AVar"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_Eval = $PS["Halogen.Aff.Driver.Eval"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_EventSource = $PS["Halogen.Query.EventSource"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];                
  var newLifecycleHandlers = Effect_Ref["new"]({
      initializers: Data_List_Types.Nil.value,
      finalizers: Data_List_Types.Nil.value
  });
  var handleAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var handlePending = function (ref) {
      return function __do() {
          var queue = Effect_Ref.read(ref)();
          Effect_Ref.write(Data_Maybe.Nothing.value)(ref)();
          return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(queue)((function () {
              var $25 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
              return function ($26) {
                  return handleAff($25(Data_List.reverse($26)));
              };
          })())();
      };
  };
  var cleanupSubscriptionsAndForks = function (v) {
      return function __do() {
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
              var $27 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Map_Internal.foldableMap)((function () {
                  var $29 = Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff);
                  return function ($30) {
                      return $29(Halogen_Query_EventSource.finalize($30));
                  };
              })());
              return function ($28) {
                  return handleAff($27($28));
              };
          })()))(Effect_Ref.read(v.subscriptions))();
          Effect_Ref.write(Data_Maybe.Nothing.value)(v.subscriptions)();
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
              var $31 = Effect_Aff.killFiber(Effect_Exception.error("finalized"));
              return function ($32) {
                  return handleAff($31($32));
              };
          })()))(Effect_Ref.read(v.forks))();
          return Effect_Ref.write(Data_Map_Internal.empty)(v.forks)();
      };
  };
  var runUI = function (renderSpec) {
      return function (component) {
          return function (i) {
              var subscribe = function (fresh) {
                  return function (ref) {
                      return function (consumer) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff_AVar.empty)(function (inputVar) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                  var listenerId = Effect_Ref.read(fresh)();
                                  Effect_Ref.modify_(function (v) {
                                      return v + 1 | 0;
                                  })(fresh)();
                                  Effect_Ref.modify_(Data_Map_Internal.insert(Data_Ord.ordInt)(listenerId)(inputVar))(ref)();
                                  return listenerId;
                              }))(function (listenerId) {
                                  var producer = Control_Coroutine.producer(Effect_Aff.monadAff)(Data_Functor.map(Effect_Aff.functorAff)(Data_Either.either(Data_Function["const"](new Data_Either.Right(Data_Unit.unit)))(Data_Either.Left.create))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Effect_Aff_AVar.take(inputVar))));
                                  return Data_Functor["void"](Effect_Aff.functorAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Coroutine.runProcess(Effect_Aff.monadRecAff)(Control_Coroutine.connect(Effect_Aff.monadRecAff)(Effect_Aff.parallelAff)(producer)(consumer)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Map_Internal["delete"](Data_Ord.ordInt)(listenerId))(ref)))(function () {
                                          return Effect_Aff_AVar.kill(Effect_Exception.error("ended"))(inputVar);
                                      });
                                  })));
                              });
                          });
                      };
                  };
              };
              var rootHandler = function (ref) {
                  return function (message) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (listeners) {
                          return Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Map_Internal.foldableMap)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(Data_Functor.map(Data_Map_Internal.functorMap)(Effect_Aff_AVar.put(message))(listeners));
                      });
                  };
              };
              var squashChildInitializers = function (lchs) {
                  return function (preInits) {
                      return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                          var parentInitializer = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Initialize(Data_Unit.unit)));
                          return Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: new Data_List_Types.Cons(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(Data_List.reverse(handlers.initializers)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(parentInitializer)(function () {
                                          return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                              handlePending(st.pendingQueries)();
                                              return handlePending(st.pendingOuts)();
                                          });
                                      });
                                  }), preInits),
                                  finalizers: handlers.finalizers
                              };
                          })(lchs);
                      });
                  };
              };
              var runComponent = function (lchs) {
                  return function (handler) {
                      return function (j) {
                          return Halogen_Component.unComponent(function (c) {
                              return function __do() {
                                  var lchs$prime = newLifecycleHandlers();
                                  var $$var = Halogen_Aff_Driver_State.initDriverState(c)(j)(handler)(lchs$prime)();
                                  var pre = Effect_Ref.read(lchs)();
                                  Effect_Ref.write({
                                      initializers: Data_List_Types.Nil.value,
                                      finalizers: pre.finalizers
                                  })(lchs)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $33 = render(lchs);
                                      return function ($34) {
                                          return $33((function (v) {
                                              return v.selfRef;
                                          })($34));
                                      };
                                  })()))(Effect_Ref.read($$var))();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(squashChildInitializers(lchs)(pre.initializers))(Effect_Ref.read($$var))();
                                  return $$var;
                              };
                          });
                      };
                  };
              };
              var renderChild = function (lchs) {
                  return function (handler) {
                      return function (childrenInRef) {
                          return function (childrenOutRef) {
                              return Halogen_Component.unComponentSlot(function (slot) {
                                  return function __do() {
                                      var childrenIn = Data_Functor.map(Effect.functorEffect)(slot.pop)(Effect_Ref.read(childrenInRef))();
                                      var $$var = (function () {
                                          if (childrenIn instanceof Data_Maybe.Just) {
                                              Effect_Ref.write(childrenIn.value0.value1)(childrenInRef)();
                                              var dsx = Effect_Ref.read(childrenIn.value0.value0)();
                                              Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                                  return function __do() {
                                                      Data_Function.flip(Effect_Ref.write)(st.handlerRef)((function () {
                                                          var $35 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                          return function ($36) {
                                                              return $35(slot.output($36));
                                                          };
                                                      })())();
                                                      return handleAff(Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Receive(slot.input, Data_Unit.unit))))();
                                                  };
                                              })(dsx)();
                                              return childrenIn.value0.value0;
                                          };
                                          if (childrenIn instanceof Data_Maybe.Nothing) {
                                              return runComponent(lchs)((function () {
                                                  var $37 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                  return function ($38) {
                                                      return $37(slot.output($38));
                                                  };
                                              })())(slot.input)(slot.component)();
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 236, column 14 - line 245, column 98): " + [ childrenIn.constructor.name ]);
                                      })();
                                      var isDuplicate = Data_Functor.map(Effect.functorEffect)(function ($39) {
                                          return Data_Maybe.isJust(slot.get($39));
                                      })(Effect_Ref.read(childrenOutRef))();
                                      Control_Applicative.when(Effect.applicativeEffect)(isDuplicate)(Effect_Console.warn("Halogen: Duplicate slot address was detected during rendering, unexpected results may occur"))();
                                      Effect_Ref.modify_(slot.set($$var))(childrenOutRef)();
                                      return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read($$var))(Halogen_Aff_Driver_State.renderStateX(Effect.functorEffect)(function (v) {
                                          if (v instanceof Data_Maybe.Nothing) {
                                              return Effect_Exception["throw"]("Halogen internal error: child was not initialized in renderChild");
                                          };
                                          if (v instanceof Data_Maybe.Just) {
                                              return Control_Applicative.pure(Effect.applicativeEffect)(renderSpec.renderChild(v.value0));
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 250, column 37 - line 252, column 50): " + [ v.constructor.name ]);
                                      }))();
                                  };
                              });
                          };
                      };
                  };
              };
              var render = function (lchs) {
                  return function ($$var) {
                      return function __do() {
                          var v = Effect_Ref.read($$var)();
                          var shouldProcessHandlers = Data_Functor.map(Effect.functorEffect)(Data_Maybe.isNothing)(Effect_Ref.read(v.pendingHandlers))();
                          Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(v.pendingHandlers))();
                          Effect_Ref.write(Halogen_Data_Slot.empty)(v.childrenOut)();
                          Effect_Ref.write(v.children)(v.childrenIn)();
                          var selfRef = Control_Category.identity(Control_Category.categoryFn)(v.selfRef);
                          var pendingQueries = Control_Category.identity(Control_Category.categoryFn)(v.pendingQueries);
                          var pendingHandlers = Control_Category.identity(Control_Category.categoryFn)(v.pendingHandlers);
                          var handler = (function () {
                              var $40 = Halogen_Aff_Driver_Eval.queueOrRun(pendingHandlers);
                              var $41 = Data_Functor["void"](Effect_Aff.functorAff);
                              var $42 = Halogen_Aff_Driver_Eval.evalF(render)(selfRef);
                              return function ($43) {
                                  return $40($41($42($43)));
                              };
                          })();
                          var childHandler = (function () {
                              var $44 = Halogen_Aff_Driver_Eval.queueOrRun(pendingQueries);
                              return function ($45) {
                                  return $44(handler(Halogen_Query_Input.Action.create($45)));
                              };
                          })();
                          var rendering = renderSpec.render(function ($46) {
                              return handleAff(handler($46));
                          })(renderChild(lchs)(childHandler)(v.childrenIn)(v.childrenOut))(v.component.render(v.state))(v.rendering)();
                          var children = Effect_Ref.read(v.childrenOut)();
                          var childrenIn = Effect_Ref.read(v.childrenIn)();
                          Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(childrenIn)(function (v1) {
                              return function __do() {
                                  var childDS = Effect_Ref.read(v1)();
                                  Halogen_Aff_Driver_State.renderStateX_(Effect.applicativeEffect)(renderSpec.removeChild)(childDS)();
                                  return finalize(lchs)(childDS)();
                              };
                          })();
                          Data_Function.flip(Effect_Ref.modify_)(v.selfRef)(Halogen_Aff_Driver_State.mapDriverState(function (ds$prime) {
                              return {
                                  component: ds$prime.component,
                                  state: ds$prime.state,
                                  refs: ds$prime.refs,
                                  children: children,
                                  childrenIn: ds$prime.childrenIn,
                                  childrenOut: ds$prime.childrenOut,
                                  selfRef: ds$prime.selfRef,
                                  handlerRef: ds$prime.handlerRef,
                                  pendingQueries: ds$prime.pendingQueries,
                                  pendingOuts: ds$prime.pendingOuts,
                                  pendingHandlers: ds$prime.pendingHandlers,
                                  rendering: new Data_Maybe.Just(rendering),
                                  fresh: ds$prime.fresh,
                                  subscriptions: ds$prime.subscriptions,
                                  forks: ds$prime.forks,
                                  lifecycleHandlers: ds$prime.lifecycleHandlers
                              };
                          }))();
                          return Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Data_Function.flip(Control_Monad_Rec_Class.tailRecM(Control_Monad_Rec_Class.monadRecEffect))(Data_Unit.unit)(function (v1) {
                              return function __do() {
                                  var handlers = Effect_Ref.read(pendingHandlers)();
                                  Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(pendingHandlers)();
                                  Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
                                      var $47 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
                                      return function ($48) {
                                          return handleAff($47(Data_List.reverse($48)));
                                      };
                                  })())(handlers)();
                                  var mmore = Effect_Ref.read(pendingHandlers)();
                                  var $21 = Data_Maybe.maybe(false)(Data_List["null"])(mmore);
                                  if ($21) {
                                      return Data_Functor.voidLeft(Effect.functorEffect)(Effect_Ref.write(Data_Maybe.Nothing.value)(pendingHandlers))(new Control_Monad_Rec_Class.Done(Data_Unit.unit))();
                                  };
                                  return new Control_Monad_Rec_Class.Loop(Data_Unit.unit);
                              };
                          }))();
                      };
                  };
              };
              var finalize = function (lchs) {
                  return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                      return function __do() {
                          cleanupSubscriptionsAndForks(st)();
                          var f = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Finalize(Data_Unit.unit)));
                          Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: handlers.initializers,
                                  finalizers: new Data_List_Types.Cons(f, handlers.finalizers)
                              };
                          })(lchs)();
                          return Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(st.children)(function (v) {
                              return function __do() {
                                  var dsx = Effect_Ref.read(v)();
                                  return finalize(lchs)(dsx)();
                              };
                          })();
                      };
                  });
              };
              var evalDriver = function (disposed) {
                  return function (ref) {
                      return function (q) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(disposed)))(function (v) {
                              if (v) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                              };
                              return Halogen_Aff_Driver_Eval.evalQ(render)(ref)(q);
                          });
                      };
                  };
              };
              var dispose = function (disposed) {
                  return function (lchs) {
                      return function (dsx) {
                          return function (subsRef) {
                              return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                                  var v = Effect_Ref.read(disposed)();
                                  if (v) {
                                      return Data_Unit.unit;
                                  };
                                  Effect_Ref.write(true)(disposed)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
                                      var $49 = Effect_Aff_AVar.kill(Effect_Exception.error("disposed"));
                                      return function ($50) {
                                          return Effect_Aff.launchAff_($49($50));
                                      };
                                  })()))(Effect_Ref.read(subsRef))();
                                  finalize(lchs)(dsx)();
                                  return Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $51 = Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(renderSpec.dispose);
                                      return function ($52) {
                                          return $51((function (v1) {
                                              return v1.rendering;
                                          })($52));
                                      };
                                  })())(dsx)();
                              });
                          };
                      };
                  };
              };
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(newLifecycleHandlers))(function (lchs) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](0)))(function (fresh) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (disposed) {
                          return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                              var listeners = Effect_Ref["new"](Data_Map_Internal.empty)();
                              var dsx = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref.read)(runComponent(lchs)(rootHandler(listeners))(i)(component))();
                              return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                  return Control_Applicative.pure(Effect.applicativeEffect)({
                                      query: evalDriver(disposed)(st.selfRef),
                                      subscribe: subscribe(fresh)(listeners),
                                      dispose: dispose(disposed)(lchs)(dsx)(listeners)
                                  });
                              })(dsx)();
                          });
                      });
                  });
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";                                               

  exports._querySelector = function (selector) {
    return function (node) {
      return function () {
        return node.querySelector(selector);
      };
    };
  };
})(PS["Web.DOM.ParentNode"] = PS["Web.DOM.ParentNode"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.DOM.ParentNode"] = $PS["Web.DOM.ParentNode"] || {};
  var exports = $PS["Web.DOM.ParentNode"];
  var $foreign = $PS["Web.DOM.ParentNode"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var querySelector = function (qs) {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $4 = $foreign["_querySelector"](qs);
      return function ($5) {
          return $3($4($5));
      };
  };
  exports["querySelector"] = querySelector;
})(PS);
(function(exports) {
  /* global window */
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.HTML"] = $PS["Web.HTML"] || {};
  var exports = $PS["Web.HTML"];
  var $foreign = $PS["Web.HTML"];
  exports["window"] = $foreign.window;
})(PS);
(function(exports) {
  "use strict";

  exports._readyState = function (doc) {
    return function () {
      return doc.readyState;
    };
  };
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.HTML.HTMLDocument.ReadyState"] = $PS["Web.HTML.HTMLDocument.ReadyState"] || {};
  var exports = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Loading = (function () {
      function Loading() {

      };
      Loading.value = new Loading();
      return Loading;
  })();
  var Interactive = (function () {
      function Interactive() {

      };
      Interactive.value = new Interactive();
      return Interactive;
  })();
  var Complete = (function () {
      function Complete() {

      };
      Complete.value = new Complete();
      return Complete;
  })();
  var parse = function (v) {
      if (v === "loading") {
          return new Data_Maybe.Just(Loading.value);
      };
      if (v === "interactive") {
          return new Data_Maybe.Just(Interactive.value);
      };
      if (v === "complete") {
          return new Data_Maybe.Just(Complete.value);
      };
      return Data_Maybe.Nothing.value;
  };
  exports["Loading"] = Loading;
  exports["parse"] = parse;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.HTML.HTMLDocument"] = $PS["Web.HTML.HTMLDocument"] || {};
  var exports = $PS["Web.HTML.HTMLDocument"];
  var $foreign = $PS["Web.HTML.HTMLDocument"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var toParentNode = Unsafe_Coerce.unsafeCoerce; 
  var toDocument = Unsafe_Coerce.unsafeCoerce;
  var readyState = (function () {
      var $0 = Data_Functor.map(Effect.functorEffect)((function () {
          var $2 = Data_Maybe.fromMaybe(Web_HTML_HTMLDocument_ReadyState.Loading.value);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument_ReadyState.parse($3));
          };
      })());
      return function ($1) {
          return $0($foreign["_readyState"]($1));
      };
  })();
  exports["toDocument"] = toDocument;
  exports["toParentNode"] = toParentNode;
  exports["readyState"] = readyState;
})(PS);
(function(exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.HTML.Window"] = $PS["Web.HTML.Window"] || {};
  var exports = $PS["Web.HTML.Window"];
  var $foreign = $PS["Web.HTML.Window"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  exports["toEventTarget"] = toEventTarget;
  exports["document"] = $foreign.document;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.Aff.Util"] = $PS["Halogen.Aff.Util"] || {};
  var exports = $PS["Halogen.Aff.Util"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Web_DOM_ParentNode = $PS["Web.DOM.ParentNode"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var selectElement = function (query) {
      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Bind.bindFlipped(Effect.bindEffect)(Control_Bind.composeKleisliFlipped(Effect.bindEffect)((function () {
          var $2 = Web_DOM_ParentNode.querySelector(query);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument.toParentNode($3));
          };
      })())(Web_HTML_Window.document))(Web_HTML.window)))(function (mel) {
          return Control_Applicative.pure(Effect_Aff.applicativeAff)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(mel));
      });
  };
  var runHalogenAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var awaitLoad = Effect_Aff.makeAff(function (callback) {
      return function __do() {
          var rs = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_HTMLDocument.readyState)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))();
          if (rs instanceof Web_HTML_HTMLDocument_ReadyState.Loading) {
              var et = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
              var listener = Web_Event_EventTarget.eventListener(function (v) {
                  return callback(new Data_Either.Right(Data_Unit.unit));
              })();
              Web_Event_EventTarget.addEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et)();
              return Effect_Aff.effectCanceler(Web_Event_EventTarget.removeEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et));
          };
          callback(new Data_Either.Right(Data_Unit.unit))();
          return Effect_Aff.nonCanceler;
      };
  });
  exports["awaitLoad"] = awaitLoad;
  exports["selectElement"] = selectElement;
  exports["runHalogenAff"] = runHalogenAff;
})(PS);
(function(exports) {
  "use strict";

  exports._getAttribute = function (name) {
    return function (element) {
      return function () {
        return element.getAttribute(name);
      };
    };
  };
})(PS["Web.DOM.Element"] = PS["Web.DOM.Element"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.DOM.Element"] = $PS["Web.DOM.Element"] || {};
  var exports = $PS["Web.DOM.Element"];
  var $foreign = $PS["Web.DOM.Element"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  var getAttribute = function (attr) {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $4 = $foreign["_getAttribute"](attr);
      return function ($5) {
          return $3($4($5));
      };
  };
  exports["toNode"] = toNode;
  exports["toEventTarget"] = toEventTarget;
  exports["getAttribute"] = getAttribute;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.DOM"] = $PS["Halogen.VDom.DOM"] || {};
  var exports = $PS["Halogen.VDom.DOM"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var haltWidget = function (v) {
      return Halogen_VDom_Machine.halt(v.widget);
  };
  var patchWidget = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchWidget(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Widget) {
          var res = Halogen_VDom_Machine.step(state.widget, vdom.value0);
          var res$prime = Halogen_VDom_Machine.unStep(function (v) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v.value0, {
                  build: state.build,
                  widget: res
              }, patchWidget, haltWidget));
          })(res);
          return res$prime;
      };
      haltWidget(state);
      return state.build(vdom);
  };
  var haltText = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      return Halogen_VDom_Util.removeChild(v.node, parent);
  };
  var patchText = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchText(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Text) {
          if (state.value === vdom.value0) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, state, patchText, haltText));
          };
          if (Data_Boolean.otherwise) {
              var nextState = {
                  build: state.build,
                  node: state.node,
                  value: vdom.value0
              };
              Halogen_VDom_Util.setTextContent(vdom.value0, state.node);
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchText, haltText));
          };
      };
      haltText(state);
      return state.build(vdom);
  };
  var haltKeyed = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forInE(v.children, function (v1, s) {
          return Halogen_VDom_Machine.halt(s);
      });
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var haltElem = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forEachE(v.children, Halogen_VDom_Machine.halt);
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var eqElemSpec = function (ns1, v, ns2, v1) {
      var $56 = v === v1;
      if ($56) {
          if (ns1 instanceof Data_Maybe.Just && (ns2 instanceof Data_Maybe.Just && ns1.value0 === ns2.value0)) {
              return true;
          };
          if (ns1 instanceof Data_Maybe.Nothing && ns2 instanceof Data_Maybe.Nothing) {
              return true;
          };
          return false;
      };
      return false;
  };
  var patchElem = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchElem(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Elem && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          var v1 = Data_Array.length(state.children);
          if (v1 === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
          };
          var onThis = function (ix, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (ix, s, v2) {
              var res = Halogen_VDom_Machine.step(s, v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (ix, v2) {
              var res = state.build(v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithIxE(state.children, vdom.value3, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
      };
      haltElem(state);
      return state.build(vdom);
  };
  var patchKeyed = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchKeyed(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Keyed && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          if (state.length === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children,
                  length: 0
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (v2, ix$prime, s, v3) {
              var res = Halogen_VDom_Machine.step(s, v3.value1);
              Halogen_VDom_Util.insertChildIx(ix$prime, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (v2, ix, v3) {
              var res = state.build(v3.value1);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithKeyAndIxE(state.children, vdom.value3, Data_Tuple.fst, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2,
              length: v
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
      };
      haltKeyed(state);
      return state.build(vdom);
  };
  var buildWidget = function (v, build, w) {
      var res = v.buildWidget(v)(w);
      var res$prime = Halogen_VDom_Machine.unStep(function (v1) {
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v1.value0, {
              build: build,
              widget: res
          }, patchWidget, haltWidget));
      })(res);
      return res$prime;
  };
  var buildText = function (v, build, s) {
      var node = Halogen_VDom_Util.createTextNode(s, v.document);
      var state = {
          build: build,
          node: node,
          value: s
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchText, haltText));
  };
  var buildKeyed = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (k, ix, v1) {
          var res = build(v1.value1);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.strMapWithIxE(ch1, Data_Tuple.fst, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children,
          length: Data_Array.length(ch1)
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchKeyed, haltKeyed));
  };
  var buildElem = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (ix, child) {
          var res = build(child);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.forE(ch1, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchElem, haltElem));
  };
  var buildVDom = function (spec) {
      var build = function (v) {
          if (v instanceof Halogen_VDom_Types.Text) {
              return buildText(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Elem) {
              return buildElem(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Keyed) {
              return buildKeyed(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Widget) {
              return buildWidget(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Grafted) {
              return build(Halogen_VDom_Types.runGraft(v.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.DOM (line 58, column 27 - line 63, column 52): " + [ v.constructor.name ]);
      };
      return build;
  };
  exports["buildVDom"] = buildVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.Thunk"] = $PS["Halogen.VDom.Thunk"] || {};
  var exports = $PS["Halogen.VDom.Thunk"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];        
  var Thunk = (function () {
      function Thunk(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Thunk.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Thunk(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Thunk;
  })();                                          
  var unsafeEqThunk = function (v, v1) {
      return Halogen_VDom_Util.refEq(v.value0, v1.value0) && (Halogen_VDom_Util.refEq(v.value1, v1.value1) && v.value1(v.value3, v1.value3));
  };
  var thunk = function (tid, eqFn, f, a) {
      return new Thunk(tid, eqFn, f, a);
  };
  var runThunk = function (v) {
      return v.value2(v.value3);
  };
  var buildThunk = function (toVDom) {
      var haltThunk = function (state) {
          return Halogen_VDom_Machine.halt(state.vdom);
      };
      var patchThunk = function (state, t2) {
          var $43 = unsafeEqThunk(state.thunk, t2);
          if ($43) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(state.vdom), state, patchThunk, haltThunk));
          };
          var vdom = Halogen_VDom_Machine.step(state.vdom, toVDom(runThunk(t2)));
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
              vdom: vdom,
              thunk: t2
          }, patchThunk, haltThunk));
      };
      var renderThunk = function (spec) {
          return function (t) {
              var vdom = Halogen_VDom_DOM.buildVDom(spec)(toVDom(runThunk(t)));
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
                  thunk: t,
                  vdom: vdom
              }, patchThunk, haltThunk));
          };
      };
      return renderThunk;
  };
  exports["buildThunk"] = buildThunk;
})(PS);
(function(exports) {
  "use strict";

  var getEffProp = function (name) {
    return function (node) {
      return function () {
        return node[name];
      };
    };
  };                                                   

  exports._parentNode = getEffProp("parentNode");

  exports.childNodes = getEffProp("childNodes");           

  exports._nextSibling = getEffProp("nextSibling");

  exports.insertBefore = function (node1) {
    return function (node2) {
      return function (parent) {
        return function () {
          return parent.insertBefore(node1, node2);
        };
      };
    };
  };

  exports.appendChild = function (node) {
    return function (parent) {
      return function () {
        return parent.appendChild(node);
      };
    };
  };

  exports.removeChild = function (node) {
    return function (parent) {
      return function () {
        return parent.removeChild(node);
      };
    };
  };
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.DOM.Node"] = $PS["Web.DOM.Node"] || {};
  var exports = $PS["Web.DOM.Node"];
  var $foreign = $PS["Web.DOM.Node"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var parentNode = (function () {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($4) {
          return $3($foreign["_parentNode"]($4));
      };
  })();
  var nextSibling = (function () {
      var $14 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($15) {
          return $14($foreign["_nextSibling"]($15));
      };
  })();
  exports["parentNode"] = parentNode;
  exports["nextSibling"] = nextSibling;
  exports["childNodes"] = $foreign.childNodes;
  exports["insertBefore"] = $foreign.insertBefore;
  exports["appendChild"] = $foreign.appendChild;
  exports["removeChild"] = $foreign.removeChild;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Halogen.VDom.Driver"] = $PS["Halogen.VDom.Driver"] || {};
  var exports = $PS["Halogen.VDom.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver = $PS["Halogen.Aff.Driver"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var substInParent = function (v) {
      return function (v1) {
          return function (v2) {
              if (v1 instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.insertBefore(v)(v1.value0)(v2.value0));
              };
              if (v1 instanceof Data_Maybe.Nothing && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(v)(v2.value0));
              };
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
      };
  };
  var removeChild = function (v) {
      return function __do() {
          var npn = Web_DOM_Node.parentNode(v.node)();
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (pn) {
              return Web_DOM_Node.removeChild(v.node)(pn);
          })(npn)();
      };
  };
  var mkSpec = function (handler) {
      return function (renderChildRef) {
          return function (document) {
              var getNode = Halogen_Aff_Driver_State.unRenderStateX(function (v) {
                  return v.node;
              });
              var done = function (st) {
                  if (st instanceof Data_Maybe.Just) {
                      return Halogen_VDom_Machine.halt(st.value0);
                  };
                  return Data_Unit.unit;
              };
              var buildWidget = function (spec) {
                  var buildThunk = Halogen_VDom_Thunk.buildThunk(Data_Newtype.unwrap(Halogen_HTML_Core.newtypeHTML))(spec);
                  var renderComponentSlot = function (cs) {
                      var renderChild = Effect_Ref.read(renderChildRef)();
                      var rsx = renderChild(cs)();
                      var node = getNode(rsx);
                      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, Data_Maybe.Nothing.value, patch, done));
                  };
                  var render = function (slot) {
                      if (slot instanceof Halogen_Component.ComponentSlot) {
                          return renderComponentSlot(slot.value0);
                      };
                      if (slot instanceof Halogen_Component.ThunkSlot) {
                          var step = buildThunk(slot.value0);
                          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step), new Data_Maybe.Just(step), patch, done));
                      };
                      throw new Error("Failed pattern match at Halogen.VDom.Driver (line 85, column 7 - line 90, column 75): " + [ slot.constructor.name ]);
                  };
                  var patch = function (st, slot) {
                      if (st instanceof Data_Maybe.Just) {
                          if (slot instanceof Halogen_Component.ComponentSlot) {
                              Halogen_VDom_Machine.halt(st.value0);
                              return renderComponentSlot(slot.value0);
                          };
                          if (slot instanceof Halogen_Component.ThunkSlot) {
                              var step$prime = Halogen_VDom_Machine.step(st.value0, slot.value0);
                              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step$prime), new Data_Maybe.Just(step$prime), patch, done));
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 98, column 22 - line 104, column 79): " + [ slot.constructor.name ]);
                      };
                      return render(slot);
                  };
                  return render;
              };
              var buildAttributes = Halogen_VDom_DOM_Prop.buildProp(handler);
              return {
                  buildWidget: buildWidget,
                  buildAttributes: buildAttributes,
                  document: document
              };
          };
      };
  };
  var renderSpec = function (document) {
      return function (container) {
          var render = function (handler) {
              return function (child) {
                  return function (v) {
                      return function (v1) {
                          if (v1 instanceof Data_Maybe.Nothing) {
                              return function __do() {
                                  var renderChildRef = Effect_Ref["new"](child)();
                                  var spec = mkSpec(handler)(renderChildRef)(document);
                                  var machine = Halogen_VDom_DOM.buildVDom(spec)(v);
                                  var node = Halogen_VDom_Machine.extract(machine);
                                  Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(node)(Web_HTML_HTMLElement.toNode(container)))();
                                  return {
                                      machine: machine,
                                      node: node,
                                      renderChildRef: renderChildRef
                                  };
                              };
                          };
                          if (v1 instanceof Data_Maybe.Just) {
                              return function __do() {
                                  Effect_Ref.write(child)(v1.value0.renderChildRef)();
                                  var parent = Web_DOM_Node.parentNode(v1.value0.node)();
                                  var nextSib = Web_DOM_Node.nextSibling(v1.value0.node)();
                                  var machine$prime = Halogen_VDom_Machine.step(v1.value0.machine, v);
                                  var newNode = Halogen_VDom_Machine.extract(machine$prime);
                                  Control_Applicative.when(Effect.applicativeEffect)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Unsafe_Reference.unsafeRefEq)(v1.value0.node)(newNode))(substInParent(newNode)(nextSib)(parent))();
                                  return {
                                      machine: machine$prime,
                                      node: newNode,
                                      renderChildRef: v1.value0.renderChildRef
                                  };
                              };
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 159, column 5 - line 175, column 80): " + [ v1.constructor.name ]);
                      };
                  };
              };
          };
          return {
              render: render,
              renderChild: Control_Category.identity(Control_Category.categoryFn),
              removeChild: removeChild,
              dispose: removeChild
          };
      };
  };
  var runUI = function (component) {
      return function (i) {
          return function (element) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))))(function (document) {
                  return Halogen_Aff_Driver.runUI(renderSpec(document)(element))(component)(i);
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";

  exports.toArray = function (list) {
    return function () {
      return [].slice.call(list);
    };
  };
})(PS["Web.DOM.NodeList"] = PS["Web.DOM.NodeList"] || {});
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.DOM.NodeList"] = $PS["Web.DOM.NodeList"] || {};
  var exports = $PS["Web.DOM.NodeList"];
  var $foreign = $PS["Web.DOM.NodeList"];
  exports["toArray"] = $foreign.toArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Web.Event.CustomEvent"] = $PS["Web.Event.CustomEvent"] || {};
  var exports = $PS["Web.Event.CustomEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                      
  var toEvent = Unsafe_Coerce.unsafeCoerce;
  exports["toEvent"] = toEvent;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.8
  "use strict";
  $PS["Main"] = $PS["Main"] || {};
  var exports = $PS["Main"];
  var Autocomplete_Component = $PS["Autocomplete.Component"];
  var Autocomplete_Events = $PS["Autocomplete.Events"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Coroutine = $PS["Control.Coroutine"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Class_Console = $PS["Effect.Class.Console"];
  var Halogen_Aff_Util = $PS["Halogen.Aff.Util"];
  var Halogen_VDom_Driver = $PS["Halogen.VDom.Driver"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_DOM_NodeList = $PS["Web.DOM.NodeList"];
  var Web_Event_CustomEvent = $PS["Web.Event.CustomEvent"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];                
  var removeChildren = function (parent) {
      return function __do() {
          var children = Control_Bind.bind(Effect.bindEffect)(Web_DOM_Node.childNodes(parent))(Web_DOM_NodeList.toArray)();
          return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(children)(function (child) {
              return Web_DOM_Node.removeChild(child)(parent);
          })();
      };
  };
  var extractConfig = function (element) {
      return function __do() {
          var apiKey = Web_DOM_Element.getAttribute("data-api-key")(element)();
          var inputName = Web_DOM_Element.getAttribute("data-input-name")(element)();
          var inputClass = Web_DOM_Element.getAttribute("data-input-class")(element)();
          var suggestionCountStr = Web_DOM_Element.getAttribute("data-suggestion-count")(element)();
          var suggestionCount = Data_Maybe.fromMaybe(Data_Maybe.Nothing.value)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Int.fromString)(suggestionCountStr));
          return {
              apiKey: apiKey,
              inputName: inputName,
              inputClass: inputClass,
              suggestionCount: suggestionCount
          };
      };
  };
  var main = Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitLoad)(function () {
      return Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.selectElement("#geocode-city-autocomplete"))(function (container) {
          if (container instanceof Data_Maybe.Nothing) {
              return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Class_Console.log(Effect_Class.monadEffectEffect)("Please provide a container with id `geocode-city-autocomplete`"));
          };
          if (container instanceof Data_Maybe.Just) {
              var asElement = Web_HTML_HTMLElement.toElement(container.value0);
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(removeChildren(Web_DOM_Element.toNode(asElement))))(function () {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(extractConfig(asElement)))(function (cfg) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Halogen_VDom_Driver.runUI(Autocomplete_Component.component)(cfg)(container.value0))(function (io) {
                          var evtTarget = Web_DOM_Element.toEventTarget(asElement);
                          return io.subscribe(Control_Coroutine.consumer(Effect_Aff.monadAff)(function (msg) {
                              if (msg instanceof Autocomplete_Component.ResultsFound) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_Event_EventTarget.dispatchEvent(Web_Event_CustomEvent.toEvent(Autocomplete_Events.citiesFound(msg.value0)))(evtTarget)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                                  });
                              };
                              if (msg instanceof Autocomplete_Component.NoResults) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_Event_EventTarget.dispatchEvent(Web_Event_CustomEvent.toEvent(Autocomplete_Events.noCitiesFound))(evtTarget)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                                  });
                              };
                              if (msg instanceof Autocomplete_Component.Selected) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_Event_EventTarget.dispatchEvent(Web_Event_CustomEvent.toEvent(Autocomplete_Events.citySelected(msg.value0)))(evtTarget)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                                  });
                              };
                              if (msg instanceof Autocomplete_Component.Failed) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_Event_EventTarget.dispatchEvent(Web_Event_CustomEvent.toEvent(Autocomplete_Events.lookupError(msg.value0)))(evtTarget)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                                  });
                              };
                              throw new Error("Failed pattern match at Main (line 38, column 9 - line 52, column 25): " + [ msg.constructor.name ]);
                          }));
                      });
                  });
              });
          };
          throw new Error("Failed pattern match at Main (line 28, column 3 - line 52, column 25): " + [ container.constructor.name ]);
      });
  }));
  exports["main"] = main;
  exports["removeChildren"] = removeChildren;
  exports["extractConfig"] = extractConfig;
})(PS);
PS["Main"].main();